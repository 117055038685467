import React, {useState, useEffect, useRef, useCallback} from 'react';

import { Button, Card, Form, Row, Col,Popover, OverlayTrigger, Overlay, InputGroup } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import arrowLeft from '../../assets/arrow-left.svg'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { trackEvent } from '../../mixpanel';
// import { trackAddToCart, trackAddToWishlist, transformPath } from '../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import orderRoute from '../../services/orderRoute';

import { localStorageGetItem, localStorageSetItem } from '../../App';
import info from '../../assets/info.svg'
import { toSvg } from 'html-to-image';

const PurchaseVoucher = () => {
  const {showToast, isMobile}= useAppContext();
  const [loading, setLoading] = useState(false);
  const [captureLoading, setCaptureLoading] = useState(false);
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem('userToken');
  const isLoggedIn = !!userData && !!token;
const location = useLocation();

  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [showDeliveryForm, setShowDeliveryForm] = useState(false)
  const [isEditing, setIsEditing] = useState(true);
const {purchaseVoucher}= orderRoute()
  const [voucher, setVoucher] = useState(location?.state?.voucher);

  const [voucherData, setVoucherData] = useState({
  sendOption:'',
  senderName:'',
  recipientName:'',
  recipientEmail:'',
  totalAmount: voucher?.amount,
  specialNote:''
  })

  const previewRef = useRef(null);
  const noteRef = useRef(null);
  const [capturedPreviewImage, setCapturedPreviewImage] = useState(null);
  const [capturedNoteImage, setCapturedNoteImage] = useState(null);
const [isPaymentDisabled, setIsPaymentDisabled] = useState(true);
const isFormValid = () => {
  const isSenderNameRequired = voucherData.sendOption === 'show my name';

  return (
    voucherData.sendOption &&
    voucherData.recipientEmail &&
    voucherData.recipientName &&
    voucherData.totalAmount &&
    (!isSenderNameRequired || (isSenderNameRequired && voucherData.senderName))
  );
};


const [lastActivity, setLastActivity] = useState(Date.now());

const handleUserActivity = useCallback(() => {
  setLastActivity(Date.now());
}, []);

useEffect(() => {
  // Event listeners to track user activity
  window.addEventListener('mousemove', handleUserActivity);
  window.addEventListener('keydown', handleUserActivity);
  window.addEventListener('scroll', handleUserActivity);
  window.addEventListener('click', handleUserActivity);

  // Function to check for inactivity
  const checkForInactivity = () => {
    const currentTime = Date.now();
    const timeDifference = currentTime - lastActivity;
    
    if (timeDifference >= 3600000) { // 1 hour in milliseconds
      showToast({toastText:'Session timeout', isSuccess:false})
      navigate('/');
    }
  };

  // Set interval to check for inactivity
  const interval = setInterval(checkForInactivity, 60000); // Check every minute

  // Cleanup event listeners and interval on component unmount
  return () => {
    window.removeEventListener('mousemove', handleUserActivity);
    window.removeEventListener('keydown', handleUserActivity);
    window.removeEventListener('scroll', handleUserActivity);
    window.removeEventListener('click', handleUserActivity);
    clearInterval(interval);
  };
}, [lastActivity, handleUserActivity, navigate]);





  useEffect(() => {

 if(capturedPreviewImage !== null){
  setCaptureLoading(false)
 }
  }, [capturedPreviewImage]);


  
 
  useEffect(() => {

 
    
  
    setIsPaymentDisabled(isEditing || !isFormValid || !checked || !capturedPreviewImage);
  }, [checked, isFormValid, isEditing]);
 


const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    const isCheckbox = type === 'checkbox';
  
 
        if (name === 'specialNote') {
            if (value.length <= 100) {
              setVoucherData((prevData) => ({
                ...prevData,
                [name]: value,
              }));
            } else {
              // Trigger an error or alert when the length exceeds 50 characters
              alert('You have reached the maximum text limit of 100 chaacters.');
            
            }
          } else {
      setVoucherData({ ...voucherData, [name]: isCheckbox ? checked : value });
    }
  };
  
  const handleEditDelivery = () => {
    setIsEditing(true);
    setCapturedPreviewImage(null)
    setCapturedNoteImage(null);
    trackEvent('Edit Voucher Purchase Information', {
      clicked_at: new Date().toISOString(),
      'User': userData?.email || 'Anonymous',
     
    })
  };

  const captureImage = (ref, setCapturedImage) => {
    const node = ref.current;
   
  
    if (!node) {
      console.error('Element to capture not found');
      return;
    }
  
    toSvg(node, {
    // useCORS: true,
      // cacheBust: true,
      quality: 1,
      width: node.offsetWidth,
      height: node.offsetHeight,
    })
      .then((dataUrl) => {
      
        if (dataUrl === 'data:,') {
          console.error('Captured image is empty.');
          return;
        }
        setCapturedImage(dataUrl);
       
      })
      .catch((error) => {
        console.error('Failed to capture image:', error);
      });
  };
  const handleSaveDelivery = (e) => {
    e.preventDefault();
    

   
    setIsEditing(false)
    
    trackEvent(' Voucher Purchase Information Added', {
      'User': userData?.email ||'Anonymous' ,
      'Voucher Name': voucher.name,
      'Recipient name': voucherData.recipientName,
      'Recipient email': voucherData.recipientEmail
     
      
    });

 
    if (!isMobile){
      const imagesLoaded = new Promise((resolve, reject) => {
        const previewImage = new window.Image();
        const noteImage = new window.Image();
      

        let loadedCount = 0;
        const onLoad = () => {
          loadedCount++;
       
          if (loadedCount === 2) {
            resolve();
          }
        };
    
        const onError = (error) => {
          console.error('Image failed to load:', error);
          reject(error);
        };
    
        previewImage.src = voucher?.desktopVoucherPreview;
        noteImage.src = voucher?.desktopVoucherSpecialNote;
       
    
        previewImage.onload = onLoad;
        noteImage.onload = onLoad;
        

    
        previewImage.onerror = onError;
        noteImage.onerror = onError;
      
      });
    
      imagesLoaded
        .then(() => {
       
          setCaptureLoading(true)
          setTimeout(() => {
            captureImage(previewRef, setCapturedPreviewImage);
            if(voucherData?.specialNote !== ''){
              captureImage(noteRef, setCapturedNoteImage);
            }
           
          }, 2000);
         
        })
        .catch((error) => {
          console.error('Failed to load images:', error);
        });
      }
    };
     
    
  


   

const handleContinue = () => {
 
    setShowDeliveryForm(true);
    if (isMobile){
      const imagesLoaded = new Promise((resolve, reject) => {
        const previewImage = new window.Image();
        const noteImage = new window.Image();
      

        let loadedCount = 0;
        const onLoad = () => {
          loadedCount++;
        
          if (loadedCount === 2) {
            resolve();
          }
        };
    
        const onError = (error) => {
          console.error('Image failed to load:', error);
          reject(error);
        };
    
        previewImage.src = voucher?.desktopVoucherPreview;
        noteImage.src = voucher?.desktopVoucherSpecialNote;
       
    
        previewImage.onload = onLoad;
        noteImage.onload = onLoad;
        

    
        previewImage.onerror = onError;
        noteImage.onerror = onError;
      
      });
    
      imagesLoaded
        .then(() => {
       
          setCaptureLoading(true)
          setTimeout(() => {
            captureImage(previewRef, setCapturedPreviewImage);
            if(voucherData?.specialNote !== ''){
              captureImage(noteRef, setCapturedNoteImage);
            }
           
          }, 2000);
        
        })
        .catch((error) => {
          console.error('Failed to load images:', error);
        });
      }
  
}
const dataURLToBlob = (dataUrl) => {
  const [header, data] = dataUrl.split(',');

  // Check if the data URL is for SVG
  if (header.includes('image/svg+xml')) {
    const svgBlob = new Blob([decodeURIComponent(data)], { type: 'image/svg+xml' });
    return svgBlob;
  }

  // For other data URL formats
  const mime = header.match(/:(.*?);/)[1];
  const bstr = atob(data);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};
 

const handleShow = () => {
 
    navigate(-1);
  }


const handleBack = ()=> {
  setShowDeliveryForm(false);
}
const calculateValidity = () => {
    const currentDate = new Date();
    let validUntil;

    if (voucher?.validityPeriodType === 'month') {
      validUntil = new Date(
        currentDate.setMonth(currentDate.getMonth() + voucher.validityPeriod)
      );
    } else if (voucher?.validityPeriodType === 'day') {
      validUntil = new Date(
        currentDate.setDate(currentDate.getDate() + voucher.validityPeriod)
      );
    } else if (voucher?.validityPeriodType === 'year') {
      validUntil = new Date(
        currentDate.setFullYear(currentDate.getFullYear() + voucher.validityPeriod)
      );
    }

    const dd = String(validUntil.getDate()).padStart(2, '0');
    const mm = String(validUntil.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yy = String(validUntil.getFullYear()).slice(-2);

    return `${dd}/${mm}/${yy}`;
  };

  function convertSVGToPNG(svgDataUrl, callback) {
    // Create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
  
      // Convert the canvas to a PNG data URL
      const pngDataUrl = canvas.toDataURL('image/png');
  
      // Call the callback function with the PNG data URL
      callback(pngDataUrl);
    };
  
    img.onerror = function (error) {
      console.error('Failed to convert SVG to PNG:', error);
    };
  
    // Set the source of the image to the SVG data URL
    img.src = svgDataUrl;
  }


const handlePay = () => {
  if (!isLoggedIn) {
    navigate('/login');
  } else {
    const timestamp = Date.now();
    const formData = new FormData();

    formData.append('recipientName', voucherData?.recipientName);
    formData.append('recipientEmail', voucherData?.recipientEmail);
    formData.append('amount', voucherData?.totalAmount?.toString());

    if (voucherData.specialNote !== "" && voucherData.specialNote !== null && voucherData.specialNote !== undefined) {
      formData.append('specialMessage', voucherData.specialNote);
    }

    if (voucherData.senderName !== "" && voucherData.senderName !== null && voucherData.senderName !== undefined) {
      formData.append('senderName', voucherData.senderName);
    }

    formData.append('showName', voucherData?.sendOption === 'show my name' ? true : false);

    // Wrap image conversions in promises
    const imageConversionPromises = [];

    if (capturedPreviewImage) {
      const previewImagePromise = new Promise((resolve) => {
        convertSVGToPNG(capturedPreviewImage, (pngDataUrl) => {
          const previewBlob = dataURLToBlob(pngDataUrl);
          formData.append('voucherPreview', previewBlob, `voucher-preview-${timestamp}.png`);
          resolve();
        });
      });
      imageConversionPromises.push(previewImagePromise);
    }

    if (capturedNoteImage) {
      const noteImagePromise = new Promise((resolve) => {
        convertSVGToPNG(capturedNoteImage, (pngDataUrl) => {
          const noteBlob = dataURLToBlob(pngDataUrl);
          formData.append('voucherNote', noteBlob, `voucher-note-${timestamp}.png`);
          resolve();
        });
      });
      imageConversionPromises.push(noteImagePromise);
    }

    // Wait for all image conversions to complete
    Promise.all(imageConversionPromises).then(() => {
      // Now all images are converted and appended, proceed with the purchase
      purchaseVoucher(formData, voucher, setLoading, showToast);
    });
  }
};


  return (
    <>
    
    {isMobile ? (<div className='px-lg-5 mx-lg-5 py-5'>
      {showDeliveryForm && (<> 
      <div className='pt-5 pb-3'>
              <div className='px-3' style={{borderBottom:'1px solid #D0D9E3'}}> 
              <div className='d-flex gap-3 align-items-center pb-3' > 
              <img src={arrowLeft} onClick={handleBack}/>
       <h6 className='my-auto'style={{color:'#0D111B', fontWeight:'700'}}>Preview</h6>
      </div>
     
      </div>
      {!isEditing &&
          <Card style={{border:'none'}} className='p-3 mb-3'>
  <h6 className='mb-4'style={{color:'#000000', fontWeight:'700'}}>Voucher Preview</h6>
  { !capturedPreviewImage || !capturedNoteImage ? 
  <Row className=' py-4 px-4 justify-content-center'>
  <Col xs={12} className='d-flex align-items-center justify-content-center mb-3'>
  <Card className='border-0 position-relative' ref={previewRef}>
      <Card.Img src={voucher?.desktopVoucherPreview} style={{height:'240px'}}/>
      <Card.ImgOverlay className=''>
      <div className='position-absolute' style={{left:'15%',top:'31%' }}>
              <p style={{fontFamily:"DM Sans",fontWeight:'600'}}>{voucherData?.recipientName}</p></div>
          
          <div className='position-absolute' style={{left:'15%', top:'43%'}}>
              <p style={{fontFamily:"DM Sans",fontWeight:'600'}}>{voucherData?.sendOption === 'show my name' ? voucherData?.senderName : 'Anon'}</p></div>
              <div className='position-absolute' style={{right:'13%',top:'45%' }}>
              <p style={{ fontFamily: "DM Sans", fontWeight: '600' }}>
   *****
  </p>
  
              </div>
              <div className='position-absolute' style={{left:'17%',top:'54%' }}>
              <p style={{ fontFamily: "DM Sans", fontWeight: '600' }}>
    {Number(voucherData?.totalAmount).toLocaleString()} NGN
  </p>
  
              </div>
  
              <div className='position-absolute' style={{right:'11%',top:'54%' }}>
              <p style={{fontFamily:"DM Sans",fontWeight:'600'}}>{calculateValidity()}</p></div>
      </Card.ImgOverlay>
  
  </Card></Col>
     {voucherData?.specialNote &&
     
     <Col xs={12} className='d-flex align-items-center justify-content-center mb-3'>
      <Card className='border-0 position-relative' ref={noteRef} style={{backgroundColor:'transparent'}}>
      <Card.Img src={voucher?.desktopVoucherSpecialNote} style={{height:'300px',width:'200px'}}/>
   
       <Card.ImgOverlay className='d-flex flex-column gap-1 align-items-center text-center text-align-center ps-3 pe-4 pt-5 pb-3' style={{width:'200px', height:'300px'}}>
      <h6 style={{ color: '#469B12',  lineHeight:'20px', fontWeight:'500',  }}  className='voucher-preview-text align-items-center justify-content-center text-center mb-4 '>To {voucherData.recipientName}</h6> 
      <p className=' d-flex text-align-center text-center' style={{fontFamily:"DM Sans",fontWeight:'600'}}>{voucherData?.specialNote}</p>
      <p style={{fontFamily:"DM Sans",fontWeight:'600'}} className='d-flex justify-content-end ms-auto '>Love,&nbsp;{voucherData?.sendOption === 'show my name' ? voucherData?.senderName : 'Anon'}</p>
  
      </Card.ImgOverlay>
    </Card>
  </Col>}
  </Row>:
<Row className=' py-4 px-3 justify-content-center'>
  {capturedPreviewImage && (
              <Col xs={12} className='d-flex align-items-center justify-content-center mb-3'>
                  
                    <img src={capturedPreviewImage} alt="Captured Desktop" />
                </Col>
            )}

            {capturedNoteImage && (
                 <Col xs={12} className='d-flex align-items-center justify-content-center mb-3'>
                  
                    <img src={capturedNoteImage} alt="Captured Note" />
                </Col>
            )}
            </Row>
}
{captureLoading && 
        <div className='d-flex gap-1 justify-content-center align-items-center text-center' style={{ padding: '10px 0px' }}>
        <div className="spinner-border text-success" role="status">
         
        </div>
        <p className="my-auto">Capturing Voucher...</p>
      </div>
}

<div className='d-flex gap-1'>
    <img src={info} alt='info'/>
<p style={{fontWeight:'500',color:'#1D2939'}} className='my-auto'>Please note that this voucher is only valid for {voucher?.validityPeriod} {voucher.validityPeriodType}s after purchase.</p> </div>

          </Card>
          }

          
          <Card style={{border:'none'}} className='p-3'>
          <h6 className='mb-4'style={{color:'#000000', fontWeight:'700'}}>Payment</h6>
           <div className='d-flex flex-column gap-0' style={{borderBottom:'1px solid #F8F8F9'}}>
          
           <div className='d-flex justify-content-between'>
             <p style={{color:'#667085'}}>Subtotal <span style={{color:'#4F4F4F'}}></span></p>
             <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucherData?.totalAmount)}</p>
           </div>
          </div>
           <div className='py-2'>
             <div className='d-flex justify-content-between'>
               <h6 style={{color:'#353542', fontWeight:'700'}}> Grand Total</h6>

               <p style={{color:'#353542', fontWeight:'700', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucherData?.totalAmount)}</p>
             </div>
           </div>
           <div className='pt-2' >
          
          <div className='d-flex gap-1 align-items-center mt-1'>
           <Form.Check type='checkbox'   onChange={() => setChecked(!checked)}
             value={checked} required
/> <p className='my-auto'style={{color:'#4F4F4F',fontWeight:'500'}}>Please check to acknowledge our <a href='/terms' style={{color:'#469B12'}}> Terms of Use</a> and <a href='/privacy-policy' style={{color:'#469B12'}}> Privacy Policy</a> </p>
           </div>

            
            <div className='my-3 px-3'>
            <Button   disabled={isPaymentDisabled} onClick={handlePay}   className='py-2 w-100' style={{backgroundColor: isPaymentDisabled ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}}>Pay {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucherData?.totalAmount)}</Button>
            </div>
            
           </div>
         </Card>
         
      </div> 
      </>)}

      {!showDeliveryForm && (<>
            <Card style={{backgroundColor:'#FCFCFC', borderRadius:'0px', border:'none'}} className='mt-5 mb-3'>
              <div className='py-3 '>
              <div className='px-3' style={{borderBottom:'1px solid #D0D9E3'}}> 
              <div className='d-flex gap-3 align-items-center pb-3' > 
              <img src={arrowLeft} onClick={handleBack}/>
       <h6 className='my-auto'style={{color:'#0D111B', fontWeight:'700'}}>Voucher Details</h6>
      </div>
     
      </div>
              </div>
              
              <div className='my-3 px-3'>
            
              <Card  style={{border: '0.5px solid #4F4F4F4D'}} className='p-4'>
              <Card.Img src={voucher?.desktopVoucherCover} alt='voucher'/>
                
              </Card>

              
         
              </div>

              <Card className='p-4 mb-4 ' style={{backgroundColor:'#FCFCFC', borderRadius:'0px', border:'none'}}>
          <div className='d-flex gap-3'>
        
          <h5 className=''style={{color:'#000000', fontWeight:'700'}}>Purchase Information</h5>
      {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEditDelivery}>Edit</p>
      )}
    </div>

    <Form className='mt-2 ' onSubmit={handleSaveDelivery}>
      <div className='mb-4'>
    <h6 className='my-3'style={{color:'#000000', fontWeight:'500'}}>Purchase Options</h6>
           
           <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>How would you like to send this voucher?</p>
       
         <Form.Select
     className="custom-select"
     onChange={handleChange}
     value={voucherData.sendOption || ''}
     name="sendOption"
     required disabled={!isEditing}
   > <option value="" disabled>Select an option</option>

     <option value='show my name' >Show my name</option>
     <option value='anonymously' >Anonymously</option>

   </Form.Select>

         </Form.Group>
          
       
         {voucher?.priceActor === 'customer' &&
           <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>Enter the amount you want for the voucher</p>
         <Form.Control
type='text'
name='totalAmount'
placeholder='Type an amount'
style={{border:'1px solid #E4EAF1'}}
value={voucherData.totalAmount}
onChange={(event) => {
  const numericValue = event.target.value.replace(/\D/g, "");
  setVoucherData({ ...voucherData, totalAmount: numericValue })}}
disabled={!isEditing}
/>


         </Form.Group>}
      
         </div>
         {voucherData?.sendOption === 'show my name' &&
         <div className='mb-4'>
    <h6 className='my-3'style={{color:'#000000', fontWeight:'500'}}>Sender's Details</h6>
           
         
          
       
         
           <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>Enter the name(s) you would like to use</p>
         <Form.Control
type='text'
name='senderName'
placeholder='Type your name'
style={{border:'1px solid #E4EAF1'}}
value={voucherData.senderName}
onChange={handleChange}
disabled={!isEditing}
/>


         </Form.Group>
      
         </div>}

         <div className='mb-4'>
    <h6 className='my-3'style={{color:'#000000', fontWeight:'500'}}>Recipient's Details</h6>
           
         
          <Card style={{border: '0.5px solid #4F4F4F4D'}} className='py-3 px-3'>

          
       
         
           <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>Recipient's Name</p>
         <Form.Control
type='text'
placeholder="Enter recipient's name"
style={{border:'1px solid #E4EAF1'}}
value={voucherData.recipientName}
name='recipientName'
onChange={handleChange}
disabled={!isEditing}
/>


         </Form.Group>

         <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>Email Address</p>
         <Form.Control
type='email'
placeholder="Enter recipient's email address"
style={{border:'1px solid #E4EAF1'}}
value={voucherData.recipientEmail}
name='recipientEmail'
onChange={handleChange}
disabled={!isEditing}
/>


         </Form.Group>

         <div className='my-3'>
    <h6 className='my-2'style={{color:'#000000', fontWeight:'500'}}>Special Voucher Note<span style={{color:'#000000', fontWeight:'400'}}> (optional)</span></h6>
           
         
          
       
         
           <Form.Group className='mb-3'>
         <p className='mb-2' style={{color:'#000000',fontWeight:'400'}}>You can include a special message to the recipient</p>
         <textarea

name='specialNote'
rows={3}
placeholder='Type your message'
style={{border:'1px solid #E4EAF1'}}
value={voucherData.specialNote}
onChange={handleChange}
className='w-100'
disabled={!isEditing}></textarea>
<p className='d-flex justify-content-end' style={{fontSize:'11px'}}>(max. 100 characters)</p>

         </Form.Group>
      
         </div>
         </Card>
      

      
         </div>

           {isEditing && (
     <div className='pt-3 mt-3 d-flex justify-content-end'>
       <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
     </div>
   )}
         </Form>
                
              </Card>
              </Card>

              <div className='mx-5 mb-5 pb-5'>
            <Button disabled={!isFormValid() || isEditing} style={{backgroundColor: isFormValid() || isEditing ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} 
              onClick={handleContinue}
              className='w-100 p-md-3'
            >
              Proceed
            </Button>
            </div>
              </>)}
              </div>) :

       <div style={{backgroundColor:'#F4F4F5'}} className='px-5 py-5'>
       <div className='px-xxl-5 mx-lg-5'>
        
        <Row>
          <Col xs={12} md={5}>
            <Card style={{backgroundColor:'#FCFCFC', borderRadius:'0px', border:'none'}} className='py-3 mb-3'>
            
              <div className='px-3'> 
              <div className='d-flex gap-3 align-items-center pb-2' > 
              <img src={arrowLeft} onClick={handleShow}/>
       <h6 className='my-auto'style={{color:'#000000', fontWeight:'700'}}>Voucher Details</h6>
      </div>
   
     
             
          
              <div className='my-3 px-3'>
            
              <Card  style={{border: '0.5px solid #4F4F4F4D'}} className='p-4'>
              <Card.Img src={voucher?.desktopVoucherCover} alt='voucher'/>
                
              </Card>
              
         
              </div>
            </div>

            </Card>
            <Card className='p-4 mb-4 ' style={{backgroundColor:'#FCFCFC', borderRadius:'0px', border:'none'}}>
          <div className='d-flex gap-3'>
        
          <h5 className=''style={{color:'#000000', fontWeight:'700'}}>Purchase Information</h5>
      {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEditDelivery}>Edit</p>
      )}
    </div>

    <Form className='mt-3 px-1' onSubmit={handleSaveDelivery}>
      <div className='mb-4'>
    <h6 className='my-3'style={{color:'#000000', fontWeight:'500'}}>Purchase Options</h6>
           
           <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>How would you like to send this voucher?</p>
       
         <Form.Select
     className="custom-select"
     onChange={handleChange}
     value={voucherData.sendOption || ''}
     name="sendOption"
     required disabled={!isEditing}
   > <option value="" disabled>Select an option</option>

     <option value='show my name' >Show my name</option>
     <option value='anonymously' >Anonymously</option>

   </Form.Select>

         </Form.Group>
          
       
        {voucher?.priceActor === 'customer' &&
           <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>Enter the amount you want for the voucher</p>
         <Form.Control
type='text'
name='totalAmount'
placeholder='Type an amount'
style={{border:'1px solid #E4EAF1'}}
value={voucherData.totalAmount}
onChange={(event) => {
  const numericValue = event.target.value.replace(/\D/g, "");
  setVoucherData({ ...voucherData, totalAmount: numericValue })}}
disabled={!isEditing}
/>


         </Form.Group>}
      
         </div>

        {voucherData?.sendOption === 'show my name' &&
         <div className='mb-4'>
    <h6 className='my-3'style={{color:'#000000', fontWeight:'500'}}>Sender's Details</h6>
           
         
          
       
         
           <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>Enter the name(s) you would like to use</p>
         <Form.Control
type='text'
name='senderName'
placeholder='Type your name'
style={{border:'1px solid #E4EAF1'}}
value={voucherData.senderName}
onChange={handleChange}
disabled={!isEditing}
/>


         </Form.Group>
      
         </div>}

         <div className='mb-4'>
    <h6 className='my-3'style={{color:'#000000', fontWeight:'500'}}>Recipient's Details</h6>
           
         
          <Card style={{border: '0.5px solid #4F4F4F4D'}} className='py-3 px-3'>

          
       
         
           <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>Recipient's Name</p>
         <Form.Control
type='text'
placeholder="Enter recipient's name"
style={{border:'1px solid #E4EAF1'}}
value={voucherData.recipientName}
name='recipientName'
onChange={handleChange}
disabled={!isEditing}
/>


         </Form.Group>

         <Form.Group className='mb-3'>
         <p className='mb-1' style={{color:'#000000',fontWeight:'400'}}>Email Address</p>
         <Form.Control
type='email'
placeholder="Enter recipient's email address"
style={{border:'1px solid #E4EAF1'}}
value={voucherData.recipientEmail}
name='recipientEmail'
onChange={handleChange}
disabled={!isEditing}
/>


         </Form.Group>

         <div className='my-3'>
    <h6 className='my-2'style={{color:'#000000', fontWeight:'500'}}>Special Voucher Note<span style={{color:'#000000', fontWeight:'400'}}> (optional)</span></h6>
           
         
          
       
         
           <Form.Group className='mb-3'>
         <p className='mb-2' style={{color:'#000000',fontWeight:'400'}}>You can include a special message to the recipient</p>
         <textarea

name='specialNote'
rows={3}
placeholder='Type your message'
style={{ border: '1px solid #E4EAF1' }}
value={voucherData.specialNote}
onChange={handleChange}
className='w-100'
disabled={!isEditing}></textarea>
<p className='d-flex justify-content-end' style={{fontSize:'11px'}}>(max. 100 characters)</p>


         </Form.Group>
      
         </div>
         </Card>
      

      
         </div>

           {isEditing && (
     <div className='pt-3 my-3 d-flex justify-content-end'>
       <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
     </div>
   )}
         </Form>
                
              </Card>
           
          </Col>
          <Col xs={12} md={7}>
          
          {!isEditing &&
          <Card style={{border:'none'}} className='p-3 mb-3'>
  <h6 className='mb-4'style={{color:'#000000', fontWeight:'700'}}>Voucher Preview</h6>
  { !capturedPreviewImage || !capturedNoteImage ? 
  <Row className=' py-4 px-4 justify-content-center'>
<Col xs={7} className='d-flex align-items-center justify-content-center mb-3'>
<Card className='border-0 position-relative' ref={previewRef}>
    <Card.Img src={voucher?.desktopVoucherPreview} style={{height:'240px'}}/>
    <Card.ImgOverlay className=''>
    <div className='position-absolute' style={{left:'15%',top:'31%' }}>
            <p style={{fontFamily:"DM Sans",fontWeight:'600'}}>{voucherData?.recipientName}</p></div>
        
        <div className='position-absolute' style={{left:'15%', top:'43%'}}>
            <p style={{fontFamily:"DM Sans",fontWeight:'600'}}>{voucherData?.sendOption === 'show my name' ? voucherData?.senderName : 'Anon'}</p></div>
            <div className='position-absolute' style={{right:'13%',top:'45%' }}>
            <p style={{ fontFamily: "DM Sans", fontWeight: '600' }}>
 *****
</p>

            </div>
            <div className='position-absolute' style={{left:'17%',top:'53%' }}>
            <p style={{ fontFamily: "DM Sans", fontWeight: '600' }}>
  {Number(voucherData?.totalAmount).toLocaleString()} NGN
</p>

            </div>

            <div className='position-absolute' style={{right:'10%',top:'53%' }}>
            <p style={{fontFamily:"DM Sans",fontWeight:'600'}}>{calculateValidity()}</p></div>
    </Card.ImgOverlay>

</Card></Col>
   {voucherData?.specialNote &&
   
   <Col xs={5} className='justify-content-center mb-3'>
    <Card className='border-0 position-relatuve' ref={noteRef} style={{backgroundColor:'transparent'}}>
    <Card.Img src={voucher?.desktopVoucherSpecialNote} style={{height:'300px',width:'200px'}}/>
 
     <Card.ImgOverlay className='d-flex flex-column gap-1 align-items-center text-center text-align-center ps-3 pe-4 pt-5 pb-3' style={{width:'200px', height:'300px'}}>
    <h5 style={{ color: '#469B12',  lineHeight:'20px', fontWeight:'500',  }}  className='voucher-preview-text align-items-center justify-content-center text-center mb-4 '>To {voucherData.recipientName}</h5> 
    <p className=' d-flex text-align-center text-center' style={{fontFamily:"DM Sans",fontWeight:'600'}}>{voucherData?.specialNote}</p>
    <p style={{fontFamily:"DM Sans",fontWeight:'600'}} className='d-flex justify-content-end ms-auto '>Love,&nbsp;{voucherData?.sendOption === 'show my name' ? voucherData?.senderName : 'Anon'}</p>

    </Card.ImgOverlay>
  </Card>
</Col>}
</Row> :  
<Row className=' py-4 px-3 justify-content-center'>
  {capturedPreviewImage && (
              <Col xs={8} className='d-flex align-items-center justify-content-center mb-3'>
                  
                    <img src={capturedPreviewImage} alt="Captured Desktop" />
                </Col>
            )}

            {capturedNoteImage && (
                 <Col xs={4} className='justify-content-center mb-3'>
                  
                    <img src={capturedNoteImage} alt="Captured Note" />
                </Col>
            )}
            </Row>
}
{captureLoading && 
        <div className='d-flex gap-1 justify-content-center align-items-center text-center' style={{ padding: '10px 0px' }}>
          <div className="spinner-border text-success" role="status">
           
          </div>
          <p className="my-auto">Capturing Voucher...</p>
        </div>
}

<div className='d-flex gap-2 text-center justify-content-center align-items-center'>
    <img src={info} alt='info'/>
<p style={{fontWeight:'500',color:'#1D2939'}} className='my-auto'>Please note that this voucher is only valid for {voucher?.validityPeriod} {voucher.validityPeriodType}s after purchase.</p> </div>

          </Card>
          }




           
          
            <Card style={{border:'none'}} className='p-3'>
            <h6 className='mb-4'style={{color:'#000000', fontWeight:'700'}}>Payment</h6>
             <div className='d-flex flex-column gap-0' style={{borderBottom:'1px solid #F8F8F9'}}>
            
             <div className='d-flex justify-content-between'>
               <p style={{color:'#667085'}}>Subtotal <span style={{color:'#4F4F4F'}}></span></p>
               <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucherData?.totalAmount)}</p>
             </div>
            </div>
             <div className='py-2'>
               <div className='d-flex justify-content-between'>
                 <h6 style={{color:'#353542', fontWeight:'700'}}> Grand Total</h6>

                 <p style={{color:'#353542', fontWeight:'700', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucherData?.totalAmount || 0)}</p>
               </div>
             </div>
             <div className='pt-2' >
            
            <div className='d-flex gap-1 align-items-center mt-1'>
             <Form.Check type='checkbox'   onChange={() => setChecked(!checked)}
               value={checked} required
  /> <p className='my-auto'style={{color:'#4F4F4F',fontWeight:'500'}}>Please check to acknowledge our <a href='/terms' style={{color:'#469B12'}}> Terms of Use</a> and <a href='/privacy-policy' style={{color:'#469B12'}}> Privacy Policy</a> </p>
             </div>

              
              <div className='my-3 px-3'>
              <Button   disabled={isPaymentDisabled} onClick={handlePay}  className='py-2 w-100' style={{backgroundColor: isPaymentDisabled ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}}>Pay {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucherData?.totalAmount || 0)}</Button>
              </div>
              
             </div>
           </Card>
           
          </Col>
        </Row>
           

           
       

  
          </div>
    </div>
      }

 
   
    </>
   
  )
}

export default PurchaseVoucher


