import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet';
import productRoute from '../../services/productRoute';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import GuestNavbar, { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { localStorageGetItem, localStorageSetItem } from '../../App';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import { trackEvent } from '../../mixpanel';

import axios from 'axios';
import orderRoute from '../../services/orderRoute';
import { sanitizeProductName } from './Home';
// import { trackAddToCart, trackAddToWishlist, trackViewContent } from '../../App';

const Categories = () => {
  const {updateCart,cartItems, setCartItems, setWishlistItems, wishlistItems, updatedWishlist, showToast, apiWishlistItems, setApiWishlistItems} = useAppContext()
  const { categoryName } = useParams();
  const decodedCategoryName = decodeURIComponent(categoryName)
  .replace(/-/g, ' ')  
  .replace(/and/g, '&'); 
  const { categoryId } = useParams();
  const location = useLocation();
  // const categoryId = location.state?.id;
  // const [searchParams] = useSearchParams();
  // const categoryId = searchParams.get('id');
    const [globalProducts, setGlobalProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    
    const [allCategories, setAllCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {getGlobalProducts, getProductCategory, filterProductsByCategory}= productRoute();
    const {updateWishlist, getWishlist}= orderRoute();
    const [showProductModal, setShowProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [filledHearts, setFilledHearts] = useState({});
    const userData = getParsedLocalStorageItem('userData');
    const userToken = localStorageGetItem
('userToken');;
    const isLoggedIn = !!userData && !!userToken;
  
    useEffect(() => {
      if (categoryId) {
          filterProductsByCategory(categoryId, setFilteredCategories, showToast);
      }
  }, [categoryId]);

    useEffect(() => {

      getGlobalProducts(
        setLoading, showToast, setGlobalProducts, 
       
      )
        
      }, []);

      useEffect(() => {
    

        getProductCategory(
          setLoading, showToast, setCategories,setAllCategories 
         
        )
        
      }, []);

      useEffect(() => {
        if (isLoggedIn) {
          // Fetch the wishlist if logged in
          getWishlist(setLoading, showToast, setApiWishlistItems);
        }
      }, [isLoggedIn]);
     
      useEffect(() => {
        let wishlist = [];
      
        if (isLoggedIn) {
          // If logged in, use wishlist items fetched from API
          wishlist = apiWishlistItems;
          const updatedWishlistStatus = {};
      globalProducts.forEach(product => {
        const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product.id);
        updatedWishlistStatus[product.id] = isInWishlist;
      });
      setFilledHearts(updatedWishlistStatus);
        } else {
          // If not logged in, retrieve wishlist items from local storage
          const wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
          
          const updatedWishlistStatus = {};
      globalProducts.forEach(product => {
        const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product.id);
        updatedWishlistStatus[product.id] = isInWishlist;
      });
      setFilledHearts(updatedWishlistStatus);
        }
      
       
      
      }, [globalProducts, apiWishlistItems]);


      useEffect(() => {
        if (selectedProduct) {
          const updatedProduct = cartItems.find(product => product.id === selectedProduct.id);
          if (updatedProduct && updatedProduct.quantity !== selectedProduct.quantity) {
            setSelectedProduct(updatedProduct);
          }
        }
      }, [cartItems, selectedProduct]);
      

      // useEffect(() => {
      //   if (selectedProduct) {
      //     const updatedProduct = filteredCategories.find(product => product.id === selectedProduct.id);
      //     if (updatedProduct && updatedProduct.quantity !== selectedProduct.quantity) {
      //       setSelectedProduct(updatedProduct);
      //     }
      //   }
      // }, [filteredCategories, selectedProduct]);
      const handleShowProductModal = (product) => {

        /*Ad tag */
        // const contentId = product ? product?.id : 'Unknown product';
        // const contentType = `View ${product?.productName} Product Information`
        // trackViewContent(contentId, contentType);
    
        // setShowProductModal(true);
        trackEvent(`${product?.productName} Viewed`, {
          'Product ID': product?.id,
          'Product Name': product?.productName,
          'Category Name': product?.category,
          'User': userData?.email || 'Anonymous',
          // Any other relevant properties
        });
        const sanitizedProductName = sanitizeProductName(product?.productName);

 
     const productId = product?.sku;

navigate(`/products/${sanitizedProductName}/${productId}`);
      };

      // const handleCloseProductModal = () => {
      //   setShowProductModal(false);
      // };

      const handleIncreaseQuantity = (id, increment = 1, replace) => {
        let newCartItems = [...cartItems];
        const cartIndex = newCartItems.findIndex(item => item.id === id);
    
        // Find the product in the global products array
        const product = globalProducts.find(p => p.id === id);
        if (!product) {
            console.log('Error', `Product with ID ${id} not found`);
            return;
        }
        let initialQuantity = 0;
        if (cartIndex !== -1) {
          initialQuantity = newCartItems[cartIndex].quantity;
        }
        // Check if the product is already in the cart
        if (cartIndex !== -1) {
            // Check if increasing quantity exceeds available stock
            if (newCartItems[cartIndex].quantity + increment > product.quantityAvailable) {
                showToast({toastText:'This product has limited stock!', isSuccess:false});
                return;
            }
            // Update the quantity in the cart
            const updatedQuantity = replace ? increment : newCartItems[cartIndex].quantity + increment;
            newCartItems[cartIndex] = { ...newCartItems[cartIndex], quantity: updatedQuantity };
        } else {
            // Check if adding this quantity would exceed available stock
            if (product.quantity + increment > product.quantityAvailable) {
                showToast({toastText:'This product has limited stock!', isSuccess:false});
                return;
            }
            // Add the product to the cart if not found
            newCartItems.unshift({ ...product, quantity: increment });
        }
    
        // Update cart items state
        setCartItems(newCartItems);
        updateCart(newCartItems);
    
        // Update the product list with the new quantities
        const updatedProducts = globalProducts.map(product => {
            if (product.id === id) {
                const newQuantity = replace ? increment : (product.quantity || 0) + increment;
                return { ...product, quantity: newQuantity };
            }
            return product;
        });
        setGlobalProducts(updatedProducts);
    
        // Track the event of adding a product to the cart
        const addedProduct = updatedProducts.find(p => p.id === id);
        if (addedProduct) {
          if (initialQuantity === 0) {
            trackEvent(`${addedProduct.productName} Added to Cart`, {
              'Product ID': addedProduct.id,
              'Product Name': addedProduct.productName,
              'Category Name': addedProduct.category,
              'Quantity Added': increment,
              'User': userData?.email || 'Anonymous',
              'Location': 'Categories page'
            });
          } else {
            trackEvent(`${addedProduct.productName} Increased in Cart`, {
              'Product ID': addedProduct.id,
              'Product Name': addedProduct.productName,
              'Category Name': addedProduct.category,
              'New Quantity': newCartItems[cartIndex].quantity,
              'User': userData?.email || 'Anonymous',
              'Location': 'Categories page'
            });
          }
    };
  }
    
    
    
    const handleDecreaseQuantity = (id) => {
      const productBeforeUpdate = cartItems.find(item => item?.id === id);
      let newCartItems = [...cartItems];
      const cartIndex = newCartItems.findIndex(item => item.id === id);
    
      // Check if the product is in the cart
      if (cartIndex !== -1) {
        // Decrease quantity or remove item if quantity is 1
        if (newCartItems[cartIndex].quantity > 1) {
          newCartItems[cartIndex] = { ...newCartItems[cartIndex], quantity: newCartItems[cartIndex].quantity - 1 };
          trackEvent(`${newCartItems[cartIndex].productName} Quantity Decreased in Cart`, {
            'Product ID': newCartItems[cartIndex].id,
            'Product Name': newCartItems[cartIndex].productName,
            'Category Name': newCartItems[cartIndex].category,
            'New Quantity': newCartItems[cartIndex].quantity,
            'User': userData?.email || 'Anonymous',
            'Location': 'Categories page'
          });
        } else {
          // Remove item from cart if quantity is 1
          newCartItems.splice(cartIndex, 1);
         

          trackEvent(`${productBeforeUpdate.productName} Removed from Cart`, {
            'Product ID': productBeforeUpdate.id,
            'Product Name': productBeforeUpdate.productName,
            'Category Name': productBeforeUpdate.category,
            'Location': 'Categories page',
            // Here, we know the quantity reached 0, leading to its removal
            'User': userData?.email || 'Anonymous',
          });
        }
      } else {
          console.log('Error', `Product with ID ${id} not found in the cart`);
          return; // Exit function as there is no item to decrease
      }
    
      setCartItems(newCartItems);
    
      // Update product quantity in the globalProducts state
      const updatedProducts = globalProducts.map(product => {
        if (product.id === id) {
          let newQuantity = (product.quantity || 0) - 1;
          return { ...product, quantity: newQuantity < 0 ? 0 : newQuantity };
        }
        return product;
      });
    
      setGlobalProducts(updatedProducts);
    
      // Update cart storage or state
      updateCart(newCartItems); 
  };

      const toggleFill = (productId) => {
        const product = globalProducts.find(p => p.id === productId);
      const products = globalProducts  
      
        if (isLoggedIn) {
          updateWishlist(
            productId,
            showToast,
            filledHearts,
            setFilledHearts,
            apiWishlistItems,
            setApiWishlistItems,
            products
          );
        } else {
          const newState = !filledHearts[productId];
          setFilledHearts(prevState => ({
            ...prevState,
            [productId]: newState,
          }));
      
          let wishlist = getParsedLocalStorageItem('wishlistItems') || [];
          const index = wishlist.findIndex(item => item?.product?.id === productId);
          let toastText = '';
      
          if (newState) {
            // Add to local wishlist if not already present
            if (index === -1) {
              const productToAdd = globalProducts.find(product => product.id === productId);
              wishlist = [{product:productToAdd}, ...wishlist]; // Prepend the new product
              toastText = 'Item added to Wishlist!';
              // trackAddToWishlist(productId, productToAdd.productName);
            }
           
          } else {
            // Remove from local wishlist if present
            if (index !== -1) {
              wishlist = wishlist.filter(item => item?.product?.id !== productId);
              toastText = 'Item removed from Wishlist!';
            }
          }
      
          // Update local storage and state
          localStorageSetItem('wishlistItems', JSON.stringify(wishlist));
          setWishlistItems(wishlist);
      
          // Show the appropriate toast message
          showToast({
            toastText: toastText,
            isSuccess: true
          });
          trackEvent(newState ? `${product?.productName} Added to Wishlist` : `${product?.productName} Removed from Wishlist`, {
            'Product ID': productId,
            'Product Name': product?.productName,
            'Category Name': product?.category,
            'User': userData?.email || 'Anonymous',
         
          });
        }
      
      
      
      };

     
  return (
    <div>
        <Helmet>
        <title>Virtue's Shop - Product Categories</title>
        <meta name="description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta name="keywords" content="Food, Foodstuffs, Fresh tomatoes, Fresh food, Fresh food market, Fresh food delivery, Foodstuff shop in Lagos, Foodstuff delivery shop in Lagos, Affordable food store" />

        <meta property="og:title" content="Virtue's Shop - FAQs" />
        <meta property="og:description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta property="og:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta property="og:url" content="https://app.virtuesshop.co/" />
        <meta property="og:type" content="website" />


        <meta name="twitter:title" content="Virtue's Shop - FAQs" />
        <meta name="twitter:description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta name="twitter:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta name="twitter:card" content="summary_large_image" />

        
        <meta name="author" content="Virtue's Shop" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://app.virtuesshop.co/" />

      </Helmet>
      
        <section className='px-3 px-xxl-5 mx-lg-5'>

        {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>

      { filteredCategories?.length > 0 ? ( <div className='my-5 mx-xl-5 pb-5'> 
            <div className="d-flex gap-2 align-items-center mb-4" >  
  <Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-right" ></i></p></Link>
  <p className='' style={{color:'#1D2939', fontWeight:'600'}} >              {decodedCategoryName} </p>
      {/* <p className='' style={{color:'#1D2939', fontWeight:'600'}} >              {filteredCategories[0].category?.decodedCategoryName} </p> */}
      </div>
      
      <Row>

{filteredCategories?.map(product => 
  {
    const foundInCart = cartItems.find(item => item.id === product.id);
const productQuantity = foundInCart ? foundInCart.quantity : 0; 
product.quantity = productQuantity;

return(
 <Col xs={6} md={3} className='mb-3 mb-md-4'  key={product?.id} >
{product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0 ?  
<Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
}} >
        <div className='position-relative mb-1 h-100'>
        <Card.Img src={product?.productImage} style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', height:'100%',maxHeight:'250px',objectFit:'cover', width:'true'}}/>
        
        <Card.ImgOverlay style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', height:'100%',maxHeight:'250px',}} onClick={() => handleShowProductModal(product)} > <div className=' bg-danger text-center px-2 position-absolute' style={{ padding:'', borderRadius:'100px',boxShadow:'0px 1px 2px 0px #0000001A', top:'5%', right:'5%'}} >

<span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400', }}>
OUT OF STOCK
</span>

                             
</div></Card.ImgOverlay>
                   
        </div>
       
        
        < Card.Body className='px-2 pt-1 pb-2'>

<h5 style={{
color: 'rgba(53, 53, 66, 0.6)',
fontWeight: '700',
minHeight: '2.2rem', 
display: 'flex',
alignItems: 'start',
justifyContent: 'start'
}} className='my-0 py-0'>
{product?.productName}
</h5>

    
  
   <div className='d-flex justify-content-between align-items-center'>
   <div className='d-flex gap-2 align-items-center'>
   <h5 style={{ fontFamily: 'Outfit', color: '#353542', fontWeight: '600' }} className='my-auto'>
{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.price)}
</h5>


           {product?.OldPrice && (<h5 className='my-auto' style={{color:'#dfdcdc',textDecoration:'line-through', fontSize:'12px', fontFamily:'Outfit', fontWeight:'500',}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.OldPrice)}</h5>)}
           

       </div>
        
       <i
                 
                  className={`bi ${filledHearts[product?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1rem',
                    color: filledHearts[product?.id] ? '#469B12' : 'black', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                  onClick={() => toggleFill(product?.id)}></i>
      
       
   </div>
</Card.Body>
 
         
         
       
      </Card>:  <Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
}} >
        <div className='position-relative mb-1 h-100'>
        <Card.Img src={product?.productImage} onClick={() => handleShowProductModal(product)} style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', height:'100%',maxHeight:'250px',objectFit:'cover', width:'true'}}/>
        {product?.inSeason && <div className='bg-success position-absolute my-1 my-md-0 text-center px-2' style={{top:'5%', left:'5%', padding:'', borderRadius:'100px',boxShadow:'0px 1px 2px 0px #0000001A'}} >

<span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400', }}>
{product?.inSeason && 'IN SEASON' }
</span>

                             
</div>}
        
       
       
       <div className='position-absolute' style={{top:'5%', right:'5%'}}>
   <div className=' pt-1 d-flex text-center align-items-center justify-content-center px-1  bg-white' style={{borderRadius:'15px', color:'#344054', boxShadow: "0px 1px 2px 0px #0000001A"}}  onClick={() => toggleFill(product?.id)}>  
      
        <i
                 
                  className={`bi ${filledHearts[product?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1rem',
                    color: filledHearts[product?.id] ? '#469B12' : 'black', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                ></i>
</div>
 
   </div>                          
        </div>
       
        
        < Card.Body className='px-2 pt-1 pb-2'>

<h5 style={{
color: 'rgba(53, 53, 66, 0.6)',
fontWeight: '700',
minHeight: '2.2rem', 
display: 'flex',
alignItems: 'start',
justifyContent: 'start'
}} className='my-0 py-0'>
{product?.productName}
</h5>

    
  
   <div className='d-flex justify-content-between align-items-center'>
   <div className='d-flex gap-2 align-items-center'>
   <h5 style={{ fontFamily: 'Outfit', color: '#353542', fontWeight: '600' }} className='my-auto'>
{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.price)}
</h5>


           {product?.OldPrice && (<h5 className='my-auto' style={{color:'#dfdcdc',textDecoration:'line-through', fontSize:'12px', fontFamily:'Outfit', fontWeight:'500',}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.OldPrice)}</h5>)}
           

       </div>
        
       {product.quantity === 0 ? (
<Button  onClick={() => handleIncreaseQuantity(product?.id, 1, true)} className='d-flex text-center justify-content-center align-items-center px-2' style={{height:'30px'}}>ADD</Button>
) : (
<div className="btn-group btn-group-sm gap-1" >
 <Button onClick={() => handleDecreaseQuantity(product.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'1.5rem', height:'30px'}}>-</Button>
 <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'1.5rem', height:'30px', fontWeight:'600'}}>{product.quantity}</Button>
 <Button onClick={() => handleIncreaseQuantity(product.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'1.5rem', height:'30px'}}>+</Button>
</div>
)}
 
      
       
   </div>
</Card.Body>
 
         
         
       
      </Card>}

 </Col>
)})}
</Row></div> 
     ): <div className='py-5 px-3 px-md-5'>
        <div className="d-flex gap-2 align-items-center mb-4" >  
  <Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-right" ></i></p></Link>
  <p className='' style={{color:'#1D2939', fontWeight:'600'}} >              {decodedCategoryName} </p>
      
      </div>
      <div className="text-center fw-medium" style={{ padding: "70px 0px" }}>No products available under this category</div>
     </div>
     }
     </>
      )}

     

      <section className='px-0 px-xl-3 py-md-5 py-3 d-flex justify-content-end'>
          <div className='d-block d-md-flex gap-3'>
  <div style={{borderTopLeftRadius:'52px', borderBottomLeftRadius:'52px', width:'240px',height:'', border:'1px solid #EDE9EA'}} className='p-1 mb-3'>
<div className="row g-4" >
   
   <div  className="col-12 ">
     <div className="d-flex align-items-center gap-3 rounded-3 ">
       <div className="flex-shrink-0">
       <i className="bi bi-whatsapp p-2" style={{border:'1px solid #EDE9EA', borderRadius:'50%'}}></i>
       </div>
       <div className="flex-grow-1">
         <div className="">
         <a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}>
           <p className="mb-0 text-dark" style={{fontWeight:'600'}}>Chat/Shop on Whatsapp</p>
           <p className="text-truncate text-decoration-underline mb-0" style={{color:'#9CA3AF'}}>+234 816 664 1019 </p>
           </a>
         </div>
       </div>
     </div>
   </div>
 
</div>
</div>
<div style={{borderTopLeftRadius:'52px', borderBottomLeftRadius:'52px', width:'200px',height:'', border:'1px solid #EDE9EA'}} className='p-1 mb-3'>
<div className="row g-4" >
   
   <div  className="col-12 ">
     <div className="d-flex align-items-center gap-3 rounded-3 ">
       <div className="flex-shrink-0">
       <i className="bi bi-whatsapp p-2" style={{border:'1px solid #EDE9EA', borderRadius:'50%'}}></i>
       </div>
       <div className="flex-grow-1">
         <div className="">
         <a href="tel:+2349167682871" className=" text-white text-decoration-none">
           <p className="mb-0 text-dark" style={{fontWeight:'600'}}>Call us on</p>
           <p className="text-truncate text-decoration-underline mb-0" style={{color:'#9CA3AF'}}>+234 9167682871</p>
           </a>
         </div>
       </div>
     </div>
   </div>
 
</div>
</div>

</div></section>
        </section>
  
   


  
  
   {/* <ProductModal selectedProduct={selectedProduct} showModal={showProductModal}handleCloseModal={handleCloseProductModal} handleDecreaseQuantity={handleDecreaseQuantity} handleIncreaseQuantity={handleIncreaseQuantity} globalProducts={globalProducts}/> */}
  </div>
  )
}

export default Categories
