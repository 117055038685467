import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet';

import { useParams, Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import GuestNavbar, { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { localStorageGetItem, localStorageSetItem } from '../../App';
import { Row, Col, Card, Button,Modal, Image } from 'react-bootstrap';
import arrow from '../../assets/typcn_arrow-back-outline.svg'
import { useAppContext } from '../../AppContext';
import love from '../../assets/love.svg'
import { trackEvent } from '../../mixpanel';
import foodBox from '../../assets/food-box.png'
import productRoute from '../../services/productRoute';
import yam from '../../assets/yam.png'

import arrowLeft from '../../assets/arrow-left.svg'
import bag from '../../assets/bag-outline.svg'
import orderRoute from '../../services/orderRoute';



const Vouchers = () => {
  const {showToast,setWishlistItems, wishlistItems,setApiWishlistItems, apiWishlistItems, isMobile} = useAppContext()
    const [vouchers, setVouchers] = useState([]);
   
    const {updateWishlist, getWishlist}= orderRoute();
   
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
const {getVouchers}= productRoute()
const [filledHearts, setFilledHearts] = useState({});
    const userData = getParsedLocalStorageItem('userData');
    const userToken = localStorageGetItem
('userToken');;
    const isLoggedIn = !!userData && !!userToken;
   
    useEffect(()=>{
   
      getVouchers(
        setLoading, showToast, setVouchers, 
       
      )
    
  },[])

  
  const handleBack = () => {
   
    navigate(-1);
  }
  const handlePurchase = (voucher) => {
    navigate('/vouchers/purchase', { state: { voucher } });
  };

  return (
    <div>
        <Helmet>
        <title>Virtue's Shop - Food Vouchers</title>
        <meta name="description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta name="keywords" content="Food, Foodstuffs, Fresh tomatoes, Fresh food, Fresh food market, Fresh food delivery, Foodstuff shop in Lagos, Foodstuff delivery shop in Lagos, Affordable food store" />

        <meta property="og:title" content="Virtue's Shop - Food Vouchers" />
        <meta property="og:description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta property="og:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta property="og:url" content="https://app.virtuesshop.co/" />
        <meta property="og:type" content="website" />


        <meta name="twitter:title" content="Virtue's Shop - Food Vouchers" />
        <meta name="twitter:description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta name="twitter:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta name="twitter:card" content="summary_large_image" />

        
        <meta name="author" content="Virtue's Shop" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://app.virtuesshop.co/" />

      </Helmet>

           
      {isMobile ? 
       <div className='py-5 my-5 my-sm-0'>
        <div className='mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex d-sm-none align-items-center gap-3 px-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>Gift Vouchers</h5>
              
           </div>
           </div>
           <div className=' px-3 px-xxl-5 mx-lg-5 mb-5'>
          
    

        {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>

      { vouchers?.length > 0 ? ( 
      
      <Row className='mt-5'>

        {vouchers?.map((voucher)=> (
          <Col xs={12} md={4} className='mb-5 mb-md-4 px-2'  key={voucher?.id} >
         
            <Row>
              <Col xs={7}>
             
          <h5 style={{color:'#344054', fontWeight:'600'
          }} className='mb-0 pb-1'>
          {voucher?.name}
          </h5>
         
       
          <p style={{color:'#98A2B3'}} className='mb-0 pb-2'>{voucher?.description}</p>
          {voucher?.priceActor === 'admin' && voucher?.amount !== null &&
          <p style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '600'}} className='mt-2 pb-0'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucher?.amount)}
          </p>}
            
           
             <div className='d-flex gap-2 align-items-center mt-1'>
            
             <Button  className='d-flex text-center justify-content-center align-items-center px-2' style={{height:'21px', fontSize:'12px'}}  onClick={()=> handlePurchase(voucher)}>PURCHASE</Button>
             {/* <div className='d-flex gap-1 align-items-center'>
             <i  
                  className='bi-heart'

                  style={{
                    fontSize: '1rem',
                    color:'#394357', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }} ></i>
              <p className='my-2' style={{color:'#394357', fontSize:'13px'}}>Add to Wishlist
  </p>
             </div> */}
    
                     
          
                
                 
             </div>
              </Col>
              <Col xs={5}>
              <div className='position-relative mb-3 h-100'>
                  <Image fluid  src={voucher?.desktopVoucherCover} style={{width:'150px', height:"90px"}}   alt='food box' />
                
                 
                                           
                  </div>
              </Col>
            </Row>
             
          
           </Col>
        ))}

</Row>
     ): <div className='py-5 px-3 px-md-5'>
       
      <div className="text-center fw-medium" style={{ padding: "70px 0px" }}>No Gift Vouchers</div>
     </div>
     } </> )}  
           </div> 
           </div>:
      
        <section className='px-3 px-xxl-5'>
        <div className='my-5 mx-xl-5 pb-5'> 
            <div className="d-flex gap-2 align-items-center mb-4" >  
  <Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-right" ></i></p></Link>
  <p className='' style={{color:'#1D2939', fontWeight:'600'}} > Gift Vouchers</p>
  
      </div>

     

    

        {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
{ vouchers?.length > 0 ? ( 
      
      <Row className='mt-5'>

        {vouchers?.map((voucher)=> (
          <Col xs={6} md={4} className='mb-3 mb-md-4'  key={voucher?.id} >
           <Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
          }} >
                  <Card className='p-3 border-0' style={{ maxHeight:'350px'}}>
                  <Card.Img src={voucher?.desktopVoucherCover}  style={{ maxHeight:'300px'}} alt='voucher'/>
                  
                 
                                           
                  </Card>
                 
                  
                  < Card.Body className='px-3 pt-2 mt-0 pb-4'>
                  <div className='d-flex justify-content-between mb-2'>
          <h5 style={{color:'#344054', fontWeight:'600'
          }} className='my-0 py-0'>
          {voucher?.name}
          </h5>
          {voucher?.priceActor === 'admin' && voucher?.amount !== null &&
          <h6 style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '600' }} className='my-auto'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucher?.amount)}
          </h6>}
          </div>
          <p style={{color:'#4F4F4F'}}>{voucher?.description}</p>
              
            
           
             <div className='d-flex gap-3 align-items-center'>
            
             <Button  className='d-flex text-center justify-content-center align-items-center px-2' style={{height:'30px'}} onClick={()=> handlePurchase(voucher)}>PURCHASE</Button>
             {/* <div className='d-flex gap-1 align-items-center'>
             <i  
                  className='bi-heart'

                  style={{
                    fontSize: '1.3rem',
                    color:'#394357', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }} ></i>
              <p className='my-auto' style={{color:'#394357'}}>Add to Wishlist
  </p>
           
             </div> */}
    
                     
          
                
                 
             </div>
          </Card.Body>
           
                   
                   
                 
                </Card>
          
           </Col>
        ))}

</Row>
     ): <div className='py-5 px-3 px-md-5'>
       
      <div className="text-center fw-medium" style={{ padding: "70px 0px" }}>No Gift Vouchers</div>
     </div>
     }
     
     </>
      )}

</div>

    
        </section>}
 
   
  </div>
  )
}

export default Vouchers
