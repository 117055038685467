import {  Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Website/Home';
import Login from './pages/Website/Login';

import './styles.css';
import VerifySuccessModal from './components/Modal/VerifySuccessModal';

import Signup from './pages/Website/Signup';
import Loading from './pages/Website/Loading';
import Categories from './pages/Website/Categories';
import OrderSummary from './pages/Website/OrderSummary';
import CartOffCanvas from './pages/Website/Cart'
import PrivacyPolicy from './pages/Website/PrivacyPolicy';
import Terms from './pages/Website/Terms';


import ShippingPolicy from './pages/Website/ShippingPolicy';
import RefundPolicy from './pages/Website/RefundPolicy';
import Orders from './pages/Profile/Orders';
import Faq from './pages/Website/Faq';
import ProfileWishlist from './pages/Profile/ProfileWishlist';
import ResetPasswordModal from './components/Modal/ResetPasswordModal';
import PersonalData from './pages/Profile/PersonalData';
import NewPassword from './pages/Profile/NewPassword';
import ForgotPassword from './pages/Website/ForgotPassword';
import LogoutModal from './components/Modal/Logout';
import OrderConfirmation from './pages/Profile/OrderConfirmation';
import OrderDetails from './pages/Profile/OrderDetails';
import Search from './pages/Website/Search';
import SidebarNavbar from './components/Navbar/SideNavbar';
import ProductPage from './pages/Website/ProductPage';

import { PreventLoginAccessRoute, ProtectedRoute } from './App';
import OrderModification from './pages/Profile/OrderModification';
import Subscriptions from './pages/Website/Subscriptions';
import SubscriptionSummary from './pages/Website/SubscriptionSummary';
import UserSubscriptions from './pages/Profile/UserSubscriptions';
import CancelSubscription from './pages/Profile/CancelSubscription';
import Vouchers from './pages/Website/Vouchers';
import PurchaseVoucher from './pages/Website/PurchaseVoucher';
import Refer from './pages/Profile/Refer';



const RoutesConfig = ({ isMobile }) => (

     <Routes>
   <Route path="/" element={<Home/>} />
   <Route path="/terms" element={<Terms/>} />

   <Route element={<PreventLoginAccessRoute />}>
   <Route path="/login" element={<Login/>} />
   <Route path="/signup" element={<Signup/>} />
   <Route path="/forgot-password" element={<ForgotPassword/>} />
        </Route>
 
   <Route path="/faq" element={<Faq/>} />
   <Route path="/search" element={<Search/>} />
   {isMobile && <Route path="/cart" element={<CartOffCanvas/>} />}
   {isMobile && <Route path="/dashboard" element={<SidebarNavbar/>} /> }
   <Route path="/products/:productName/:productId" element={<ProductPage />} />
   <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
   <Route path="/shipping-policy" element={<ShippingPolicy/>} />
   <Route path="/refund-policy" element={<RefundPolicy/>} />     
   <Route path="/loading/:token" element={<Loading/>} />
   <Route path="/verify-email" element={<VerifySuccessModal/>} />
   <Route path="/reset-password" element={<ResetPasswordModal/>} />
   <Route path="/category/:categoryName/:categoryId" element={<Categories/>} />
   <Route path="/subscriptions" element={<Subscriptions/>} />
   <Route path="/vouchers" element={<Vouchers/>} />
   <Route path="/vouchers/purchase" element={<PurchaseVoucher/>} />
   <Route path="/subscription-summary" element={<SubscriptionSummary />} />

   <Route path="/order-confirmation" element={<OrderConfirmation />} />

{/* Customers */}
   <Route element={<ProtectedRoute />}>
          <Route path="/profile/orders" element={<Orders />} />
          <Route path="/profile/refer" element={<Refer />} />
          <Route path="/profile/order-details/:orderId" element={<OrderDetails />} />
          <Route path="/profile/order-modification/:orderId" element={<OrderModification />} />
         
         
          <Route path="/profile/personal-data" element={<PersonalData />} />
          <Route path="/order-summary" element={<OrderSummary />} />
         
          <Route path="/profile/password-update" element={<NewPassword />} />
          <Route path="/profile/wishlist" element={<ProfileWishlist />} />
          <Route path="/profile/subscriptions" element={<UserSubscriptions />} />
          <Route path="/profile/subscriptions/cancel/:subscriptionId" element={<CancelSubscription />} />
        </Route>
       



   
       
         
     
   <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  
)

export default RoutesConfig;