import React, { useState} from 'react'
import { Card, Button,Row,Col } from 'react-bootstrap'
import arrowDown from '../../assets/arrow-down.svg'
import arrowLeft from '../../assets/arrow-left.svg'
import arrowRight from '../../assets/arrow-right.svg'
import {Link, useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet';


const allFaqs = [
  {
    id: 1,
    faqTitle:'General Questions',
    questionswithAnswers:[
      {
        id:1,
        question:"What is Virtue's Shop?",
        answer:"Virtue's Shop is a convenient online grocery shopping platform that delivers fresh produce, household staples, and other essentials to your doorstep."

      },
      {
        id:2,
        question:"What are your delivery areas ?",
        answer:"We currently deliver to some areas in Lagos and Ogun state."

      },
      {
        id:3,
        question:"Do I need to create an account to use Virtue's Shop?",
        answer:"While creating an account allows you to save your preferences and track your orders more easily and checkout, you can still proceed to explore the application as a guest.",
      },
      {
        id:4,
        question:"How do I place an order?",
        answer:"Browse our product categories, add items to your cart, and proceed to checkout. You will need to provide your delivery information and choose a payment method.",
      },
      {
        id:5,
        question:"What are your payment methods?",
        answer:"We currently accept payments through <b>Paystack</b>. You can also send your orders to our customer service representatives and pay with <b>Bank Transfer</b>.",
      }
    ]

  },
  {
    id: 2,
    faqTitle:'Ordering & Delivery',
    questionswithAnswers:[
      {
        id:1,
        question:"What are the minimum and maximum order amounts?",
        answer:"We have a minimum order requirement of N12000 per order and a minimum of N1000 per item to ensure efficient delivery logistics.There is no maximum order limit."

      },
      {
        id:2,
        question:"What are your delivery fees?",
        answer:"Delivery fees vary depending on your location and order amount. You will see the applicable fee displayed at checkout."

      },
      {
        id:3,
        question:"Can I schedule my delivery?",
        answer:"At this time, we offer deliveries twice in a month. You can select the date that is more convenient for you from the date list.",
      },
      {
        id:4,
        question:"What happens if I'm not available during my delivery window?",
        answer:"We will attempt to contact you to arrange a suitable alternative time. If we are unable to reach you, your order may be returned to the store, and a refund will be issued.",
      },
      {
        id:5,
        question:"Can I add items to my order after I've placed it?",
        answer:"Unfortunately, you cannot add items to your order once it has been confirmed. However, you can always place a new order for any additional items you need.",
      }
    ]

  },
  {
    id: 3,
    faqTitle:'Products & Pricing',
    questionswithAnswers:[
      {
        id:1,
        question:"Are the pictures of the products accurate?",
        answer:"We strive to provide accurate and up-to-date product images. However, slight variations in color or size may occur."

      },
      {
        id:2,
        question:"Do your prices change?",
        answer:"Due to market fluctuations, prices of some products may change occasionally. We display the most current price on the product page."

      },
      {
        id:3,
        question:"What if an item I want is out of stock?",
        answer:"We update our inventory regularly, but some items may occasionally run out of stock. We will notify you if any item in your order is unavailable and offer you a substitute or a refund.",
      },
      
    ]

  },
  {
    id: 4,
    faqTitle:'Returns & Refunds',
    questionswithAnswers:[
      {
        id:1,
        question:"What is your return policy?",
        answer:"We accept returns for damaged or spoiled items within 12 - 24 hours of delivery. Please contact us immediately if you have any issues with your order."

      },
      {
        id:2,
        question:"How do I request a refund?",
        answer:"We do not issue refunds unless items are out of stock or damaged."

      },
      
      
    ]

  },
  {
    id: 5,
    faqTitle:'Account & Security',
    questionswithAnswers:[
      {
        id:1,
        question:"How do I create an account?",
        answer:"Visit our website and click on the 'Create Account' button. You will need to provide your name, email address, and choose a password."

      },
      {
        id:2,
        question:"How do I change my account information?",
        answer:"You can edit your account information by logging in to your account and clicking on 'My Profile'."

      },
      {
        id:3,
        question:"How do I reset my password?",
        answer:"Click on 'Forgot Password' on the login page and enter your email address. We will send you instructions on how to reset your password."

      },
      {
        id:4,
        question:"Is my information secure?",
        answer:"We take data security seriously and employ various measures to protect your information."

      },
      
      
    ]

  },
  {
    id: 6,
    faqTitle:'Contact Us',
    questionswithAnswers:[
      {
        id:1,
        question:"How can I contact you?",
        answer: `You can contact our customer service team via phone at <a href="tel:+2348166641019">+2348166641019</a> or email us at <a href="mailto:support@virtuesshop.co">support@virtuesshop.co</a>.`

      }
      
    ]

  },
]

const Faq = () => {
  const [selectedFaqTitle, setSelectedFaqTitle] = useState(allFaqs[0].faqTitle); 
  const [openQuestionId, setOpenQuestionId] = useState(null);
  const navigate = useNavigate();
 
  const toggleAccordion = (id) => {
    setOpenQuestionId(openQuestionId === id ? null : id); 
  };

  const selectedFaq = allFaqs.find(faq => faq.faqTitle === selectedFaqTitle);

  const handleBack = () => {
   
    navigate(-1);
  }
  return (
    <>
    <Helmet>
        <title>Virtue's Shop - FAQs</title>
        <meta name="description" content="Frequently Asked Questions about our foodstuff/groceries delivery services in Lagos and Ogun State." />
        <meta name="keywords" content="Food, Foodstuffs, Fresh tomatoes, Fresh food, Fresh food market, Fresh food delivery, Foodstuff shop in Lagos, Foodstuff delivery shop in Lagos, Affordable food store" />

        <meta property="og:title" content="Virtue's Shop - FAQs" />
        <meta property="og:description" content="Frequently Asked Questions about our foodstuff/groceries delivery services in Lagos and Ogun State." />
        <meta property="og:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta property="og:url" content="https://app.virtuesshop.co/faq" />
        <meta property="og:type" content="website" />


        <meta name="twitter:title" content="Virtue's Shop - FAQs" />
        <meta name="twitter:description" content="Frequently Asked Questions about our foodstuff/groceries delivery services in Lagos and Ogun State." />
        <meta name="twitter:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta name="twitter:card" content="summary_large_image" />

        
        <meta name="author" content="Virtue's Shop" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://app.virtuesshop.co/faq" />

      </Helmet>

<div style={{ position: 'relative' }} className='py-5 py-sm-0'> 
    <Card className=' terms-card' style={{ border: 'none', borderRadius: '0px', position: 'relative', zIndex: 1 }}>
    <div className='pt-5 px-3 px-md-5 mx-lg-5 mb-5 ' style={{zIndex:'99'}}>
     
    
        <div className='mb-4'>
        <div className='d-block d-sm-none'>
          <div className='d-flex align-items-center gap-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
          <h2 className='my-auto' style={{fontWeight:'600',color:'#469B12'}} >Frequently Asked Questions</h2>
              
           </div>
           </div>
           <div className='d-none d-sm-flex mb-3 justify-content-between align-items-center'>
        <h2 className='' style={{fontWeight:'600',color:'#469B12'}} >Frequently Asked Questions (FAQs)</h2>
        <Link className='' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
        </div>
        </div>
        <div className='mt-md-5 mb-5 pb-md-5'>
        <div className="d-flex d-md-none flex-wrap mb-3">
        {allFaqs.map((faq) => (
           <Button
           key={faq.id}
           className={selectedFaqTitle === faq.faqTitle ? 'primary' : 'faqButton'}
           onClick={() => setSelectedFaqTitle(faq.faqTitle)}
           
         >
           {faq.faqTitle}
         </Button>
        ))}
      </div>
          <Row >

            <Col xs={12} md={4} className="d-none d-md-block">
              <div className='d-flex flex-column' style={{gap:'2.5rem'}}>
              {allFaqs.map((faq) => (
          <Button
            key={faq.id}
            className={selectedFaqTitle === faq.faqTitle ? 'faqButtonActive' : 'faqButton'}
            onClick={() => setSelectedFaqTitle(faq.faqTitle)}
            
          >
            {faq.faqTitle}
          </Button>
        ))}
              </div>
            </Col>
            <Col xs={12} md={8} className='px-lg-5'>
            <div className='mb-sm-5 px-lg-5 ms-lg-3'>
        {selectedFaq.questionswithAnswers.map((qa) => (
          <div key={qa.id} style={{ borderBottom: '1px solid #EAECF0' }} className='mb-2 mb-md-3'>
            <div className='d-flex justify-content-between py-3' onClick={() => toggleAccordion(qa.id)}>
              <div className='d-flex gap-2 align-items-center'>
                
                <h5 className='my-auto' style={{ fontWeight: '500', color: '#000000' }}>{qa.question}</h5>
              </div>
              <Button variant='outline-secondary' style={{ border: 'none', backgroundColor: 'white' }}>
                <img src={openQuestionId === qa.id ? arrowDown : arrowRight} alt='toggle' />
              </Button>
            </div>
            {openQuestionId === qa.id && (
              <div>
             
                <h6 style={{ color: '#000000' }}dangerouslySetInnerHTML={{ __html: qa.answer }}></h6>
              </div>
            )}
          </div>
        ))}
      </div>
            </Col>

          </Row>
          <div className='d-none d-sm-block mt-2 px-lg-5'><h6 style={{fontWeight:'600'}}>We reserve the right to modify these FAQs at any time without prior notice. If you have any questions not addressed in this FAQ, please contact our customer service team at <a href="tel:+2348166641019" style={{color:'#469B12'}}>+2348166641019</a> or email us at <a style={{color:'#469B12'}}href="mailto:support@virtuesshop.co">support@virtuesshop.co</a></h6></div>

          <div className='d-block d-sm-none mt-4 px-lg-5'><h6 style={{fontWeight:'500'}}>We reserve the right to modify these FAQs at any time without prior notice. If you have any questions not addressed in this FAQ, please contact our customer service team at <a href="tel:+2348166641019" style={{color:'#469B12'}}>+2348166641019</a> or email us at <a style={{color:'#469B12'}}href="mailto:support@virtuesshop.co">support@virtuesshop.co</a></h6></div>
     
    </div>
   
    </div>
    </Card>
    </div>
    </>
    
  )
}

export default Faq
