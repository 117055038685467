

import Error from './error';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../mixpanel';
import { transformPath } from '../App';
import createAxios from './httpCommon';
import mixpanel from 'mixpanel-browser';
import { localStorageGetItem, localStorageSetItem,localStorageRemoveItem } from '../App';



export default () => {
  const { error } = Error();
  const navigate = useNavigate();


 
const signup = (
  body,
  setLoading,showToast,setFormData, setShowVerificationModal
  
) => {
  setLoading(true);
  createAxios()
    .then((axios) => {
      axios
      .post('/auth/register', body)
        .then(async (response) => {
          setLoading(false);
       
          const gotResponse = response?.data?.data;
          if (gotResponse) {
            const userId = gotResponse?.user?.id;
            const email = gotResponse?.user?.email;
            const phoneNumber = gotResponse?.user?.phoneNumber || '';
            const preAuthPath = localStorage.getItem('preAuthPath');
           

                      mixpanel.alias(userId);
                      mixpanel.identify(userId);
                      mixpanel.people.set({
                        $email: email,
                        $phone: phoneNumber,
                        $name: `${gotResponse?.user?.firstName} ${gotResponse?.user?.lastName}` || '',
                        created_at: new Date().toISOString(),
                      }); 
                      trackEvent('Successful Signup', {
                        userid: gotResponse?.user?.id, 
              email_address: gotResponse?.user?.email,
              phone_number: gotResponse?.user?.phoneNumber || '',
              signup_type: 'email', 
              referralCode:body?.referralCode || 'none',
              signup_at: new Date().toISOString(),
              signup_location:transformPath(preAuthPath),
              button_clicked: 'Create an account'
                      })

                      // trackCompleteRegistration('success', gotResponse?.user?.email);
                      localStorageSetItem("userData", JSON.stringify(gotResponse.user));
                      localStorageSetItem("userToken", gotResponse?.accessToken);
                     
           
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              confirmPassword: "",})

             
              setShowVerificationModal(true);
                // if (preAuthPath) {
                //   navigate(preAuthPath);
                //  } else {
                //   navigate('/');
                //  }
                // localStorageRemoveItem('preAuthPath');
               
              
            
           
           
           
            
          
              
             
           
          } else {
            showToast({
              toastText: 'Failed to register',
              isSuccess: false
            });
          }
        })
        
       
        .catch((e) => {
          // setLoading(false);
          const preAuthPath = localStorageGetItem
('preAuthPath') || '/';
         const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        
     
            trackEvent('Failed Signup', {
              email_address: body.email,
              failure_reason: e.response?.data?.message || e.message || 'An unexpected error occurred',
              signup_location: transformPath(preAuthPath),
              attempted_at: new Date().toISOString(),
              button_clicked: 'Create an account'
            });
            // trackCompleteRegistration('failure', body?.email);
      
       
          
        });
    });
};

const updatePassword = (setLoading, body, showToast) => {
  setLoading(true);
  const token = localStorageGetItem
("userToken");
  createAxios().then((axios) => {
    axios
      .patch("/auth/update-password", body, {
        headers: {
          "X-Auth-Token": token,
        },
      })
      .then(async (response) => {
        setLoading(false);
        if (response.status === 200) {
          showToast({
            toastText: "Password updated successfully",
            isSuccess: true
          });

          navigate('/')
        }
      })

      .catch((e) => {
        // setLoading(false);
       const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
      });
  });
};


const login = (
  body,
  setLoading,
  showToast,
  setEmail, 
  setPassword
  
) => {
 
  setLoading(true)
  createAxios()
    .then((axios) => {
      axios
      .post('/auth/login', body)
        .then(async (response) => {
          setLoading(false);

          const gotResponse = response?.data?.data;
          if (gotResponse) {
          
            localStorageSetItem("userData", JSON.stringify(gotResponse?.user));
            localStorageSetItem('userToken', gotResponse?.accessToken);
          
          
const preAuthPath = localStorage.getItem('preAuthPath');


            const userId = gotResponse?.user?.id;
            const email = gotResponse?.user?.email;
            const phoneNumber = gotResponse?.user?.phoneNumber || '';
                     

                      mixpanel.alias(userId);
                      mixpanel.identify(userId);
                      mixpanel.people.set({
                        $email: email,
                        $phone: phoneNumber,
                        $name: `${gotResponse?.user?.firstName} ${gotResponse?.user?.lastName}` || '',
                      });

            trackEvent('Successful Login', {
              email_address: body.email,
              login_type: 'email', 
              login_at: new Date().toISOString(),
              login_location:transformPath(preAuthPath),
              button_clicked: 'Sign in', 
              device_type: navigator.userAgent 
            });
            showToast({
              toastText: 'Welcome back!',
              isSuccess: true
            });
         
            setEmail('')
            setPassword('')
            
            setTimeout(() => {
           
              if (preAuthPath) {
               navigate(preAuthPath);
              } else {
               navigate('/');
              }
              localStorageRemoveItem('preAuthPath');
            
          
            }, 2000); 
          } else {
            showToast({
              toastText: 'Failed to login!',
              isSuccess: false
            });
           
          }
        })
        .catch((e) => {
          
          // setLoading(false);
       
          const preAuthPath = localStorageGetItem
('preAuthPath') || '/';
const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
          
          trackEvent('Failed Login', {
            email_address: body.email,
            failure_reason: e.response?.data?.message || e.message || 'An unexpected error occurred',
            login_location: transformPath(preAuthPath),
            attempted_at: new Date().toISOString(),
            button_clicked: 'Sign in'
          });
        });
    });
};

const resetForgotPassword = (setLoading, body, showToast) => {
 
  setLoading(true);
 
  createAxios().then((axios) => {
    axios
      .patch("/auth/reset-password", body)
      .then(async (response) => {
        
        setLoading(false);
        if (response.status === 200) {
     
          setLoading(false);
          const gotResponse = response?.data?.data;

          localStorageSetItem
('userToken', gotResponse?.accessToken);
          localStorageSetItem
("userData", JSON.stringify(gotResponse?.user));
       ;
          trackEvent("Reset Password successful", {
            userid: gotResponse?.user?.id,
            email_address: gotResponse?.user?.email,
            phone_number: gotResponse?.user?.phoneNumber || "",
          });
          showToast({
            toastText: "Password reset successfully",
            isSuccess: true
          });

          navigate('/')
        }
      })

      .catch((e) => {
        setLoading(false);
        trackEvent("Failed Password reset", {
          failure_reason:
            error?.response?.data?.message ||
            error?.message ||
            "An unexpected error occurred",
        });
       const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
      });
  });
};

const sendPasswordResetLink = (
  body,
  showToast,setShowPasswordModal
  
) => {

  createAxios()
    .then((axios) => {
      axios
      .post('/auth/password-reset-link/request', body)
        .then(async (response) => {        
          const gotResponse = response?.data?.data;
           
          if (gotResponse) {
         
            setShowPasswordModal(false)
            showToast({
              toastText: 'Password Reset link has been sent to your email!',
              isSuccess: true
            });
           
 
          } else {
            showToast({
              toastText: 'Failed to send reset link!',
              isSuccess: false
            });
           
          }
        })
        .catch((e) => {
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, showToast, retriesDone);
        });
    });
};

const resendVerification = (setLoading, showToast,  setShowVerificationModal) => {
  setLoading(true);
  const token = localStorageGetItem("userToken");
  createAxios().then((axios) => {
    axios
      .get("/auth/email-verification/request"
      , {
        headers: {
          "X-Auth-Token": token,
        }}
      )
      .then(async (response) => {
        setLoading(false);
        
        setShowVerificationModal(true);
      })

      .catch((e) => {
        // setLoading(false);
       const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
      });
  });
};


  return {
    
    login, signup, updatePassword,sendPasswordResetLink, resetForgotPassword, resendVerification
    };
};