import React, { useState} from 'react'
import {Image,Form, Button,Modal, Card,Row, Col } from 'react-bootstrap'
import homeBg from '../../assets/auth-bg.png'
import logo from '../../assets/virtue-logo-2.png'
import { Link, useNavigate } from 'react-router-dom'
import google from '../../assets/google-icon.svg'
import apple from '../../assets/apple-icon.svg'
import facebook from '../../assets/facebook-icon.svg'
import { localStorageGetItem, localStorageSetItem } from '../../App';
import authRoute from '../../services/authRoute'
import VerificationModal from '../../components/Modal/VerificationModal'
import { useAppContext } from '../../AppContext'
import { trackEvent } from '../../mixpanel'
import { transformPath } from '../../App'
import eye from '../../assets/eye.svg'
import arrowLeft from '../../assets/arrow-left.svg'
import useSavePreviousPath from '../../hooks/useSavePreviousPath'
import { height } from '@fortawesome/free-solid-svg-icons/fa0'



const Signup = () => {
  const {showToast}= useAppContext()
  const [loading, setLoading] = useState(false);

  const {signup}= authRoute();
 
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    referralCode:"",
    password: "",
    confirmPassword: "",
  });
  
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [showVerificationModal,setShowVerificationModal] = useState(false)


  const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
  //   const checkPasswordStrength = (password) => {
  //     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[^\s]{8,}$/;
  //     return passwordRegex.test(password);
  // };

  const checkPasswordStrength = (password) => {
    const passwordRegex = /^.{8,}$/;
    return passwordRegex.test(password);
  };
  
 
  
  const handleSignup = (e) => {
    e.preventDefault();
   
    const validationErrors = {};


   
    if (!formData.firstName) {
      validationErrors.firstName = 'Please enter your First Name';
    }

    if (!formData.lastName) {
      validationErrors.lastName = 'Please enter your Last Name';
    }

    if (!formData.email) {
      validationErrors.email = 'Please enter your Email';
    }

    if (!formData.confirmPassword) {
      validationErrors.confirmPassword = 'Please confirm password';
    } else if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match';
    }

    if (!checkPasswordStrength(formData.password)) {
      validationErrors.password = 'Password must have at least 8 characters';
    }
  
   
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(formData.email)) {
      validationErrors.email = 'Please enter a valid email address';
    }

    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0 ) {
      const body = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        
        password: formData.password,
        email: formData.email,

      }
      if (formData.referralCode !== '' || formData.referralCode !== null || formData.referralCode !== undefined){
        body.referralCode = formData.referralCode
      }

    

 signup (
  body,
  setLoading,showToast,
  setFormData,
  setShowVerificationModal
)
     
    }
  };
  const preAuthPath = localStorage.getItem('preAuthPath') || '/'

  const handleGoogleSignup = () => {
   
    trackEvent('Google Sign in Click', {
      clicked_at: new Date().toISOString(),
    });
   
    window.location = 'https://production-api.virtuesshop.co/auth/google';
  };
  
  

    
  return (
    <>

    <VerificationModal
        showModal={showVerificationModal} setShowVerificationModal={setShowVerificationModal}
        closeModal={() => {setShowVerificationModal(false);navigate(preAuthPath)}}
        modalText='Verification Mail Sent'
        subText='A verification mail has been sent to your email address. Kindly check your mail.'
        btnText='Check Mail'
      />

      {loading ? <div className='justify-content-center align-items-center text-center' style={{ padding: '200px 100px' }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div> :
        <section className=''>
          <div style={{backgroundColor:'white', }} className='pt-3 pb-5 d-block d-md-none'>
             
             <div className='login-form pt-3 pb-5 mb-5 px-4'>
           <Link className='d-flex justify-content-end mb-2' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
   
      <div className='text-center'><Image fluid src={logo} style={{width:'100px'}}/>
      <h2 className='mt-1 mb-3'>Create an account for free</h2></div>
                <Form onSubmit={handleSignup}>
                  <Row>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    
                    <Form.Control
                    type="text"
                    name="firstName"
                    id="firstName"
                    className=""
                    placeholder="First name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  {error.firstName && <div className="text-danger" style={{fontSize:'12px'}}>{error.firstName}</div>}
                    </Form.Group>

                    </Col>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    
                    <Form.Control
                    type="text"
                    name="lastName"
                    id="lastName"
                    className=""
                    placeholder="Last name"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                  {error.lastName && <div className="text-danger" style={{fontSize:'12px'}}>{error.lastName}</div>}
                    </Form.Group>

                    </Col>
                    <Col xs={12}>
                    <Form.Group className='mb-2'>
                    <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                    <Form.Control
                    type="email"
                    name="email"
                    id="email"
                    className=""
                    placeholder="e.g.user@email.com"
                    value={formData.email}
                    onChange={handleChange}
                    
                  />
                  {error.email && <div className="text-danger" style={{fontSize:'12px'}}>{error.email}</div>}
                    </Form.Group>
                    </Col>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    <Form.Label>Password</Form.Label>
                    <div className='position-relative'>
                 <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
      
    >
      {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
    </button> 
    <Form.Control
                       type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    placeholder="Min. of 8 characters"
                    className=""
                    value={formData.password}
                    onChange={handleChange}
                    
                  />
                  {error.password && <div className="text-danger" style={{fontSize:'12px'}}>{error.password}</div>}
                  </div>
                 
                    </Form.Group>
                    </Col>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    <Form.Label>Confirm Password</Form.Label>
                    <div className='position-relative'>
                 <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
      
    >
      {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
    </button> 
    <Form.Control
                       type={showPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="••••••••"
                    className=""
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    
                  />
                  {error.confirmPassword && <div className="text-danger" style={{fontSize:'12px'}}>{error.confirmPassword}</div>}
                  </div>
                 
                    </Form.Group>
                    </Col>
                    <Col xs={12} md={12}>
                    <Form.Group className='mb-2'>
                    <Form.Label style={{color:'#5D5D5D'}}>Referral Code (optional)</Form.Label>
                    <Form.Control
                    type="text"
                    name="referralCode"
                    id="referralCode"
                    className=""
                    placeholder=""
                    value={formData.referralCode}
                    onChange={handleChange}
                    
                  />
                  
                    </Form.Group>
                    </Col>

                  </Row>
                   
                  <div className='d-flex gap-1 align-items-center mt-2 mb-4'>
              <Form.Check type='checkbox'  required
   /> <p className='my-auto'style={{color:'#4F4F4F',}}>By creating an account, you agree to <a href='/terms' style={{color:'#469B12'}}> Terms of Use</a> and <a href='/privacy-policy' style={{color:'#469B12'}}> Privacy Policy</a> </p>
              </div>
                   
                  
                    <div className='mt-2'><Button type='submit' className='btn-primary w-100'> <i className='bi bi-plus'> </i> &nbsp;Create Account</Button></div>
                    <div className=' text-center mt-2'><p className='mb-1' style={{color:'#393939'}}>Already have an account? &nbsp;<Link to ='/login' className='' onClick={() => { trackEvent('Sign in Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Sign in'
  }) }}  style={{fontWeight:'500',color:"#469B12 "}}>Log in</Link></p></div>
                
                <div className='d-flex flex-row justify-content-center gap-3 align-items-center my-1'>
         <div className='line'></div>
         <p className='line-p'>OR</p>
         <div className='line'></div>
     </div>
                
               
                <div className='d-flex flex-column gap-3 mt-2 mb-5 pb-5'>
              
                <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'600'}} className='w-100 mb-5' onClick={handleGoogleSignup}><img src={google} alt='google'/> &nbsp; Sign up with Google</Button>
               
                </div>
                </Form>
               
            </div>
            </div>
            
            <Card style={{border:"none", borderRadius:'0px'}} className='d-none d-md-block'>
           
            <Card.ImgOverlay  style={{
      backgroundImage: `url(${homeBg})`,
      backgroundSize: 'cover', // Ensures the image covers the full area
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents image repetition
      position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', height:'100%', width:'100'}} className=' '>


      <div style={{maxWidth:'32rem',border:'none', boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px',backgroundColor:'white' }} className='mx-md-auto py-2 mt-md-5 mb-5'>
             
           <div className='login-form pt-3 pb-4 pt-lg-2  px-4'>
           <Link className='d-flex justify-content-end mb-2' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
   
      <div className='text-center'><Image fluid src={logo} style={{width:'100px'}}/>
      <h2 className='mt-1 mb-3'>Create an account for free</h2></div>
                <Form onSubmit={handleSignup}>
                  <Row>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    
                    <Form.Control
                    type="text"
                    name="firstName"
                    id="firstName"
                    className=""
                    placeholder="First name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  {error.firstName && <div className="text-danger" style={{fontSize:'12px'}}>{error.firstName}</div>}
                    </Form.Group>

                    </Col>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    
                    <Form.Control
                    type="text"
                    name="lastName"
                    id="lastName"
                    className=""
                    placeholder="Last name"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                  {error.lastName && <div className="text-danger" style={{fontSize:'12px'}}>{error.lastName}</div>}
                    </Form.Group>

                    </Col>
                    <Col xs={12}>
                    <Form.Group className='mb-2'>
                    <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                    <Form.Control
                    type="email"
                    name="email"
                    id="email"
                    className=""
                    placeholder="e.g.user@email.com"
                    value={formData.email}
                    onChange={handleChange}
                    
                  />
                  {error.email && <div className="text-danger" style={{fontSize:'12px'}}>{error.email}</div>}
                    </Form.Group>
                    </Col>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    <Form.Label>Password</Form.Label>
                    <div className='position-relative'>
                 <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
      
    >
      {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
    </button> 
    <Form.Control
                       type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    placeholder="Min. of 8 characters"
                    className=""
                    value={formData.password}
                    onChange={handleChange}
                    
                  />
                  {error.password && <div className="text-danger" style={{fontSize:'12px'}}>{error.password}</div>}
                  </div>
                 
                    </Form.Group>
                    </Col>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    <Form.Label>Confirm Password</Form.Label>
                    <div className='position-relative'>
                 <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
      
    >
      {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
    </button> 
    <Form.Control
                       type={showPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="••••••••"
                    className=""
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    
                  />
                  {error.confirmPassword && <div className="text-danger" style={{fontSize:'12px'}}>{error.confirmPassword}</div>}
                  </div>
                 
                    </Form.Group>
                    </Col>
                    <Col xs={12} md={12}>
                    <Form.Group className='mb-2'>
                    <Form.Label style={{color:'#5D5D5D'}}>Referral Code (optional)</Form.Label>
                    <Form.Control
                    type="text"
                    name="referralCode"
                    id="referralCode"
                    className=""
                    placeholder=""
                    value={formData.referralCode}
                    onChange={handleChange}
                    
                  />
                  
                    </Form.Group>
                    </Col>
                  </Row>
                   
                  <div className='d-flex gap-1 align-items-center mt-2 mb-4'>
              <Form.Check type='checkbox'  required
   /> <p className='my-auto'style={{color:'#4F4F4F',}}>By creating an account, you agree to <a href='/terms' style={{color:'#469B12'}}> Terms of Use</a> and <a href='/privacy-policy' style={{color:'#469B12'}}> Privacy Policy</a> </p>
              </div>
                   
                  
                    <div className='mt-2'><Button type='submit' className='btn-primary w-100'> <i className='bi bi-plus'> </i> &nbsp;Create Account</Button></div>
                    <div className=' text-center mt-2'><p className='mb-1' style={{color:'#393939'}}>Already have an account? &nbsp;<Link to ='/login' className='' onClick={() => { trackEvent('Sign in Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Sign in'
  }) }}  style={{fontWeight:'500',color:"#469B12 "}}>Log in</Link></p></div>
                
                <div className='d-flex flex-row justify-content-center gap-3 align-items-center my-1'>
         <div className='line'></div>
         <p className='line-p'>OR</p>
         <div className='line'></div>
     </div>
                
               
                <div className='d-flex flex-column gap-3 mt-2'>
              
                <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'600'}} className='w-100' onClick={handleGoogleSignup}><img src={google} alt='google'/> &nbsp; Sign up with Google</Button>
               
                </div>
                </Form>
               
            </div>
            </div>
            </Card.ImgOverlay>
            </Card>
            </section>}
          
       </>
    
  )
}


export default Signup