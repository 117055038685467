import { useState } from 'react'
import {Image,Form, Button,Modal, Card } from 'react-bootstrap'
import homeBg from '../../assets/auth-bg.png'
import logo from '../../assets/virtue-logo-2.png'
import { Link, useNavigate } from 'react-router-dom'
import google from '../../assets/google-icon.svg'
import apple from '../../assets/apple-icon.svg'
import facebook from '../../assets/facebook-icon.svg'
import { localStorageGetItem, localStorageSetItem } from '../../App';
import eye from '../../assets/eye.svg'
import authRoute from '../../services/authRoute'
import { useAppContext } from '../../AppContext'
import { trackEvent } from '../../mixpanel'
import { transformPath } from '../../App'
import useSavePreviousPath from '../../hooks/useSavePreviousPath'
import arrowLeft from '../../assets/arrow-left.svg'



const Login = () => {
  const {showToast,showPasswordModal, setShowPasswordModal}= useAppContext()
 
  const {login}= authRoute();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

   


 const handleTogglePassword = () => {
        setShowPassword(!showPassword);
      };


      
  
    const handleLogin = (e)=> {
        e.preventDefault();
     
        const body = { 
          password: password,
          email: email,
        }
  
   login (
    body,
    setLoading,
    showToast,
    setEmail, 
    setPassword
    
  )
    }


    const handleGoogleLogin = () => {
     
      trackEvent('Google Sign in Click', {
        clicked_at: new Date().toISOString(),
      });
     
      window.location = 'https://production-api.virtuesshop.co/auth/google';
    };
 
    
    if (loading) {
      return <div className='justify-content-center align-items-center text-center' style={{ padding: '200px 100px' }}>
        <div className="spinner-border text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>;
    }
  
  return (
    <> 
         
       
      <div style={{backgroundColor:'white', }} className='pt-3 pb-5 d-block d-md-none'>
             
           <div className='login-form pt-3 pb-5 mb-5 px-4'>
           <Link className='d-flex justify-content-end mb-2' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
          
                 <div className='text-center'><Image fluid src={logo} style={{width:'100px'}}/>
                 <h2 className='mt-1 mb-3'>Login to your account</h2></div>
              
                   <Form onSubmit={handleLogin}>
                       <Form.Group className='mb-2'>
                       <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                       <Form.Control
                       type="email"
                       name="email"
                       id="email"
                       className=""
                       placeholder="e.g. user@email.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       required
                     />
                       </Form.Group>
                      
                       <Form.Group className=''>
                       <Form.Label>Password</Form.Label>
                       <div className='position-relative'>
                     <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
         
       >
         {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
       </button> 
       <Form.Control
                          type={showPassword ? 'text' : 'password'}
                       name="password"
                       id="password"
                       placeholder="••••••••"
                       className=""
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       required
                     />
                     </div>
                    
                       </Form.Group>
                       <div className='d-flex justify-content-end mb-3 mt-1'><Link className='text-dark' to='/forgot-password' style={{fontSize:'12px', fontWeight:'600'}}>Forgot password?</Link></div>
                       <div><Button type='submit' className='btn-primary w-100'>Sign in</Button></div>
                       <div className=' text-center mt-2 mb-0 '><p className='mb-1' style={{color:'#393939'}}>Don’t have an account? &nbsp;<Link to='/signup' className='' onClick={() => {trackEvent('Create an Account Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Create an account'
  })}} style={{fontWeight:'500',color:"#469B12 "}}>Sign up</Link></p></div>
                   </Form>
                   <div className='d-flex flex-row justify-content-center gap-3 align-items-center mb-1'>
            <div className='line'></div>
            <p className='line-p'>OR</p>
            <div className='line'></div>
        </div>
                   
        <div className='d-flex flex-column mt-2 mb-5 pb-5'>
                     
                   <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'600'}} className='w-100 mb-5' onClick={handleGoogleLogin}><img src={google} alt='google'/> &nbsp; Sign in with Google</Button>
                  
                   </div>
                   
                  
               </div>
          </div>
          
            <Card style={{border:"none", borderRadius:'0px'}} className='d-none d-md-block'>
            {/* <Card.Img src={homeBg} alt='bg' style={{ opacity: '', width:'100%',  height:'100%', maxHeight: '750px', objectFit: 'cover' }} className=''/> */}

<Card.ImgOverlay  style={{
      backgroundImage: `url(${homeBg})`,
      backgroundSize: 'cover', // Ensures the image covers the full area
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents image repetition
      position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', height:'100%', width:'100'}} className=' '>


      <div style={{width:'100%', maxWidth:'32rem',border:'none', boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px',backgroundColor:'white' }} className=' mx-md-auto py-2 mt-md-5 mb-5'>
             
           <div className='login-form pt-3 pb-4 pt-lg-2  px-4'>
           <Link className='d-flex justify-content-end mb-2' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
          
                 <div className='text-center'><Image fluid src={logo} style={{width:'100px'}}/>
                 <h2 className='mt-1 mb-3'>Login to your account</h2></div>
              
                   <Form onSubmit={handleLogin}>
                       <Form.Group className='mb-2'>
                       <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                       <Form.Control
                       type="email"
                       name="email"
                       id="email"
                       className=""
                       placeholder="e.g. user@email.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       required
                     />
                       </Form.Group>
                      
                       <Form.Group className=''>
                       <Form.Label>Password</Form.Label>
                       <div className='position-relative'>
                     <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
         
       >
         {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
       </button> 
       <Form.Control
                          type={showPassword ? 'text' : 'password'}
                       name="password"
                       id="password"
                       placeholder="••••••••"
                       className=""
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       required
                     />
                     </div>
                    
                       </Form.Group>
                       <div className='d-flex justify-content-end mb-3 mt-1'><Link className='text-dark' to='/forgot-password' style={{fontSize:'12px', fontWeight:'600'}}>Forgot password?</Link></div>
                       <div><Button type='submit' className='btn-primary w-100'>Sign in</Button></div>
                       <div className=' text-center mt-2 mb-0 '><p className='mb-1' style={{color:'#393939'}}>Don’t have an account? &nbsp;<Link to='/signup' className='' onClick={() => {trackEvent('Create an Account Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Create an account'
  })}} style={{fontWeight:'500',color:"#469B12 "}}>Sign up</Link></p></div>
                   </Form>
                   <div className='d-flex flex-row justify-content-center gap-3 align-items-center mb-1'>
            <div className='line'></div>
            <p className='line-p'>OR</p>
            <div className='line'></div>
        </div>
                   
        <div className='d-flex flex-column mt-2'>
                     
                   <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'600'}} className='w-100' onClick={handleGoogleLogin}><img src={google} alt='google'/> &nbsp; Sign in with Google</Button>
                  
                   </div>
                   
                  
               </div>
          </div>
          </Card.ImgOverlay>
          </Card>
          
                  
     </>
    
 
       
   
  )
}

export default Login
