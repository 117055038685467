import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet';

import { useParams, Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import GuestNavbar, { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { localStorageGetItem, localStorageSetItem } from '../../App';
import { Row, Col, Card, Button,Modal, Image } from 'react-bootstrap';
import arrow from '../../assets/typcn_arrow-back-outline.svg'
import { useAppContext } from '../../AppContext';
import love from '../../assets/love.svg'
import { trackEvent } from '../../mixpanel';
import foodBox from '../../assets/food-box.png'
import productRoute from '../../services/productRoute';
import yam from '../../assets/yam.png'
import SubscriptionModal from '../../components/Modal/SubscriptionModal';
import arrowLeft from '../../assets/arrow-left.svg'
import bag from '../../assets/bag-outline.svg'
import orderRoute from '../../services/orderRoute';



const Subscriptions = () => {
  const {showToast,setWishlistItems, wishlistItems,setApiWishlistItems, apiWishlistItems, isMobile} = useAppContext()
    const [subscriptionBoxes, setSubscriptionBoxes] = useState([]);
    const [userBoxes, setUserBoxes] = useState([]);
    const [activeSubscriptions, setActiveSubscriptions] = useState('All');
    const {updateWishlist, getWishlist}= orderRoute();
    const [showSubscriptionModal, setShowSubscriptionModal] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const location = useLocation();
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
const {getAllBoxes,getUserBoxes,getSingleBoxes}= productRoute()
const [filledHearts, setFilledHearts] = useState({});
    const userData = getParsedLocalStorageItem('userData');
    const userToken = localStorageGetItem
('userToken');;
    const isLoggedIn = !!userData && !!userToken;
  

    useEffect(()=>{
   
      getAllBoxes(
        setLoading, showToast, setSubscriptionBoxes, 
       
      )
    
  },[])
  useEffect(()=>{
   
    if(isLoggedIn){
     getUserBoxes(
         setLoading, showToast, setUserBoxes, 
      
       )
    }
   
 },[isLoggedIn])

useEffect(() => {
  const filterBoxes = () => {
    if (activeSubscriptions === 'All') {
      setFilteredSubscriptions(subscriptionBoxes);
    } else {
      const activeUserBoxes = userBoxes?.filter(box => box.status === 'active') || [];
      setFilteredSubscriptions(activeUserBoxes);
    }
  };
  
  filterBoxes();
}, [activeSubscriptions, subscriptionBoxes, userBoxes]);



    useEffect(() => {
      if (isLoggedIn) {
        // Fetch the wishlist if logged in
        getWishlist(setLoading, showToast, setApiWishlistItems);
      }
    }, [isLoggedIn]);
   
    useEffect(() => {
      let wishlist = [];
    
      if (isLoggedIn) {
        // If logged in, use wishlist items fetched from API
        wishlist = apiWishlistItems;
        const updatedWishlistStatus = {};
    subscriptionBoxes.forEach(box => {
      const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.box?.id === box?.id);
      updatedWishlistStatus[box?.id] = isInWishlist;
    });
    setFilledHearts(updatedWishlistStatus);
      } else {
        // If not logged in, retrieve wishlist items from local storage
        const wishlist = JSON.parse(localStorageGetItem
  ('wishlistItems')) || [];
      
        const updatedWishlistStatus = {};
    subscriptionBoxes.forEach(box => {
      const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.box?.id === box.id);
      updatedWishlistStatus[box.id] = isInWishlist;
    });
    setFilledHearts(updatedWishlistStatus);
      }
    
     
    
    }, [subscriptionBoxes, apiWishlistItems]);

    const toggleFill = (boxId) => {



      if (isLoggedIn) {
  
        updateWishlist(
          boxId,   
          showToast, 
          filledHearts,
          setFilledHearts,
          apiWishlistItems, 
          setApiWishlistItems, 
          subscriptionBoxes
        );
      }
       else {
        const newState = !filledHearts[boxId];
        setFilledHearts(prevState => ({
          ...prevState,
          [boxId]: newState,
        }));
    
        let wishlist = getParsedLocalStorageItem('wishlistItems') || [];
        const index = wishlist.findIndex(item => item?.box?.id === boxId);
        let toastText = '';
        
        if (newState) {
          // Add to local wishlist if not already present
          if (index === -1) {
            const boxToAdd = subscriptionBoxes.find(box => box?.id === boxId);
            wishlist = [{box:boxToAdd}, ...wishlist]; // Prepend the new product
            toastText = 'Item added to Wishlist!';
            // trackAddToWishlist(boxId, boxToAdd.productName);
          }
        } else {
          // Remove from local wishlist if present
          if (index !== -1) {
            wishlist = wishlist.filter(item => item.box?.id !== boxId);
            toastText = 'Item removed from Wishlist!';
          }
        }
    
        // Update local storage and state
        localStorageSetItem('wishlistItems', JSON.stringify(wishlist));
        setWishlistItems(wishlist);
    
        // Show the appropriate toast message
        showToast({
          toastText: toastText,
          isSuccess: true
        });
        const box = subscriptionBoxes.find(p => p.id === boxId);
        trackEvent(newState ? `${box?.name} Added to Wishlist` : `${box?.name} Removed from Wishlist`, {
          'box ID': boxId,
          'box Name': box?.name,
          'User': userData?.email || 'Anonymous',
       
        });
      }
    
    
      
    };

    const handleCancel = (subscription) => {

     
      trackEvent('Cancel Subscription Clicked', {
        'Subscription ID': subscription?.id,
        'Box Name': subscription?.boxes?.box?.name,
       
        'User': userData?.email || 'Anonymous',
        // Any other relevant properties
      });
     
    navigate(`/profile/subscriptions/cancel/${subscription?.id}`);
    };

  const handleSubscriptionChange = (subscription) => {
    setActiveSubscriptions(subscription); 
    
  };

  const handleShowBoxModal = (subscription) => {


setSelectedSubscription(subscription)

    setShowSubscriptionModal(true);
    trackEvent(`${subscription?.name} Viewed`, {
      'User': userData?.email || 'Anonymous',
    
    });
  
  };

  const handleCloseSubscriptionModal = () => {
    setShowSubscriptionModal(false);
  };

  const handleBack = () => {
   
    navigate(-1);
  }
  const handleSubscribe = (subscription) => {
    navigate('/subscription-summary', { state: { subscription } });
  };

  return (
    <div>
        <Helmet>
        <title>Virtue's Shop - Subscription Boxes</title>
        <meta name="description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta name="keywords" content="Food, Foodstuffs, Fresh tomatoes, Fresh food, Fresh food market, Fresh food delivery, Foodstuff shop in Lagos, Foodstuff delivery shop in Lagos, Affordable food store" />

        <meta property="og:title" content="Virtue's Shop - Subscription boxes" />
        <meta property="og:description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta property="og:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta property="og:url" content="https://app.virtuesshop.co/" />
        <meta property="og:type" content="website" />


        <meta name="twitter:title" content="Virtue's Shop - Subscription boxes" />
        <meta name="twitter:description" content="Affordable food store with different categories of fresh food items to de delivered to your doorstep within Lagos and Ogun State. " />
        <meta name="twitter:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta name="twitter:card" content="summary_large_image" />

        
        <meta name="author" content="Virtue's Shop" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://app.virtuesshop.co/" />

      </Helmet>

           
      {isMobile ? 
       <div className='py-5 my-5 my-sm-0'>
        <div className='mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex d-sm-none align-items-center gap-3 px-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>Subscription Boxes</h5>
              
           </div>
           </div>
           <div className=' px-3 px-xxl-5 mx-lg-5 mb-5'>
           <div className="d-flex gap-5 mb-5" style={{borderBottom: '1px solid #919191'}}>
      {['All', isLoggedIn && 'My'].filter(Boolean).map((subscription, index) => (
        <div
          key={index}
          className={`subscription-item ${activeSubscriptions === subscription ? 'active' : ''}`}
          onClick={() => handleSubscriptionChange(subscription)}
        style={{fontSize:'14px'}}>
          {subscription} Boxes
        </div>
      ))}
    
      </div>

    

        {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
{activeSubscriptions === 'All' ? <>
      { filteredSubscriptions?.length > 0 ? ( 
      
      <Row className='mt-5'>

        {filteredSubscriptions?.map((subscription)=> (
          <Col xs={12} md={4} className='mb-5 mb-md-4 px-2'  key={subscription?.id} >
         
            <Row>
              <Col xs={8}>
             
          <h5 style={{color:'#344054', fontWeight:'600'
          }} className='mb-0 pb-1'>
          {subscription?.name}
          </h5>
         
       
          <p style={{color:'#98A2B3'}} className='mb-0 pb-2'>{subscription?.description}</p>
          <p style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '600'}} className='mb-0 p b-1'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subscription?.amount)}
          </p>
            
           
             <div className='d-flex gap-2 align-items-center mt-1'>
            
             <Button  className='d-flex text-center justify-content-center align-items-center px-2' style={{height:'21px', fontSize:'12px'}} onClick={() => handleSubscribe(subscription)}>SUBSCRIBE</Button>
             <div className='d-flex gap-1 align-items-center'>
             <i  onClick={() => toggleFill(subscription?.id)}
                  className={`bi ${filledHearts[subscription?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1rem',
                    color: filledHearts[subscription?.id] ? '#469B12' : '#394357', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }} ></i>
              <p className='my-2' style={{color: filledHearts[subscription?.id] ? '#469B12' : '#394357', fontSize:'13px'}}> {/* Green if in wishlist, black otherwise */}
    {filledHearts[subscription?.id] ? 'Added to Wishlist' : 'Add to Wishlist'}
  </p>
             </div>
    
                     
          
                
                 
             </div>
              </Col>
              <Col xs={4}>
              <div className='position-relative mb-3 h-100'>
                  <Card.Img src={subscription?.boxImage}  style={{objectFit:'cover'}} alt='food box' className='h-100'/>
                  <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-start mt-4 pt-5' > 
 <p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'6px', width:'auto', cursor:'pointer'}} onClick={()=>handleShowBoxModal(subscription)} className='px-2'><img src={arrow} alt='arrow'/> Look inside</p>
  </Card.ImgOverlay>
                 
                                           
                  </div>
              </Col>
            </Row>
             
          
           </Col>
        ))}

</Row>
     ): <div className='py-5 px-3 px-md-5'>
       
      <div className="text-center fw-medium" style={{ padding: "70px 0px" }}>No Subscription Boxes</div>
     </div>
     } </> : <>
     { filteredSubscriptions?.length > 0 ? ( 
     
     <Row className='mt-5'>

       {filteredSubscriptions?.map((subscription)=> (
         <Col xs={12} md={4} className='mb-5 mb-md-4 px-2'  key={subscription?.id} >
        
           <Row>
             <Col xs={8}>
            
         <h5 style={{color:'#344054', fontWeight:'600'
         }} className='mb-0 pb-1'>
         {subscription?.boxes?.box?.name}
         </h5>
        
      
         <p style={{color:'#98A2B3'}} className='mb-0 pb-2'>{subscription?.boxes?.box?.description}</p>
         <p style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '600'}} className='mb-0 p b-1'>
         {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subscription?.boxes?.box?.amount)}
         </p>
           
          
            <div className='d-flex gap-2 align-items-center mt-1'>
           
            <Button  className='d-flex text-center justify-content-center align-items-center px-2' style={{height:'21px', fontSize:'12px'}} >SUBSCRIBED</Button>
          
   
                    
         
               
                
            </div>
             </Col>
             <Col xs={4}>
             <div className='position-relative mb-3 h-100'>
                 <Card.Img src={subscription?.boxes?.box?.boxImage}  style={{objectFit:'cover'}} alt='food box' className='h-100'/>
                 <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-start mt-4 pt-5' > 
<p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'6px', width:'auto', cursor:'pointer'}} onClick={()=>handleShowBoxModal(subscription?.boxes?.box)} className='px-2'><img src={arrow} alt='arrow'/> Look inside</p>
 </Card.ImgOverlay>
                
                                          
                 </div>
             </Col>
           </Row>
            
         
          </Col>
       ))}

</Row>
    ): <div className='py-5 px-3 px-md-5'>
      
   
       
       <div className="d-flex flex-column gap-3 justify-content-center justify-items-center text-center fw-medium mt-5 pt-5">
<div className='justify-content-center'>
     <Image fluid src={bag} alt='' style={{width:'120px', height:'120px'}} />
</div>
       
        <h3 style={{fontWeight:'600', color:'#1D2939'}}>You have no subscriptions</h3>
  
     </div>
    </div>
    } </>}
     </>
      )}
           </div>
           </div> :
      
        <section className='px-3 px-xxl-5'>
        <div className='my-5 mx-xl-5 pb-5'> 
            <div className="d-flex gap-2 align-items-center mb-4" >  
  <Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-right" ></i></p></Link>
  <p className='' style={{color:'#1D2939', fontWeight:'600'}} > Subscription Boxes</p>
  
      </div>

      <div className="d-flex gap-5 mb-5" style={{borderBottom: '1px solid #919191', width:'220px'}}>
      {['All', isLoggedIn && 'My'].filter(Boolean).map((subscription, index) => (
        <div
          key={index}
          className={`subscription-item ${activeSubscriptions === subscription ? 'active' : ''}`}
          onClick={() => handleSubscriptionChange(subscription)}
        >
          {subscription} Boxes
        </div>
      ))}
    
      </div>

    

        {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>

{activeSubscriptions === 'All' ? <>
{ filteredSubscriptions?.length > 0 ? ( 
      
      <Row className='mt-5'>

        {filteredSubscriptions?.map((subscription)=> (
          <Col xs={6} md={4} className='mb-3 mb-md-4'  key={subscription?.id} >
           <Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
          }} >
                  <Card className='position-relativeh-100 border-0 p-3'>
                  <Card.Img src={subscription?.boxImage}  style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', height:'100%',maxHeight:'250px'}} alt='food box'/>
                  <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-center mt-4' > 
 <p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'18px', width:'auto', cursor:'pointer'}} onClick={()=>handleShowBoxModal(subscription)} className='px-2'><img src={arrow} alt='arrow'/> Look inside</p>
  </Card.ImgOverlay>
                 
                                           
                  </Card>
                 
                  
                  < Card.Body className='px-3 pt-2 mt-0 pb-4'>
                  <div className='d-flex justify-content-between mb-2'>
          <h5 style={{color:'#344054', fontWeight:'600'
          }} className='my-0 py-0'>
          {subscription?.name}
          </h5>
          <h6 style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '600' }} className='my-auto'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subscription?.amount)}
          </h6>
          </div>
          <p style={{color:'#4F4F4F'}}>{subscription?.description}</p>
              
            
           
             <div className='d-flex gap-3 align-items-center'>
            
             <Button  className='d-flex text-center justify-content-center align-items-center px-2' style={{height:'30px'}} onClick={() => handleSubscribe(subscription)}>SUBSCRIBE</Button>
             <div className='d-flex gap-1 align-items-center'>
             <i  onClick={() => toggleFill(subscription?.id)}
                  className={`bi ${filledHearts[subscription?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1.3rem',
                    color: filledHearts[subscription?.id] ? '#469B12' : '#394357', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }} ></i>
              <p className='my-auto' style={{color: filledHearts[subscription?.id] ? '#469B12' : '#394357'}}> {/* Green if in wishlist, black otherwise */}
    {filledHearts[subscription?.id] ? 'Added to Wishlist' : 'Add to Wishlist'}
  </p>
           
             </div>
    
                     
          
                
                 
             </div>
          </Card.Body>
           
                   
                   
                 
                </Card>
          
           </Col>
        ))}

</Row>
     ): <div className='py-5 px-3 px-md-5'>
       
      <div className="text-center fw-medium" style={{ padding: "70px 0px" }}>No Subscription Boxes</div>
     </div>
     }</> : <>
     { filteredSubscriptions?.length > 0 ? ( 
     
     <Row className='mt-5'>

       {filteredSubscriptions?.map((subscription)=> (
         <Col xs={6} md={4} className='mb-3 mb-md-4'  key={subscription?.id} >
          <Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
         }} >
                 <Card className='position-relative h-100 border-0 p-3'>
                 <Card.Img src={subscription?.boxes?.box?.boxImage}  style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', height:'100%',maxHeight:'250px', width:'100%'}} alt='food box'/>
                 <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-center mt-4' > 
<p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'18px', width:'auto', cursor:'pointer'}} onClick={()=>handleShowBoxModal(subscription?.boxes?.box)} className='px-2'><img src={arrow} alt='arrow'/> Look inside</p>
 </Card.ImgOverlay>
                
                                          
                 </Card>
                
                 
                 < Card.Body className='px-3 pt-2 mt-0 pb-4'>
                 <div className='d-flex justify-content-between align-items-center mb-2'>
         <h5 style={{color:'#344054', fontWeight:'600'
         }} className='my-0 py-0'>
         {subscription?.boxes?.box?.name}
         </h5>
         <p style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '600' }} className='my-auto'>
         {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subscription?.boxes?.box?.amount)}
         </p>
         </div>
         <p style={{color:'#4F4F4F', fontSize:'12px'}}>{subscription?.boxes?.box?.description}</p>
             
           
          
         <div className='d-flex gap-2 align-items-center mt-1'>
     
            <Button  className='d-flex text-center justify-content-center align-items-center px-2' >SUBSCRIBED</Button> 
           
                    
         
               
                
            </div>
         </Card.Body>
          
                  
                  
                
               </Card>
         
          </Col>
       ))}

</Row>
    ): <div className='py-5 px-3 px-md-5'>
      
      <div className="d-flex flex-column gap-4 justify-content-center justify-items-center text-center fw-medium">
<div className='justify-content-center'>
<Image fluid src={bag} alt='' style={{width:'200px', height:'200px'}} />
</div>
      
       <h2 style={{fontWeight:'600', color:'#1D2939'}}>You have no subscriptions</h2>
     </div>
    
    </div>
    }</>}
     
     </>
      )}

</div>

    
        </section>}
  <SubscriptionModal showModal={showSubscriptionModal} handleCloseModal={handleCloseSubscriptionModal} selectedSubscription={selectedSubscription}/>
   
  </div>
  )
}

export default Subscriptions