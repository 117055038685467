import React,{useState, useEffect} from 'react'
import { Card, Col, Row, Button } from 'react-bootstrap'
import SidebarNavbar from '../../components/Navbar/SideNavbar'
import {Link,  useNavigate, useParams} from 'react-router-dom'
import arrowLeft from '../../assets/arrow-left.svg'
import orderRoute from '../../services/orderRoute'
import { useAppContext } from '../../AppContext'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Invoice from './Invoice'
// import { trackAddToCart } from '../../App'
import { trackEvent } from '../../mixpanel'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar'


const OrderDetails = () => {
  const {showToast, cartItems, setCartItems, updateCart}= useAppContext();
  const {orderId} =  useParams();
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);
  const userData = getParsedLocalStorageItem('userData');

  const [canModifyOrder, setCanModifyOrder] = useState(false);
  const {getSingleOrder}= orderRoute();
  const navigate = useNavigate();

  useEffect(()=> {
    getSingleOrder (
      orderId, setOrderLoading,showToast, setOrderDetail
    )
  },[])

  useEffect(() => {
    if (orderDetail?.cart && orderDetail?.cart?.length > 0 && orderDetail?.deliveryDate && orderDetail?.orderInfo?.length > 0) {
      const lastOrderInfo = orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1];
      if (orderDetail?.cart && orderDetail?.cart?.length > 0 && lastOrderInfo?.orderStatus?.toLowerCase() === 'order confirmed') {
        const deliveryDate = new Date(orderDetail?.deliveryDate);
        deliveryDate?.setDate(deliveryDate?.getDate() - 1);
        deliveryDate?.setHours(23, 0, 0, 0);
        const currentTime = new Date();
        setCanModifyOrder(currentTime < deliveryDate);
       
      }
    }
  }, [orderDetail?.deliveryDate, orderDetail?.cart, orderDetail?.orderInfo]);


  
  

  const handleModifyOrderClick = () => {
    trackEvent('Modify Order Clicked', {
      'User': userData?.email,
      attempted_at: new Date().toISOString(),
     
    });
    navigate(`/profile/order-modification/${orderId}`);
    
   
  };

  const totalShoppingInstructionsFee = orderDetail?.shoppingInstructions?.reduce((sum, instruction) => sum + instruction?.instruction?.amount, 0) || 0;

  const getShoppingInstructions = (productName, shoppingInstructions) => {
    return shoppingInstructions
      .filter(instruction =>
        instruction.products.some(product => product.productName === productName)
      )
      .map(instruction => instruction.instruction.name)
      .join(', ');
  };
  const steps =
  orderDetail?.deliveryType === "pickup"
    ? [
        { label: "Order Confirmed", key: "Order confirmed" },
        { label: "Order Processing", key: "Order processing" },
        { label: "Ready for Pickup", key: "Ready for Pickup" },
        { label: "Order Picked Up", key: "Order Picked Up" },
      ]
    : orderDetail?.deliveryType === "delivery"
    ? [
        { label: "Order Confirmed", key: "Order confirmed" },
        { label: "Order Processing", key: "Order processing" },
        { label: "Out for Delivery", key: "Out for delivery" },
        { label: "Order Delivered", key: "Order delivered" },
      ]
    : [];


  const downloadPdf = async () => {
    // Show the invoice
    setShowInvoice(true);
  
    // Ensure the DOM has updated
    requestAnimationFrame(async () => {
      const input = document.getElementById('invoice');
      if (!input) {
        console.error('Invoice element not found.');
        setShowInvoice(false); // Hide invoice if not found
        return;
      }
  
      const canvas = await html2canvas(input, {
        scale: 2.0, // Adjust as needed
        useCORS: true,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight
      });
  
      const imgData = canvas.toDataURL('image/png');
  
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });
  
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('virtueshop-invoice.pdf');
  
      // Attempt to hide the invoice immediately
      setShowInvoice(false);
    });
  };

  const formatDateToLongForm = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const reorderSingleItem = (item) => {
    // Check if the product is out of stock
    if (item.productId.stockLevel === 'out_of_stock' || item.productId.quantityAvailable === 0) {
        showToast({ toastText: 'This product is currently out of stock', isSuccess: false });
        return;
    }

    let newCartItems = [...cartItems];
    const cartIndex = newCartItems.findIndex(cartItem => cartItem.id === item.productId.id);
  
    if (cartIndex !== -1) {
        // If item already exists in the cart, update its quantity by adding the new quantity to the existing one
        const newQuantity = newCartItems[cartIndex].quantity + item.quantity;

        // Check if the new quantity exceeds the available stock
        if (newQuantity > item.productId.quantityAvailable) {
            showToast({ toastText: 'This product has limited stock!', isSuccess: false });
            return;
        }

        newCartItems[cartIndex].quantity = newQuantity;
        showToast({ toastText: 'Item added to Cart', isSuccess: true });
    } else {
        // Check if quantity exceeds available stock before adding to cart
        if (item.quantity > item.productId.quantityAvailable) {
            showToast({ toastText: 'This product has limited stock!', isSuccess: false });
            return;
        }

        // If item doesn't exist in the cart, add it with the specified quantity
        newCartItems.push({
            id: item.productId.id,
            ...item.productId,
            quantity: item.quantity
        });
        showToast({ toastText: 'Item added to Cart', isSuccess: true });
    }
  
    setCartItems(newCartItems);
    updateCart(newCartItems); 
    // trackAddToCart(item?.productId?.id, item?.productId?.productName, item?.productId?.price);
    trackEvent(`${item?.productId?.productName} Added to Cart`, {
      'Product ID': item?.productId?.id,
      'Product Name': item?.productId?.productName,
      'Category Name': item?.productId?.category,
      'User': userData?.email || 'Anonymous',
      'Location':'Order Details Page'
    });
};

const reorderAllItems = () => {
    let newCartItems = [...cartItems];

    orderDetail?.cart?.forEach(orderCartItem => {
        // Check if the product is out of stock or quantity available is 0
        if (orderCartItem.productId.stockLevel === 'out_of_stock' || orderCartItem.productId.quantityAvailable === 0) {
            showToast({ toastText: `${orderCartItem.productId.productName} is currently out of stock`, isSuccess: false });
            return;
        }

        const cartIndex = newCartItems.findIndex(cartItem => cartItem.id === orderCartItem.productId.id);

        if (cartIndex !== -1) {
            // If item already exists in the cart, update its quantity by adding the new quantity to the existing one
            const newQuantity = newCartItems[cartIndex].quantity + orderCartItem.quantity;

            // Check if the new quantity exceeds the available stock
            if (newQuantity > orderCartItem.productId.quantityAvailable) {
                showToast({ toastText: `We have limited stock for ${orderCartItem.productId.productName}`, isSuccess: false });
                return; // Skip adding this item to cart
            }

            newCartItems[cartIndex].quantity = newQuantity;
            showToast({ toastText: 'Items added to Cart', isSuccess: true });
        } else {
            // Check if quantity exceeds available stock before adding to cart
            if (orderCartItem.quantity > orderCartItem.productId.quantityAvailable) {
                showToast({ toastText: `We have limited stock for ${orderCartItem.productId.productName}`, isSuccess: false });
                return; // Skip adding this item to cart
            }

            // If item doesn't exist in the cart, add it with the specified quantity
            newCartItems.push({
                id: orderCartItem.productId.id,
                ...orderCartItem.productId,
                quantity: orderCartItem.quantity
            });
            showToast({ toastText: 'Items added to Cart', isSuccess: true });
        }
        // trackAddToCart(orderCartItem.productId.id, orderCartItem.productId.productName, orderCartItem.productId.productPrice);
        trackEvent(`${orderCartItem?.productId?.productName} Added to Cart`, {
          'Product ID': orderCartItem?.productId?.id,
          'Product Name': orderCartItem?.productId?.productName,
          'Category Name': orderCartItem?.productId?.category,
          'User': userData?.email || 'Anonymous',
          'Location':'Order Details Page'
        });
    });

    setCartItems(newCartItems); 
    updateCart(newCartItems);
};


const findStatusInfo = (statusKey) => {
  return orderDetail?.orderInfo?.find(info => info?.orderStatus.toLowerCase() === statusKey.toLowerCase());
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const year = date.getFullYear(); // Use full year
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Format the date part
  let formattedDate = `${month}-${day}-${year}`;
  
  // Format the time part
  let formattedTime;
  if (hours > 12) {
    formattedTime = `${(hours - 12).toString().padStart(2, '0')}:${minutes} PM`;
  } else if (hours == 12) {
    formattedTime = `12:${minutes} PM`;
  } else if (hours == 0) {
    formattedTime = `12:${minutes} AM`;
  } else {
    formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} AM`;
  }

  // Combine the date and time with a comma and a space as separator
  return `${formattedDate}, ${formattedTime}`;
};


  return (
    <div>
        {!showInvoice && 
       <div className='py-5 my-5 my-sm-0 '>
      
       <div className='d-flex justify-content-between d-sm-none mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex d-sm-none align-items-center gap-3 ps-3 mb-3' >
            <Link to='/profile/orders'><img src={arrowLeft} className=''/></Link>
          
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>Order Details</h5>
              
           </div>
           {canModifyOrder && (
                              
                              <h6 
                                onClick={handleModifyOrderClick} 
                                style={{ color: '#469B12', fontWeight: '600', cursor: 'pointer', textDecoration:'underline' }} className='pe-4'>
                                Modify Order
                              </h6>
                            
                          )}
           </div>
           <div className='px-3 px-xxl-5 mx-lg-5 mb-5 mb-sm-0'>
<div className="d-none d-sm-flex gap-2 align-items-center mb-3" >  
<Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-left" style={{color:'#1D2939'}} ></i></p></Link>

<Link className='' to='/profile/orders' ><p style={{color:'#475467'}}>Orders &nbsp;  <i className="bi bi-chevron-left" style={{color:'#1D2939'}} ></i></p></Link><p style={{color:'#1D2939', fontWeight:'600'}}>Order Details</p>
</div>
<Row>
<Col xs={12} sm={3} className='d-none d-sm-block'>
    <SidebarNavbar/>
</Col>
<Col xs={12} sm={9} className='ps-lg-4 pe-lg-3'>
    
<div className=''>
  
  <div className='d-none d-sm-flex mb-4 justify-content-between'>
  <div className=''>
               <h5 style={{color:'#0C0C0C', fontWeight:'500'}} className='mb-2'>Order Details</h5>
               <h6 style={{color:'#717171', fontWeight:'400'}}>Review the details of your order and reorder your favorite items.</h6>
           </div>
  {canModifyOrder && (
                              
                                <h6 
                                  onClick={handleModifyOrderClick} 
                                  style={{ color: '#469B12', fontWeight: '600', cursor: 'pointer', textDecoration:'underline' }}>
                                  Modify Order
                                </h6>
                              
                            )}
  </div>
         
           {orderLoading ? (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
<div className="spinner-border text-success" role="status">
<span className="visually-hidden">Loading...</span>
</div>
</div>): 
  <div>
           <div>
            <Row className='mb-5 mb-sm-4'>
              {orderDetail?.cart && orderDetail?.cart?.length > 0 ?
              <Col xs={12} md={7} className='mb-3 mb-sm-0'>
              <Card className='px-3 pt-3 pb-2 h-100' style={{backgroundColor:'#F6FAF3', border:'none'}}>
              <div className="stepper-container">
      {steps.map((step) => {
        const statusInfo = findStatusInfo(step.key);
        return (
          <div key={step.key} className="step">
            <div className={`step-circle ${statusInfo ? 'filled' : ''}`}></div>
            <div className="step-content d-flex justify-content-start align-items-center" style={{width: '100%'}}>
  <div style={{flexGrow: 1}}>
    <div className="step-label" style={{color:'#353542', fontWeight:'500'}}>
      {step.label}
    </div>
    {statusInfo && statusInfo.orderStatus === 'Order delivered' && (
      <div style={{color:'#353542', fontWeight:'500'}}>
        {orderDetail?.address?.streetAddress}, {orderDetail?.address?.city?.name}, {orderDetail?.address?.state?.name}
      </div>
    )}
  </div>
  {statusInfo && (
    <div className="step-time" style={{marginLeft: 'auto', paddingLeft:'auto'}}>{formatDate(statusInfo.time)}</div>
  )}
</div>

            
          </div>
        );
      })}
    </div>
              </Card>
              </Col> :
               <Col  xs={12} md={7} className='mb-3 mb-sm-0'>
               <Card className='px-3 pt-3 pb-2 d-flex flex-column gap-3 h-100 mb-3' style={{backgroundColor:'#F6FAF3', border:'none'}}>
               <h6 style={{color:'#353542', fontWeight:'500'}}>Voucher Name:  &nbsp;
<span style={{fontWeight:'400'}}> {orderDetail?.voucher?.name}</span></h6>
               <h6 style={{color:'#353542', fontWeight:'500'}}>Recipient Name: 
               &nbsp;
<span style={{fontWeight:'400'}}>
   {orderDetail?.recipientName}</span>

</h6>
<h6 style={{color:'#353542', fontWeight:'500'}}>Recipient Email: 
&nbsp;
<span style={{fontWeight:'400'}}>
 {orderDetail?.recipientEmail}</span>

</h6>


<h6 style={{color:'#353542', fontWeight:'500'}}>Voucher Code: &nbsp;
<span style={{fontWeight:'400'}}>
 {orderDetail?.voucherCode}</span>

</h6>
      
               <h6 style={{color:'#353542', fontWeight:'500'}}>Amount Paid: <span style={{fontWeight:'400'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.amount)}</span></h6>
               
               
             
               </Card>
               
               </Col>}
              <Col  xs={12} md={5} className=''>
                <Card className='px-3 pt-3 pb-2 d-flex flex-column gap-3 h-100' style={{backgroundColor:'#F6FAF3', border:'none'}}>
                <h6 style={{color:'#353542', fontWeight:'500'}}>Order ID: {orderDetail?.sku ? orderDetail?.sku : orderDetail?.id}</h6>
                <h6 style={{color:'#353542', fontWeight:'500'}}>Order Date: 
  <span style={{fontWeight:'400'}}>
    {orderDetail?.orderInfo && orderDetail?.orderInfo?.length > 0 ? formatDateToLongForm(orderDetail.orderInfo[0]?.time) : formatDateToLongForm(orderDetail?.createdAt)}
  </span>
</h6>
                {orderDetail?.address && Object.keys(orderDetail?.address)?.length > 0 && <h6 style={{color:'#353542', fontWeight:'500'}}>Delivery Address: <span style={{fontWeight:'400'}}>{orderDetail?.address?.streetAddress}, {orderDetail?.address?.city?.name}, {orderDetail?.address?.state?.name}</span></h6>}
                {!orderDetail?.voucher && <>
                {orderDetail?.discountAmount !== 0 && orderDetail?.promotionType !== 'free delivery' &&  <h6 style={{color:'#353542', fontWeight:'500'}}>Discount: <span style={{fontWeight:'400'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.discountAmount)}</span></h6>}
            
                <h6 style={{color:'#353542', fontWeight:'500'}}>Total Amount Paid: <span style={{fontWeight:'400'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.totalCost || orderDetail?.amount)}</span><br/>
                
                {orderDetail?.discountAmount !== 0 && orderDetail?.promotionType === 'free delivery' && orderDetail?.deliveryType === 'delivery' && <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px'}}>(₦{orderDetail?.discountAmount.toLocaleString()} discount deducted off shipping fee)</p>}</h6>
                </>}
                
                {orderDetail?.transactionReference || orderDetail?.transaction && <h6 style={{color:'#353542', fontWeight:'500'}}>Transaction ID: <span style={{fontWeight:'400'}}>{orderDetail?.transactionReference || orderDetail?.transaction}</span></h6>}

                {orderDetail?.orderComment && <h6 style={{color:'#353542', fontWeight:'500'}}>Comment: <span style={{fontWeight:'400'}}>{orderDetail?.orderComment}</span></h6>}
              {orderDetail?.deliveryType === 'pickup' ? <h6 style={{color:'#353542', fontWeight:'500'}}>Estimated Pickup Date: <span style={{fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</span></h6> : orderDetail?.deliveryType === 'delivery' ? <h6 style={{color:'#353542', fontWeight:'500'}}>Estimated Delivery Date: <span style={{fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</span></h6> : null}
                
              
                </Card>
                
                </Col>
            </Row>
           </div>
           <div>

            {orderDetail?.voucher && <div className='d-block d-md-flex gap-4 align-items-center justify-content-center text-center text-align-center '>

             
              {orderDetail?.voucherNote &&
             <Card.Img src={orderDetail?.voucherNote} className='mb-3' style={{height:'300px',width:'200px'}}/>}
          
           </div>
            }

{orderDetail?.cart && orderDetail?.cart?.length > 0 ? <>
  <Row>
              <Col xs={5} md={4}>
              <h6 style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Item Purchased</h6>
              </Col>
              <Col xs={2} md={1} className=''>
              <h6 style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Qty</h6>
              </Col>
              {orderDetail?.shoppingInstructions?.length > 0 &&
              <Col xs={5} md={3} className=''>
              <h6 style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Shopping Instructions</h6>
              </Col>}
              <Col xs={0} md={2} className='justify-content-center d-none d-sm-flex'>
              <h6  className='pb-3'><a className='text-decoration-underline' style={{color:'#D0823A', fontWeight:'600', cursor:'pointer'}} onClick={downloadPdf}>Download Invoice</a></h6>
              </Col>
              <Col xs={0} md={2} className='d-none d-sm-flex'>
              <h6 className='pb-3'><a className='text-decoration-underline ' style={{color:'#469B12', fontWeight:'600'}} onClick={reorderAllItems}>Reorder All</a></h6>
              </Col>
             
            </Row>
      
{orderDetail?.cart?.map((item)=> (
  <Row key={item?._id} className='d-flex align-items-center'> 
     <Col xs={6} md={4} >
     <div className='d-flex gap-3 align-items-center'>
      <div className=''> <img src={item?.productId?.productImage} style={{width:'70px', height:'70px',borderRadius:'12px',}}className=''/></div>
                 
                  <div className='mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'500', color:'#344054'}}>{item?.productId?.productName}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}</p>

          {item?.OldPrice && (<p className='' style={{color:'#dfdcdc',textDecoration:'line-through', fontFamily:'Outfit', fontWeight:'500'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.OldPrice)}</p>)}
             

         </div>
                  </div>
                </div>
              </Col>
              <Col xs={1} md={1} className=''>
              <h5 style={{color:'#344054', fontWeight:'600'}} className='my-auto ms-2'>{item?.quantity}</h5>
              </Col>
              {orderDetail?.shoppingInstructions && orderDetail?.shoppingInstructions?.length > 0 &&
              <Col xs={3} md={3} className=''>
              <h5 style={{color:'#344054', fontWeight:'600'}} className='my-auto ms-2'>  {getShoppingInstructions(item?.productId?.productName, orderDetail?.shoppingInstructions)}</h5>
              </Col>}
            
              <Col xs={2} md={3} className='align-items-center justify-content-center'>
              <p className='my-auto'><a 
  className='text-decoration-underline ' 
  style={{color:'#469B12', fontWeight:'500'}} 
  onClick={() => reorderSingleItem(item)}
>
  Reorder Item
</a></p>
              </Col>
          
    </Row>
))}
<div className='d-flex d-sm-none gap-3 justify-content-center my-4 text-center'>
<Button variant='outline-primary' onClick={downloadPdf}>Download Invoice</Button>

<Button onClick={reorderAllItems}>Reorder All</Button>
</div></> : orderDetail?.boxes ?  <>
 <Row>
 <Col xs={9} md={6}>
 <h6 style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Box Purchased</h6>
 </Col>
 <Col xs={2} md={2} className=''>
 <h6 style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Qty</h6>
 </Col>



</Row>

{orderDetail?.boxes &&
<Row className='d-flex align-items-center'> 
<Col xs={9} md={7} >
<div className='d-flex gap-3 align-items-center'>
<div className=''> <img src={orderDetail?.boxes?.box?.boxImage} style={{width:'70px', height:'70px',borderRadius:'12px',}}className=''/></div>
    
     <div className='mt-3'>
       <p className='mb-0 pb-1' style={{fontWeight:'500', color:'#344054'}}>{orderDetail?.boxes?.box?.name}</p>
     <div className='m-0 p-0 d-flex gap-2 align-items-center '>
<p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.boxes?.box?.amount)}</p>




</div>
     </div>
   </div>
 </Col>
 <Col xs={2} md={2} className=''>
 <h5 style={{color:'#344054', fontWeight:'600'}} className='my-auto ms-2'>{orderDetail?.boxes?.quantity}</h5>
 </Col>



</Row>
 }
  <Row className='px-2 my-3'>
         
         <Col xs={7} md={7}>
         <h6 style={{color:'#353542', fontWeight:'600'}} className='pb-3'>Items</h6>
         </Col>
         {orderDetail?.shoppingInstructions?.length > 0 &&
         <Col xs={5} md={5}>
           <h6 style={{color:'#353542', fontWeight:'600'}}>Shopping Instructions</h6>
         </Col>}
        
       </Row>
 <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.boxes?.box?.items?.map((item)=> (
      <Row key={item?._id} className='p-0'>
        
       
        <Col xs={7} md={7}>

        <h6 style={{color:'#344054', fontWeight:'500'}} className='my-auto'>{item?.productId?.productName}</h6>
        </Col>
        {orderDetail?.shoppingInstructions?.length > 0 &&
        <Col xs={5} md={5}>
        <h6 style={{color:'#344054', fontWeight:'500'}} className='my-auto'>{getShoppingInstructions(item?.productId?.productName, orderDetail?.shoppingInstructions)}</h6>
        </Col>}
       
      </Row>
    ))}
      
      </div> </>

: null}
           

     
           </div>
           </div>}
        </div>
</Col>
</Row>
</div>
</div>}
{showInvoice && <Invoice orderDetail={orderDetail} />}
    </div>
  )
}

export default OrderDetails
