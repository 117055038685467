
import React, {useState, useEffect} from 'react';
import { Button, Card, Form, Row, Col, Pagination } from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom'
import { useAppContext } from '../../AppContext';
import arrowLeft from '../../assets/arrow-left.svg'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import arrow from '../../assets/typcn_arrow-back-outline.svg'
import { trackEvent } from '../../mixpanel';
// import { trackAddToCart, transformPath } from '../../App';
import { useNavigate } from 'react-router-dom';
import orderRoute from '../../services/orderRoute';
import greenBag from '../../assets/green-bag.svg'
import redTrash from '../../assets/red-trash.svg'
import bag from '../../assets/bag.svg'

import SidebarNavbar from '../../components/Navbar/SideNavbar';
import productRoute from '../../services/productRoute'
import InfiniteScroll from 'react-infinite-scroll-component';
import { localStorageGetItem } from '../../App';
import SubscriptionModal from '../../components/Modal/SubscriptionModal';

const ProfileWishlist = () => {
    const navigate = useNavigate();
    const { showToast, setApiWishlistItems, apiWishlistItems, cartItems, setCartItems, updateCart,  pagination, setPagination, isMobile}= useAppContext();
    const [loading, setLoading] = useState(false);
    const [showAuthButtons, setShowAuthButtons] = useState(false);
  const [hasMore, setHasMore] = useState(true);
    const {getWishlist, updateWishlist, removefromWishlist, getMoreWishlistItems}= orderRoute();
  const [loadingMore, setLoadingMore] = useState(false);
    const userData = getParsedLocalStorageItem('userData');
    const userToken = localStorageGetItem
('userToken');;
    const isLoggedIn = !!userData && !!userToken;
    const [globalProducts, setGlobalProducts] = useState([]);
    const {getGlobalProducts,getAllBoxes, getUserBoxes}= productRoute();
  
    const location = useLocation();
    const [subscriptionBoxes, setSubscriptionBoxes] = useState([]);
  
    const [userBoxes, setUserBoxes] = useState([]);
   
    const [showSubscriptionModal, setShowSubscriptionModal] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
   
    
    useEffect(() => {
     
      getWishlist(setLoading, showToast, setApiWishlistItems, pagination, setPagination, isMobile);
    }, [isMobile, pagination.currentPage]); // 

    useEffect(()=>{
   
      getGlobalProducts(
        setLoading, showToast, setGlobalProducts, 
       
      )
    
  },[])
  
  useEffect(()=>{
   
    getAllBoxes(
      setLoading, showToast, setSubscriptionBoxes, 
     
    )
  
},[])

useEffect(() => {
  if (isLoggedIn) {
    getUserBoxes(
      setLoading, showToast, setUserBoxes, 
   
    )
  }
}, [isLoggedIn]); 

    const handleDeleteFromWishlist = (productId) => {
      removefromWishlist(productId, setLoading, showToast, apiWishlistItems, setApiWishlistItems, () => {
          // Callback function after item is successfully deleted
          // Check if we're not on the first page and if the current page will be empty after deletion
          if (pagination.currentPage > 1 && apiWishlistItems.length === 1) {
              // Adjust to show the previous page
              const newPage = pagination.currentPage - 1;
              setPagination(prevState => ({
                  ...prevState,
                  currentPage: newPage,
              }));
              // Fetch wishlist items for the new current page
              getWishlist(setLoading, showToast, setApiWishlistItems, {...pagination, currentPage: newPage}, setPagination, isMobile);
          }
      });
  };
  
    const handleAddtoCart = (id, increment = 1, replace = false) => {
      let productToAdd = null;
      let newCartItems = [...cartItems];
      const cartIndex = newCartItems.findIndex(item => item?.id === id);

      const product =  globalProducts.find(p => p?.id === id) 
      if (!product) {
          console.log('Error', `Product with ID ${id} not found in wishlist items`);
          return;
      }
  
      // Check if the product is out of stock
      if (product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0) {
          showToast({ toastText: 'This product is currently out of stock.', isSuccess: false });
          return;
      }
  
    
  
      if (cartIndex !== -1) {
        // Item already in cart, update quantity
        const updatedQuantity = replace ? increment : newCartItems[cartIndex]?.quantity + increment;
        newCartItems[cartIndex] = { ...newCartItems[cartIndex], quantity: updatedQuantity };
      } else {
        // Item not in cart, find and add new item
        productToAdd = apiWishlistItems.find(p => p?.product?.id === id) 
        if (productToAdd) {
          const itemToAdd = { ...productToAdd?.product, id: productToAdd?.product?.id, quantity: increment }; // Normalize id
          newCartItems.unshift(itemToAdd); // Adds at the beginning
        }
      }
  
      setCartItems(newCartItems);
      updateCart(newCartItems);
      showToast({ toastText: '1 item added to Cart', isSuccess: true });
  
      // Tracking event after adding to cart
      if (!productToAdd) {
        // Find again if not found earlier
        productToAdd =  apiWishlistItems.find(p => p?.product?.id === id) 
      }
      if (productToAdd) {
        // trackAddToCart(productToAdd.id, productToAdd.productName, productToAdd.price);
        trackEvent(`${productToAdd?.product.productName} Added to Cart`, {
          'Product ID': productToAdd?.product.id,
          'Product Name': productToAdd?.product.productName,
          'Quantity Added': increment,
          'User': userData?.email || 'Anonymous',
          'Location': 'Profile Wishlist Page'
        });
      }
    };
     
 const fetchMoreProducts = () => {
    if (pagination.currentPage < pagination.totalPages) {
      
        // Start loading more products
        getMoreWishlistItems(
           setLoadingMore,
            showToast,
            setApiWishlistItems,
            { ...pagination, currentPage: pagination.currentPage + 1 },
            setPagination,
          
        );
    } else {
        setHasMore(false); 
    }
};

const handleShowBoxModal = (subscription) => {


  setSelectedSubscription(subscription)
      setShowSubscriptionModal(true);
      trackEvent(`${subscription?.name} Viewed`, {
        'User': userData?.email || 'Anonymous',
      
      });
    
    };
  
    const handleCloseSubscriptionModal = () => {
      setShowSubscriptionModal(false);
    };
  
   
    const handleSubscribe = (subscription) => {
      navigate('/subscription-summary', { state: { subscription } });
    };

  const handlePageChange = (newPage) => {
  
    setPagination(prevState => ({ ...prevState, currentPage: newPage }));
  };

  const handleBack = () => {
   
    navigate(-1);
  }
  
  return (
    <div>
        
      {isMobile ? 
       <div className='py-5 my-5 my-sm-0'>
        <div className='mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex d-sm-none align-items-center gap-3 px-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>Wishlist</h5>
              
           </div>
           </div>
           <div className=' px-3 px-xxl-5 mx-lg-5 mb-5'>


           {apiWishlistItems?.length > 0 ? 
           <InfiniteScroll 
     dataLength={apiWishlistItems?.length}
     next={fetchMoreProducts}
     hasMore={hasMore}
     loader={loadingMore && <div className='justify-content-center align-items-center text-center' >
     <div className="spinner-border text-success" role="status">
       <span className="visually-hidden">Loading...</span>
     </div>
   </div>}>
           
          <Row>
            {apiWishlistItems.map((item) => (
               <Col xs={6} md={3} className='mb-3 mb-md-4'  key={item?.id} >
               <Card className='border-0 h-100' style={{boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px'}}>
                 <div className='position-relative mb-1 h-100'>
                 <Card.Img src={item?.product?.productImage || item?.box?.boxImage} style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', width: '100%', 
                  height: '100%',maxHeight:'200px', objectFit:'cover' }}/>

{item.box &&
                  <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-center mt-3 text-center' > 
<p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'7px', width:'auto', cursor:'pointer'}} onClick={()=>handleShowBoxModal(item?.box)} className='px-1 justify-content-center text-center align-items-center'><img src={arrow} alt='arrow'/> Look inside</p>
 </Card.ImgOverlay>}
                
                 {item?.product?.inSeason && <div className={item?.product?.inSeason ? 'bg-success position-absolute  text-center px-2' : 'bg-danger position-absolute text-center px-2'}style={{top:'5%', left:'5%', padding:'', borderRadius:'100px',boxShadow:'0px 1px 2px 0px #0000001A'}} >
                      <span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400', }}>
              {item?.product?.inSeason ? 'IN SEASON' : 'OFF SEASON'}
              </span>
              
                                                   
                    </div>
                
                 }
                                               
                 </div>
                
                 
                < Card.Body className='m-0 pt-0 pb-1 px-2'>
              <div className='d-flex justify-content-between align-items-center gap-2 mb-1' style={{flex:1}}>
                     <p style={{color:'#344054', fontWeight:'500'}}className='my-auto'>{item?.product?.productName || item?.box?.name}</p>
                    
                   </div>
                   <div className='d-flex justify-content-between align-items-center'>
                   <div className='d-flex gap-2 align-items-center '>
                          <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className='my-auto'> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.product?.price || item?.box?.amount)}</p>
              
                           {/* {item?.OldPrice && (<p className='my-auto' style={{color:'#dfdcdc',textDecoration:'line-through', fontFamily:'Outfit', fontWeight:'500', fontSize:'10px'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.OldPrice)}</p>)} */}
                           
              
                       </div>
                        
                     
                      
                       
                   </div>
                   <div className='py-2 d-flex justify-content-between align-items-center'>
                {item?.product &&
                   <div className='d-flex gap-1 align-items-center'  onClick={() => handleAddtoCart(item?.product?.id, 1, true)}  style={{
                    color: cartItems.some(cartItem => cartItem?.id === item?.product?.id) ? '#469B12' : '#000000', 
                }}>  
                  <img 
                    src={cartItems.some(cartItem => cartItem?.id === item?.product?.id) ? greenBag : bag} 
                    alt='cart action'
                    
                />
                  <p className='my-auto'>
                    {cartItems.some(cartItem => cartItem?.id === item?.product?.id) ? 'Added to Cart' : 'Add to Cart'}
                </p>
                   </div>}

                   {item?.box && (
  userBoxes?.find(p => p?.boxes?.box?.id === item?.box?.id && p?.status === 'active') ? (
    <Button className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '20px',fontSize:'12px' }}>
    SUBSCRIBED
  </Button>
  ) : (
   
    <Button className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '20px', fontSize:'12px' }} onClick={() => handleSubscribe(item?.box)}>
    SUBSCRIBE
  </Button>
  )
)}
                 
                <div className='d-flex gap-1 align-items-center'   onClick={() => handleDeleteFromWishlist(item?.product?.id || item?.box?.id)}>   <img src={redTrash} alt='trash'/><p  style={{color:'#CE371C'}}className='my-auto'> Delete</p></div>
               
              
               </div>
              </Card.Body>
                  
                  
                
               </Card>
               </Col>
            ))}

          </Row>
          </InfiniteScroll>
         : (
            <section className='justify-content-center text-center' style={{padding:'180px 0'}}>
            <h1>Your wishlist is empty</h1>
           
            
        </section>
        )}
         
</div>

      
    
          </div> : 
          <div className='py-5 my-5 my-sm-0 px-3 px-xxl-5 mx-lg-5 mb-5'>

          <div className="d-none d-sm-flex gap-2 align-items-center mb-3" >  
    <Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-left" style={{color:'#1D2939'}} ></i></p></Link>
   
        <p className='' style={{color:'#1D2939', fontWeight:'600'}} >Wishlist </p>
        </div>
          <Row>
       <Col xs={12} sm={3} className=''>
    <SidebarNavbar/>
</Col>
        <Col xs={12} sm={9} className='px-3'>
          <div>
          <div className='d-none d-sm-block mb-3'>
               <h5 style={{color:'#0C0C0C', fontWeight:'500'}}>Wishlist</h5>
               <h6 style={{color:'#717171', fontWeight:'400'}}>See all your favorite items here, ready to be added to cart!</h6>
           </div>

           
           {loading ? (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
<div className="spinner-border text-success" role="status">
<span className="visually-hidden">Loading...</span>
</div>
  </div>):
          apiWishlistItems?.length > 0 ? (
           <div>
           
          <Row>
            {apiWishlistItems?.map((item) => (
               <Col xs={6} md={3} className='mb-3 mb-md-4'  key={item?.id} >
               <Card className='border-0 h-100' style={{boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px'}}>
                 <div className='position-relative mb-1 h-100'>
                 <Card.Img src={item?.product?.productImage || item?.box?.boxImage} style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', width: '100%', 
                  height: '100%',maxHeight:'200px', objectFit:'cover' }}/>
                  {item.box &&
                  <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-center mt-1 text-center' > 
<p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'14px', width:'auto', cursor:'pointer'}} onClick={()=>handleShowBoxModal(item?.box)} className='px-1 justify-content-center text-center align-items-center'><img src={arrow} alt='arrow'/> Look inside</p>
 </Card.ImgOverlay>}
                 {item?.product?.inSeason && <div className={item?.product?.inSeason ? 'bg-success position-absolute  text-center px-2' : 'bg-danger position-absolute text-center px-2'}style={{top:'5%', left:'5%', padding:'', borderRadius:'100px',boxShadow:'0px 1px 2px 0px #0000001A'}} >
                      <span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400', }}>
              {item?.product?.inSeason ? 'IN SEASON' : 'OFF SEASON'}
              </span>
              
                                                   
                    </div>
                 }
                                               
                 </div>
                
                 
                < Card.Body className='m-0 pt-0 pb-1 px-2'>
              <div className='d-flex justify-content-between align-items-center gap-2 mb-1' style={{flex:1}}>
                     <p style={{color:'#344054', fontWeight:'500'}}className='my-auto'>{item?.product?.productName || item?.box?.name}</p>
                    
                   </div>
                   <div className='d-flex justify-content-between align-items-center'>
                   <div className='d-flex gap-2 align-items-center '>
                          <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className='my-auto'> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.product?.price || item?.box?.amount)}</p>
              
                           {/* {item?.OldPrice && (<p className='my-auto' style={{color:'#dfdcdc',textDecoration:'line-through', fontFamily:'Outfit', fontWeight:'500', fontSize:'10px'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.OldPrice)}</p>)} */}
                           
              
                       </div>
                        
                     
                      
                       
                   </div>
                   <div className='py-2 d-flex justify-content-between align-items-center'>
                   {item?.product &&
                   <div className='d-flex gap-1 align-items-center'  onClick={() => handleAddtoCart(item?.product?.id, 1, true)}  style={{
                    color: cartItems.some(cartItem => cartItem?.id === item?.product?.id) ? '#469B12' : '#000000', 
                }}>  
                  <img 
                    src={cartItems.some(cartItem => cartItem?.id === item?.product?.id) ? greenBag : bag} 
                    alt='cart action'
                    
                />
                  <p className='my-auto'>
                    {cartItems.some(cartItem => cartItem?.id === item?.product?.id) ? 'Added to Cart' : 'Add to Cart'}
                </p>
                   </div>}

                   {item?.box && (
  userBoxes?.find(p => p?.boxes?.box?.id === item?.box?.id && p?.status === 'active') ? (
    <Button className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '20px',fontSize:'14px'  }}>
    SUBSCRIBED
  </Button>
  ) : (
   
    <Button className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '20px',fontSize:'14px'  }} onClick={() => handleSubscribe(item?.box)}>
    SUBSCRIBE
  </Button>
  )
)}
                 
                <div className='d-flex gap-1 align-items-center'   onClick={() => handleDeleteFromWishlist(item?.product?.id || item?.box?.id)}>   <img src={redTrash} alt='trash'/><p  style={{color:'#CE371C'}}className='my-auto'> Delete</p></div>
               
              
               </div>
              </Card.Body>
                  
                  
                
               </Card>
               </Col>
            ))}

          </Row>




<div className='d-flex justify-content-between align-items-center my-4' style={{height:'50px'}}>
  <p style={{fontWeight:'500', color:'#919EAB'}}>{apiWishlistItems?.length} results</p>
  {pagination.totalPages > 1 && 
<Pagination className='custom-pagination d-flex gap-2'>
 
<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === 1}
    onClick={() => handlePageChange(pagination.currentPage - 1)}
  style={{
    border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-left"></i></h6>
</button>
 {/* First Page */}
 <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
    1
  </Pagination.Item>

  {/* Second Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(2)} active={pagination.currentPage === 2}>
      2
    </Pagination.Item>
  )}

  {/* Ellipsis after Second Page if necessary */}
  {pagination.currentPage > 3 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Current Page (if not first, second, last, or second to last page) */}
  {pagination.currentPage > 2 && pagination.currentPage < pagination.totalPages - 1 && (
    <Pagination.Item active>
      {pagination.currentPage}
    </Pagination.Item>
  )}

  {/* Ellipsis before Last Page if necessary */}
  {pagination.currentPage < pagination.totalPages - 2 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Second to Last Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(pagination.totalPages - 1)} active={pagination.currentPage === pagination.totalPages - 1}>
      {pagination.totalPages - 1}
    </Pagination.Item>
  )}

  {/* Last Page */}
  <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
    {pagination.totalPages}
  </Pagination.Item>


<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === pagination.totalPages}
  onClick={() => handlePageChange(pagination.currentPage + 1)}
  style={{
    border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-right"></i></h6>
</button>
 
  </Pagination>}


</div>
      
 




         
          </div>
        ) : (
            <section className='justify-content-center text-center' style={{padding:'180px 0'}}>
            <h1>Your wishlist is empty</h1>
           
            
        </section>
        )}
         


      
    
          </div>

           
            
           
        </Col>
      </Row>
     
      
        </div>}
        <SubscriptionModal showModal={showSubscriptionModal} handleCloseModal={handleCloseSubscriptionModal} selectedSubscription={selectedSubscription}/>
    </div>
  )
}

export default ProfileWishlist

