import React,{useState, useEffect, useRef} from 'react'
import { Col, Row, Button, Modal } from 'react-bootstrap'
import {useNavigate, useLocation} from 'react-router-dom'
import orderRoute from '../../services/orderRoute'
import { useAppContext } from '../../AppContext'
import successIcon from '../../assets/success.svg'
import userRoute from '../../services/userRoute'
import download from '../../assets/download.svg'
import print from '../../assets/print.svg'
import Invoice from './Invoice'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import errorIcon from '../../assets/icon-error.png'


const OrderConfirmation = () => {
  const {showToast, cartItems, setCartItems, updateCart}= useAppContext();

  const [showInvoice, setShowInvoice] = useState(false);
  const invoiceRef = useRef();
const [showPaymentModal, setShowPaymentModal] = useState(false);
  const navigate =useNavigate();
  const [loading, setLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});



  const {getOrders, getOrderByRef}= orderRoute();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const transactionRef = query.get('trxref');



  
useEffect(() => {
  if (transactionRef) {
    getOrderByRef(transactionRef, setLoading, showToast, setOrderDetail, setShowPaymentModal, setCartItems);
  } else {
    showToast({toastText:'Transaction reference not found', isSuccess:false});
  }
}, [transactionRef]);
  const totalShoppingInstructionsFee = orderDetail?.shoppingInstructions?.reduce((sum, instruction) => sum + instruction?.instruction?.amount, 0) || 0;

  const getShoppingInstructions = (productName, shoppingInstructions) => {
    return shoppingInstructions
      .filter(instruction =>
        instruction.products.some(product => product.productName === productName)
      )
      .map(instruction => instruction.instruction.name)
      .join(', ');
  };
  
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const formatDateToLongForm = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatDateISO = (isoDate) => {
    if (!isoDate) return '';
  
    // Parse the ISO date string
    const date = new Date(isoDate);
  
    // Subtract one day
    date.setDate(date.getDate() - 1);
  
    // Format the date to dd/mm/yy
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
  
    return `${day}/${month}/${year}`;
  };
  

  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear(); // Use full year
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    // Format the date part
    let formattedDate = `${month}-${day}-${year}`;
    
    // Format the time part
    let formattedTime;
    if (hours > 12) {
      formattedTime = `${(hours - 12).toString().padStart(2, '0')}:${minutes} PM`;
    } else if (hours == 12) {
      formattedTime = `12:${minutes} PM`;
    } else if (hours == 0) {
      formattedTime = `12:${minutes} AM`;
    } else {
      formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} AM`;
    }
  
    // Combine the date and time with a comma and a space as separator
    return `${formattedDate}, ${formattedTime}`;
  };
  

function formatTime(dateTimeString) {
  const dateObject = new Date(dateTimeString);

  // Extracting components
  let hours = dateObject.getHours();
  let minutes = dateObject.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Converting 24h to 12h format and padding
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;

  // Formatting
  return `${hours}:${minutes}${ampm}`;
}




const downloadPdf = async () => {
  // Show the invoice
  setShowInvoice(true);

  // Ensure the DOM has updated
  await new Promise(resolve => setTimeout(resolve, 100)); // Ensure the invoice is rendered

  const input = document.getElementById('invoice');
  if (!input) {
    console.error('Invoice element not found.');
    setShowInvoice(false); // Hide invoice if not found
    return;
  }

  const canvas = await html2canvas(input, {
    scale: 1.5,
    useCORS: true,
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
    windowWidth: document.documentElement.offsetWidth,
    windowHeight: document.documentElement.offsetHeight
  });

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF({
    orientation: 'p',
    unit: 'px',
    format: [canvas.width, canvas.height]
  });

  pdf.addImage(imgData, 'PNG', 0, 0);
  const blobPDF = pdf.output('blob');
  const blobUrl = URL.createObjectURL(blobPDF);

  // Check for iOS devices
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (isIOS) {
    // Open the PDF in a new tab for iOS users
    const newWindow = window.open(blobUrl, '_blank');
    if (newWindow) {
      newWindow.onload = () => {
        setTimeout(() => setShowInvoice(false), 500); // Delay hiding the invoice
      };
    } else {
      // Fallback in case the window could not be opened
      alert('Unable to open the PDF in a new tab. Please allow pop-ups for this site.');
      setShowInvoice(false);
    }
  } else {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = 'virtueshop-invoice.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setShowInvoice(false);
  }
};

const handleRetry=()=>{
  navigate('/order-summary');
  setShowPaymentModal(false);
}


const calculateSubtotal = (orderDetail) => {
  if (orderDetail?.promotionType === 'free delivery') {
    if (orderDetail?.fee < orderDetail?.discountAmount) {
      // Only the amount of orderDetail.fee was deducted
      return (orderDetail?.totalCost - orderDetail?.fee - totalShoppingInstructionsFee) + orderDetail?.fee;
    } else {
      // The exact discount amount was deducted
      return (orderDetail?.totalCost - orderDetail?.fee - totalShoppingInstructionsFee) + orderDetail?.discountAmount;
    }
  } else {
    // The exact discount amount was deducted
    return (orderDetail?.totalCost - orderDetail?.fee - totalShoppingInstructionsFee) + orderDetail?.discountAmount;
  }
};




if (loading) {
  return <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
    <div className="spinner-border text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>;
}



if (orderDetail?.paymentStatus === 'failed') {
  return  <Modal show={showPaymentModal} onHide={handleRetry} centered>
  <Modal.Header closeButton ></Modal.Header>
    <Modal.Body className='justify-content-center text-center p-5'>
  <div className=' d-flex flex-column gap-4'>
<div>
      <img src={errorIcon} alt='error icon' style={{width:'50px', height:'50px'}}/>
      </div>
      
      <div className=''>
      <h2 className='mb-3'>Your payment could not be processed. </h2>
      <p style={{color:'#5D5D5D', fontWeight:'500'}}>Please try again or contact support for further assistance.</p>
      </div>
      <div className=''>
      <Button className='btn-primary w-100' onClick={handleRetry}>Retry Payment</Button>
      
      </div></div>
     
     

    </Modal.Body>
    
      </Modal>
}


  return (
    <div>
   <>
        { !showInvoice && 
(
   <>
       <div className='d-flex d-sm-none py-5 my-5 my-sm-0'>
      
      <div className='p-3 bg-white'>
       <div className='d-flex flex-column gap-4 text-center justify-content-center'>
         <div>
         <img src={successIcon} alt='success' style={{width:'100px'}}/>
         </div>
        <div> <h4 style={{fontWeight:'700', color:'#353542', }}>Your order has been placed</h4>
         <h5 style={{fontWeight:'400', color:'#353542'}} className='mt-1 mb-1'>Thank you for shopping with us! We will provide order updates via email address and phone number.</h5>
         
      
         {orderDetail?.orderMod && Object.keys(orderDetail?.orderMod).length > 0 ? <h5 style={{fontWeight:'400', color:'#353542', }}>Please note that you can still modify your orders until 11p.m.</h5>:  orderDetail?.cart && orderDetail?.cart?.length > 0 ? <h5 style={{fontWeight:'400', color:'#353542', }}>Please note that you can still modify your orders until 11p.m.</h5> : null}  </div>
        
       </div>
       {orderDetail?.orderMod ? 
       <div className=''>
       <div style={{borderTop:'1px solid #000000',borderBottom:'1px solid #000000' }} className='mt-4 mb-5 p-3 '>
<Row>
 <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
   <h5 style={{fontWeight:'500', color:'#000000'}} className='mb-0 pb-1'>Personal Information</h5>
   <div>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='d-flex flex-wrap'>{orderDetail?.order?.user?.firstName}&nbsp;{orderDetail?.order?.user?.lastName}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='d-flex flex-wrap'>{orderDetail?.order?.user?.email}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='mt-2'>{orderDetail?.order?.user?.phoneNumber}</h5>
   </div>
   
 
 </Col>
 {orderDetail?.order?.deliveryType === 'delivery' && orderDetail?.order?.address && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Address</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.address?.streetAddress}, {orderDetail?.order?.address?.city?.name}, {orderDetail?.order?.address?.state?.name}</h5>
   
  
   <h5 className='mt-1' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Delivery date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.order?.deliveryDate)}</h5>
   
  </div>
 
 </Col>
}
 
{orderDetail?.order?.deliveryType === 'pickup' && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Pickup Details</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Pickup date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.order?.deliveryDate)}</h5>
   
  </div>
<div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Store Details</h5>
  
  <h5 className='' style={{color:'#353542', fontWeight:'400'}}>No. 2, Ileri-Oluwa Crescent, Ifako-Ijaiye, via
            College Road, Oyemekun Bus-Stop</h5>
            <h5 className='' style={{color:'#353542', fontWeight:'400'}}> <a href="tel:+2349167682871" className="text-decoration-none  style={{color:'#353542', fontWeight:'400'}}">+234 9167682871</a></h5>
  
 </div>

 
 </Col>
}

 <Col xs={12} md={3} className='d-flex flex-column gap-1 mt-2 mb3'>
  <div className='d-flex justify-content-between'>
   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.orderMod?.id}</h5>
  </div>
  
  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order placed on</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}> {formatDateToLongForm(orderDetail?.orderMod?.createdAt)}</h5>
  </div>
  </div>
 
 

 </Col>

 <Col xs={12} md={3} className='d-flex flex-column gap-2 mt-2'>
 {orderDetail?.orderMod?.transactionReference &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Transaction ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.orderMod?.transactionReference}</h5>
  </div>}
  

 </Col>
</Row>
       </div>
       <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2'>
         <Col xs={6}>
           <h5 style={{color:'#353542', fontWeight:'600'}}>Modified Items</h5>
         </Col>
         <Col xs={2}>
           <h5 style={{color:'#353542', fontWeight:'500'}}>QTY</h5>
         </Col>
         <Col xs={3}>
           <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
         </Col>
       </Row>
       <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-4 pb-2'>
       {orderDetail?.orderMod?.cart?.map((item)=> (
       <Row  key={item?._id} className='p-0'>
         <Col xs={6}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.productId?.productName}</h5>
         </Col>
         <Col xs={2}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.quantity}</h5>
         </Col>
      
         <Col xs={3}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
 {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}
</h5>

         </Col>
       </Row>
       ))}
       </div>
       <div className='d-flex justify-content-end me-lg-5'>
      
       <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
        <div className='d-flex gap-3'> 
  <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Sub-Total:</h4>
  <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>
    {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.orderMod?.totalCost)}
  </h4>
</div>


          <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'600', width:'135px'}}>Total:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.orderMod?.totalCost)}</h4>
          </div>
        </div>
       </div>
       <div className='d-flex justify-content-end me-lg-5'>
       
       <div className='d-flex gap-3'>
         <Button variant='outline-secondary'  onClick={handlePrint} ><img src={print} alt='print'/>&nbsp;Print</Button>
         <Button className='btn-primary'onClick={downloadPdf}><img src={download} alt='download'/> &nbsp;Download</Button>
       </div>
       </div>
       <div className='text-center my-5'>
         <Button onClick={()=> navigate('/')} className='w-50'>Continue Shopping</Button>
       </div>
       <div className='py-5'>
       <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2'>
         <Col xs={4}>
           <h5 style={{color:'#353542', fontWeight:'600'}}>All Items</h5>
         </Col>
         <Col xs={2}>
           <h5 style={{color:'#353542', fontWeight:'500'}}>QTY</h5>
         </Col>
         {orderDetail?.order?.shoppingInstructions?.length > 0 &&
         <Col xs={3}>
           <h5 style={{color:'#353542', fontWeight:'600'}}>Shopping Instructions</h5>
         </Col>}
         <Col xs={3}>
           <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
         </Col>
       </Row>
       <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-4 pb-2'>
       {orderDetail?.order?.cart?.map((item)=> (
       <Row  key={item?._id} className='p-0'>
         <Col xs={4}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.productId?.productName}</h5>
         </Col>
         <Col xs={2}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.quantity}</h5>
         </Col>
         {orderDetail?.order?.shoppingInstructions && orderDetail?.order?.shoppingInstructions?.length > 0 &&
         <Col xs={3}>
            <h5 style={{color:'#353542', fontWeight:'500'}}> {getShoppingInstructions(item?.productId?.productName, orderDetail?.order?.shoppingInstructions)}</h5>
          </Col>}
         <Col xs={3}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
 {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}
</h5>

         </Col>
       </Row>
       ))}
 
       </div>
       {orderDetail?.order?.orderComment &&   <div className='d-flex gap-2 py-3'> <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Comment:</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.orderComment}</h5>
      </div>}
      </div>
      </div>
      : orderDetail?.cart && orderDetail?.cart?.length > 0  ?  
       <div className=''>
       <div style={{borderTop:'1px solid #000000',borderBottom:'1px solid #000000' }} className='mt-4 mb-5 p-3 '>
<Row>
 <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
   <h5 style={{fontWeight:'500', color:'#000000'}} className='mb-0 pb-1'>Personal Information</h5>
   <div>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='d-flex flex-wrap'>{orderDetail?.user?.firstName}&nbsp;{orderDetail?.user?.lastName}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='d-flex flex-wrap'>{orderDetail?.user?.email}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='mt-2'>{orderDetail?.user?.phoneNumber}</h5>
   </div>
   
 
 </Col>
 {orderDetail?.deliveryType === 'delivery' && orderDetail?.address && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Address</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.address?.streetAddress}, {orderDetail?.address?.city?.name}, {orderDetail?.address?.state?.name}</h5>
   
  
   <h5 className='mt-1' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Delivery date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</h5>
   
  </div>
 
 </Col>
}
 
{orderDetail?.deliveryType === 'pickup' && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Pickup Details</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Pickup date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</h5>
   
  </div>
<div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Store Details</h5>
  
  <h5 className='' style={{color:'#353542', fontWeight:'400'}}>No. 2, Ileri-Oluwa Crescent, Ifako-Ijaiye, via
            College Road, Oyemekun Bus-Stop</h5>
            <h5 className='' style={{color:'#353542', fontWeight:'400'}}> <a href="tel:+2349167682871" className="text-decoration-none  style={{color:'#353542', fontWeight:'400'}}">+234 9167682871</a></h5>
  
 </div>

 
 </Col>
}

 <Col xs={12} md={3} className='d-flex flex-column gap-1 mt-2 mb3'>
  <div className='d-flex justify-content-between'>
   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.sku ? orderDetail?.sku : orderDetail?.id}</h5>
  </div>
  
  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order placed on</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}> {orderDetail?.orderInfo && orderDetail?.orderInfo?.length > 0 && formatDateToLongForm(orderDetail.orderInfo[0].time)}</h5>
  </div>
  </div>
  {orderDetail?.orderComment &&   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Comment</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.orderComment}</h5>
      </div>}
 

 </Col>

 <Col xs={12} md={3} className='d-flex flex-column gap-2 mt-2'>
 {orderDetail?.transactionReference &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Transaction ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.transactionReference}</h5>
  </div>}
  
 
 </Col>
</Row>
       </div>
       <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2'>
         <Col xs={4}>
           <h5 style={{color:'#353542', fontWeight:'600'}}>Items</h5>
         </Col>
         <Col xs={2}>
           <h5 style={{color:'#353542', fontWeight:'500'}}>QTY</h5>
         </Col>
         {orderDetail?.shoppingInstructions && orderDetail?.shoppingInstructions?.length > 0 &&
         <Col xs={3}>
           <h5 style={{color:'#353542', fontWeight:'600'}}>Shopping Instructions</h5>
         </Col>}
         <Col xs={3}>
           <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
         </Col>
       </Row>
       <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-4 pb-2'>
       {orderDetail?.cart?.map((item)=> (
       <Row  key={item?._id} className='p-0'>
         <Col xs={4}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.productId?.productName}</h5>
         </Col>
         <Col xs={2}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.quantity}</h5>
         </Col>
         {orderDetail?.shoppingInstructions && orderDetail?.shoppingInstructions?.length > 0 &&
         <Col xs={3}>
            <h5 style={{color:'#353542', fontWeight:'500'}}> {getShoppingInstructions(item?.productId?.productName, orderDetail?.shoppingInstructions)}</h5>
          </Col>}
         <Col xs={3}>
         <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
 {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}
</h5>

         </Col>
       </Row>
       ))}
       </div>
       <div className='d-flex justify-content-end me-lg-5'>
      
       <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
        <div className='d-flex gap-3'> 
  <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Sub-Total:</h4>
  <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>
    {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(calculateSubtotal(orderDetail))}
  </h4>
</div>


          <div className='d-flex gap-3'> 
          {orderDetail?.deliveryType === 'delivery' ?
   <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Shipping fee:</h4> :    <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Processing fee:</h4>
}
{orderDetail?.fee === 0 ?  <div> <h4 style={{color:'#469B12', fontWeight:'500'}}> Free delivery</h4>  {orderDetail?.discountAmount !== 0 && orderDetail?.promotionType === 'free delivery' && orderDetail?.deliveryType === 'delivery' && <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px', width:'150px'}}>(₦{orderDetail?.discountAmount.toLocaleString()} discount deducted off shipping fee )</p>}
    </div>
    

    : 
       <div>
              <h4 style={{color:'#4F4F4F', fontWeight:'400'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.fee)}</h4>
              {orderDetail?.discountAmount !== 0 && orderDetail?.promotionType === 'free delivery' &&  orderDetail?.deliveryType === 'delivery' &&  <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px',width:'150px'}}>(₦{orderDetail?.discountAmount?.toLocaleString()} discount deducted off shipping fee )</p>}
              </div>
              }
          </div>



          {orderDetail?.shoppingInstructions && orderDetail?.shoppingInstructions?.length > 0 &&  <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'400', width:'135px'}}>Extra sevices:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalShoppingInstructionsFee)}</h4>
          </div>}
          {orderDetail?.discountAmount !== 0 && orderDetail?.promotionType !== 'free delivery' &&  <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'400', width:'130px'}}>Discount:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'400'}}> -{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.discountAmount)}</h4>
          </div>}


          <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'600', width:'135px'}}>Total:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.totalCost)}</h4>
          </div>
        </div>
       </div>
       <div className='d-flex justify-content-end me-lg-5'>
       
       <div className='d-flex gap-3'>
         <Button variant='outline-secondary'  onClick={handlePrint} ><img src={print} alt='print'/>&nbsp;Print</Button>
         <Button className='btn-primary'onClick={downloadPdf}><img src={download} alt='download'/> &nbsp;Download</Button>
       </div>
       </div>
       <div className='text-center my-5'>
         <Button onClick={()=> navigate('/')} className='w-50'>Continue Shopping</Button>
       </div>
      </div> 
       : orderDetail?.boxes ? 
       <>
        <div className=''>
       <div style={{borderTop:'1px solid #000000',borderBottom:'1px solid #000000' }} className='mt-4 mb-5 p-3 '>
<Row>
 <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
   <h5 style={{fontWeight:'500', color:'#000000'}} className='mb-0 pb-1'>Personal Information</h5>
   <div>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='d-flex flex-wrap'>{orderDetail?.user?.firstName}&nbsp;{orderDetail?.user?.lastName}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='d-flex flex-wrap'>{orderDetail?.user?.email}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='mt-2'>{orderDetail?.user?.phoneNumber}</h5>
   </div>
   
 
 </Col>
 {orderDetail?.address && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Address</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.address?.streetAddress}, {orderDetail?.address?.city?.name}, {orderDetail?.address?.state?.name}</h5>
   
  
   <h5 className='mt-1' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Delivery date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</h5>
   
  </div>
{orderDetail?.frequency &&
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Frequency</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.frequency}</h5>
   
  </div>}
 
 </Col>
}


 <Col xs={12} md={3} className='d-flex flex-column gap-1 mt-2 mb3'>
  <div className='d-flex justify-content-between'>
   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.id}</h5>
  </div>
  
  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order placed on</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}> {formatDateToLongForm(orderDetail?.createdAt)}</h5>
  </div>
  </div>
 
  {orderDetail?.orderComment &&   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Comment</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.orderComment}</h5>
      </div>}

 </Col>

 <Col xs={12} md={3} className='d-flex flex-column gap-2 mt-2'>
 {orderDetail?.transactionReference &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Transaction ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.transactionReference}</h5>
  </div>}

 </Col>
</Row>
       </div>
       <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2'>
          <Col xs={5}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Box Name</h5>
          </Col>
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>QTY</h5>
          </Col>
        
          <Col xs={3}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
          </Col>
        </Row>
          
  {orderDetail?.boxes && (
    <Row className='py-2' style={{borderBottom:'1px solid #E0E0E0'}}>
      <Col xs={5}>
        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{orderDetail?.boxes?.box?.name}</h5>
      </Col>
      <Col xs={4}>
        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{orderDetail?.boxes?.quantity}</h5>
      </Col>
      <Col xs={3}>
        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.boxes?.box?.amount)}
        </h5>
      </Col>
    </Row>
  )}


        </div>
        <Row className='px-2 my-3'>
       
          <Col xs={6}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Items</h5>
          </Col>
          {orderDetail?.shoppingInstructions?.length > 0 &&
          <Col xs={5}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Shopping Instructions</h5>
          </Col>}
       
        </Row>
        <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.boxes?.box?.items?.map((item)=> (
      <Row key={item?._id} className='p-0'>
        <Col xs={5}>

<h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto'>{item?.productId?.productName}</h5>
</Col>

         {orderDetail?.shoppingInstructions && orderDetail?.shoppingInstructions?.length > 0 &&
        <Col xs={4}>
        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto'>{getShoppingInstructions(item?.productId?.productName, orderDetail?.shoppingInstructions)}</h5>
        </Col>
}
        
      
      </Row>
    ))}
      
      </div>
       <div className='d-flex justify-content-end me-lg-5'>
      
       <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
        <div className='d-flex gap-3'> 
  <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Sub-Total:</h4>

  {orderDetail?.amount ?
  <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>
  {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail.discountAmount && orderDetail.discountAmount !== 0 ? (orderDetail?.amount - orderDetail?.fee - totalShoppingInstructionsFee) + orderDetail?.discountAmount : orderDetail?.amount - orderDetail?.fee - totalShoppingInstructionsFee)}
  </h4> : orderDetail?.totalCost ?  <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>
  {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail.discountAmount && orderDetail.discountAmount !== 0 ? (orderDetail?.totalCost - orderDetail?.fee - totalShoppingInstructionsFee) + orderDetail?.discountAmount : orderDetail?.totalCost - orderDetail?.fee - totalShoppingInstructionsFee)}
  </h4> : null}
</div>


          <div className='d-flex gap-3'> 
          
   <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Shipping fee:</h4>

       
{orderDetail?.fee === 0 ?   <h4 style={{color:'#469B12', fontWeight:'500'}}>Free delivery</h4>
    :
       
              <h4 style={{color:'#4F4F4F', fontWeight:'400'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.fee)}</h4>}
          </div>
          {orderDetail?.shoppingInstructions && orderDetail?.shoppingInstructions?.length > 0 &&  <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'400', width:'135px'}}>Extra sevices:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalShoppingInstructionsFee)}</h4>
          </div>}
         
          <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'600', width:'135px'}}>Total:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.totalCost ||orderDetail?.amount)}</h4>
          </div>
        </div>
       </div>
       <div className='d-flex justify-content-end me-lg-5'>
       
       <div className='d-flex gap-3'>
         <Button variant='outline-secondary'  onClick={handlePrint} ><img src={print} alt='print'/>&nbsp;Print</Button>
         <Button className='btn-primary'onClick={downloadPdf}><img src={download} alt='download'/> &nbsp;Download</Button>
       </div>
       </div>
       <div className='text-center my-5'>
         <Button onClick={()=> navigate('/')} className='w-50'>Continue Shopping</Button>
       </div>
       </> : orderDetail?.purchasedVoucher ?
       <div className=''>
        <div style={{borderTop:'1px solid #000000',borderBottom:'1px solid #000000' }} className='mt-4 mb-5 p-3 '>
<Row>
  <Col xs={12} md={3} className='d-flex flex-column gap-2 mb-2'>
  <h5 style={{fontWeight:'500', color:'#000000'}} className='mb-0 pb-1'>Personal Information</h5>
   <div>
   <h5 className='d-flex flex-wrap' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.user?.firstName}&nbsp;{orderDetail?.purchasedVoucher?.user?.lastName}</h5>
   <h5 className='d-flex flex-wrap' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.user?.email}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='mt-2'>{orderDetail?.purchasedVoucher?.user?.phoneNumber}</h5>
   </div>
   
    
  
  </Col>

 


  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <div className='d-flex justify-content-between'>
    <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order ID</h5>
    <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.id}</h5>
   </div>
   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order placed on</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}> {formatDateToLongForm(orderDetail?.purchasedVoucher?.createdAt)}</h5>
  </div>
  </div>
 
  </Col>
  <Col xs={12} md={3} className='d-flex flex-column gap-2 mt-2'>
  {orderDetail?.purchasedVoucher?.transaction &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Transaction ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.transaction?.transaction_reference}</h5>
  </div>}

  {orderDetail?.purchasedVoucher?.voucherCode &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Voucher Code</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.voucherCode}</h5>
  </div>}
   
 
  </Col>
</Row>
        </div>
        <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2 pb-2'>
          <Col xs={6}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Voucher</h5>
          </Col>
          <Col xs={2}>
            <h5 style={{color:'#353542', fontWeight:'500'}}>QTY</h5>
          </Col>
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
          </Col>
        </Row>
        <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
     
     {orderDetail?.purchasedVoucher?.voucher && (
       <Row className='p-0'>
         <Col xs={6}>
           <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{orderDetail?.purchasedVoucher?.voucher?.name}</h5>
         </Col>
         <Col xs={2}>
           <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>1</h5>
         </Col>
         <Col xs={4}>
           <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
             {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.purchasedVoucher?.amount)}
           </h5>
         </Col>
       </Row>
     )}
   
   
           </div>
       
        <div className='d-flex justify-content-end me-lg-5'>
        <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
       
          <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'600', width:'135px'}}>Total:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.purchasedVoucher?.amount)}</h4>
          </div>
        </div>
        
        </div>
        <div className='d-flex justify-content-end me-lg-5'>
        
        <div className='d-flex gap-3'>
        <Button variant='outline-secondary'  onClick={handlePrint}><img src={print} alt='print'/>&nbsp;Print</Button>
         <Button className='btn-primary' onClick={downloadPdf}><img src={download} alt='download'/> &nbsp;Download</Button>
        </div>
        </div>
        <div className='text-center my-5'>
          <Button onClick={()=> navigate('/')} className='w-50'>Continue Shopping</Button>
        </div>
      
       </div>
       :  (
        <div>No items found</div>
      )
    } 
      </div>
      </div>
     <div style={{backgroundColor:'#F4F4F5'}} className='d-none d-sm-block px-2 px-md-5 py-5 my-5 my-sm-0'>
      
       <div className='p-md-5 mx-xxl-5 bg-white'>
        <div className='d-flex flex-column gap-4 text-center justify-content-center'>
          <div>
          <img src={successIcon} alt='success' style={{width:'100px'}}/>
          </div>
         <div> <h4 style={{fontWeight:'700', color:'#353542', }}>Your order has been placed</h4>
          <h5 style={{fontWeight:'400', color:'#353542'}} className='mt-1 mb-1'>Thank you for shopping with us! We will provide order updates via email address and phone number.</h5>
          
          {orderDetail?.orderMod && Object.keys(orderDetail?.orderMod).length > 0 ? <h5 style={{fontWeight:'400', color:'#353542', }}>Please note that you can still modify your orders until 11p.m.</h5>:  orderDetail?.cart && orderDetail?.cart?.length > 0 ? <h5 style={{fontWeight:'400', color:'#353542', }}>Please note that you can still modify your orders until 11p.m.</h5> : null} </div>
         
        </div>
        {orderDetail?.orderMod  ?  
        <div className='mx-lg-5'>
        <div style={{borderTop:'1px solid #000000',borderBottom:'1px solid #000000' }} className='mt-4 mb-5 p-3 '>
<Row>
  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Personal Information</h5>
   <div>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.user?.firstName}&nbsp;{orderDetail?.order?.user?.lastName}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.user?.email}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='mt-2'>{orderDetail?.order?.user?.phoneNumber}</h5>
   </div>
   
    
  
  </Col>
  {orderDetail?.order?.deliveryType === 'delivery' && orderDetail?.order?.address && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Address</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.address?.streetAddress}, {orderDetail?.order?.address?.city?.name}, {orderDetail?.order?.address?.state?.name}</h5>
   
  
   <h5 className='mt-1' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Delivery date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.order?.deliveryDate)}</h5>
   
  </div>
 
 </Col>
}
 
{orderDetail?.order?.deliveryType === 'pickup' && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Pickup Details</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Pickup date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.order?.deliveryDate)}</h5>
   
  </div>
<div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Store Details</h5>
  
  <h5 className='' style={{color:'#353542', fontWeight:'400'}}>No. 2, Ileri-Oluwa Crescent, Ifako-Ijaiye, via
            College Road, Oyemekun Bus-Stop</h5>
            <h5 className='' style={{color:'#353542', fontWeight:'400'}}> <a href="tel:+2349167682871" className="text-decoration-none  style={{color:'#353542', fontWeight:'400'}}">+234 9167682871</a></h5>
  
 </div>

 
 </Col>
}

  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
    <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order ID</h5>
    <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.orderMod?.sku ? orderDetail?.orderMod?.sku : orderDetail?.orderMod?.id}</h5>
   </div>
   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order placed on</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}> {formatDateToLongForm(orderDetail?.orderMod?.createdAt)}</h5>
  </div>
 
  </Col>
  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  {orderDetail?.orderMod?.transactionReference &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Transaction ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.orderMod?.transactionReference}</h5>
  </div>}
   
 
  </Col>
</Row>
        </div>
        <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2 pb-2'>
          <Col xs={6}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Modified Items</h5>
          </Col>
          <Col xs={2}>
            <h5 style={{color:'#353542', fontWeight:'500'}}>QTY</h5>
          </Col>
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
          </Col>
        </Row>
        <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.orderMod?.cart?.map((item)=> (
        <Row  key={item?._id} className='p-0'>
          <Col xs={6}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.productId?.productName}</h5>
          </Col>
          <Col xs={2}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.quantity}</h5>
          </Col>
          <Col xs={4}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
  {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}
</h5>

          </Col>
        </Row>
        ))}
        </div>
        <div className='d-flex justify-content-end me-lg-5'>
        <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
        <div className='d-flex gap-3'> 
  <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Sub-Total:</h4>
  <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>
    {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.orderMod?.totalCost)}
  </h4>
</div>
          <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'600', width:'135px'}}>Total:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.orderMod?.totalCost)}</h4>
          </div>
        </div>
        
        </div>
        <div className='d-flex justify-content-end me-lg-5'>
        
        <div className='d-flex gap-3'>
        <Button variant='outline-secondary'  onClick={handlePrint}><img src={print} alt='print'/>&nbsp;Print</Button>
         <Button className='btn-primary' onClick={downloadPdf}><img src={download} alt='download'/> &nbsp;Download</Button>
        </div>
        </div>
        <div className='text-center my-5'>
          <Button onClick={()=> navigate('/')} className='w-50'>Continue Shopping</Button>
        </div>
        <div className='py-5'>
        <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2'>
          <Col xs={5}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>All Items</h5>
          </Col>
          <Col xs={1}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>QTY</h5>
          </Col>
          {orderDetail?.order?.shoppingInstructions?.length > 0 &&
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Shopping Instructions</h5>
          </Col>}

          <Col xs={2}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
          </Col>
        </Row>
        <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.order?.cart?.map((item)=> (
        <Row  key={item?._id} className='p-0'>
          <Col xs={5}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.productId?.productName}</h5>
          </Col>
          <Col xs={1}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.quantity}</h5>
          </Col>
          {orderDetail?.order?.shoppingInstructions && orderDetail?.order?.shoppingInstructions?.length > 0 &&
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'500'}}> {getShoppingInstructions(item?.productId?.productName, orderDetail?.order?.shoppingInstructions)}</h5>
          </Col>
}
          <Col xs={2}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
  {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}
</h5>

          </Col>
        </Row>
        ))}
         
        </div>
        {orderDetail?.order?.orderComment &&   <div className='d-flex gap-2 py-3'> <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Comment:</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.order?.orderComment}</h5>
      </div>}
        </div>
       </div> 
      :  orderDetail?.cart && orderDetail?.cart?.length > 0  ?  
        <div className='mx-lg-5'>
        <div style={{borderTop:'1px solid #000000',borderBottom:'1px solid #000000' }} className='mt-4 mb-5 p-3 '>
<Row>
  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Personal Information</h5>
   <div>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.user?.firstName}&nbsp;{orderDetail?.user?.lastName}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.user?.email}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='mt-2'>{orderDetail?.user?.phoneNumber}</h5>
   </div>
   
    
  
  </Col>
  {orderDetail?.deliveryType === 'delivery' && orderDetail?.address && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Address</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.address?.streetAddress}, {orderDetail?.address?.city?.name}, {orderDetail?.address?.state?.name}</h5>
   
  
   <h5 className='mt-1' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Delivery date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</h5>
   
  </div>
 
 </Col>
}
 
{orderDetail?.deliveryType === 'pickup' && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Pickup Details</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Pickup date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</h5>
   
  </div>
<div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Store Details</h5>
  
  <h5 className='' style={{color:'#353542', fontWeight:'400'}}>No. 2, Ileri-Oluwa Crescent, Ifako-Ijaiye, via
            College Road, Oyemekun Bus-Stop</h5>
            <h5 className='' style={{color:'#353542', fontWeight:'400'}}> <a href="tel:+2349167682871" className="text-decoration-none  style={{color:'#353542', fontWeight:'400'}}">+234 9167682871</a></h5>
  
 </div>

 
 </Col>
}

  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
    <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order ID</h5>
    <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.sku ? orderDetail?.sku : orderDetail?.id}</h5>
   </div>
   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order placed on</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}> {orderDetail?.orderInfo && orderDetail?.orderInfo?.length > 0 && formatDateToLongForm(orderDetail.orderInfo[0].time)}</h5>
  </div>
 
  </Col>
  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  {orderDetail?.transactionReference &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Transaction ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.transactionReference}</h5>
  </div>}
   
  {orderDetail?.orderComment &&   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Comment</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.orderComment}</h5>
      </div>}
  </Col>
</Row>
        </div>
        <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2'>
          <Col xs={5}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Items</h5>
          </Col>
          <Col xs={1}>
            <h5 style={{color:'#353542', fontWeight:'500'}}>QTY</h5>
          </Col>
          {orderDetail?.order?.shoppingInstructions?.length > 0 &&
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Shopping Instructions</h5>
          </Col>
          }
          <Col xs={2}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
          </Col>
        </Row>
        <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.cart?.map((item)=> (
        <Row  key={item?._id} className='p-0'>
          <Col xs={5}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.productId?.productName}</h5>
          </Col>
          <Col xs={1}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{item?.quantity}</h5>
          </Col>
          {orderDetail?.shoppingInstructions && orderDetail?.shoppingInstructions?.length > 0 &&
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'500'}}> {getShoppingInstructions(item?.productId?.productName, orderDetail?.shoppingInstructions)}</h5>
          </Col>
}
          <Col xs={2}>
          <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
  {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}
</h5>

          </Col>
        </Row>
        ))}
        </div>
        <div className='d-flex justify-content-end me-lg-5'>
        <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
        <div className='d-flex gap-3'> 
  <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Sub-Total:</h4>
  <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>
    {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(calculateSubtotal(orderDetail))}
  </h4>
</div>


          <div className='d-flex gap-3'> 
          {orderDetail?.deliveryType === 'delivery' ?
   <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Shipping fee:</h4> :    <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Processing fee:</h4>
}
{orderDetail?.fee === 0 ?  <div> <h4 style={{color:'#469B12', fontWeight:'500'}}> Free delivery</h4>  {orderDetail?.discountAmount !== 0 && orderDetail?.promotionType === 'free delivery' && orderDetail?.deliveryType === 'delivery' && <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px'}}>(₦{orderDetail?.discountAmount.toLocaleString()} discount deducted off shipping fee )</p>}
    </div>
    

    : 
       <div>
              <h4 style={{color:'#4F4F4F', fontWeight:'400'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.fee)}</h4>
              {orderDetail?.discountAmount !== 0 && orderDetail?.promotionType === 'free delivery' &&  orderDetail?.deliveryType === 'delivery' &&  <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px'}}>(₦{orderDetail?.discountAmount?.toLocaleString()} discount deducted off shipping fee )</p>}
              </div>
              }

          </div>
          {orderDetail?.shoppingInstructions?.length > 0 &&  <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'400', width:'135px'}}>Extra sevices:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalShoppingInstructionsFee)}</h4>
          </div>}
          {orderDetail?.discountAmount !== 0 && orderDetail?.promotionType !== 'free delivery' && <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'400', width:'130px'}}>Discount:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'400'}}> -{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.discountAmount)}</h4>
          </div>}
          <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'600', width:'135px'}}>Total:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.totalCost)}</h4>
          </div>
        </div>
        
        </div>
        <div className='d-flex justify-content-end me-lg-5'>
        
        <div className='d-flex gap-3'>
        <Button variant='outline-secondary'  onClick={handlePrint}><img src={print} alt='print'/>&nbsp;Print</Button>
         <Button className='btn-primary' onClick={downloadPdf}><img src={download} alt='download'/> &nbsp;Download</Button>
        </div>
        </div>
        <div className='text-center my-5'>
          <Button onClick={()=> navigate('/')} className='w-50'>Continue Shopping</Button>
        </div>
       </div> 
      : orderDetail?.boxes ?
        <div className='mx-lg-5'>
        <div style={{borderTop:'1px solid #000000',borderBottom:'1px solid #000000' }} className='mt-4 mb-5 p-3 '>
<Row>
  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Personal Information</h5>
   <div>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.user?.firstName}&nbsp;{orderDetail?.user?.lastName}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.user?.email}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='mt-2'>{orderDetail?.user?.phoneNumber}</h5>
   </div>
   
    
  
  </Col>
  {orderDetail?.address && 
 <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Address</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.address?.streetAddress}, {orderDetail?.address?.city?.name}, {orderDetail?.address?.state?.name}</h5>
   
  
   <h5 className='mt-1' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.phoneNumber}</h5>
   
  </div>
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Estimated Delivery date</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</h5>
   
  </div>
  {orderDetail?.frequency &&
  <div>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Delivery Frequency</h5>
  
   <h5 className='' style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.frequency}</h5>
   
  </div>
}
 </Col>
}


  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
    <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order ID</h5>
    <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.sku ? orderDetail?.sku : orderDetail?.id}</h5>
   </div>
   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order placed on</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}> {formatDateToLongForm(orderDetail.createdAt)}</h5>
  </div>
  {orderDetail?.orderComment &&   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Comment</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.orderComment}</h5>
      </div>}
  </Col>
  <Col xs={12} md={3} className='d-flex flex-column gap-2'>
  {orderDetail?.transactionReference &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Transaction ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.transactionReference}</h5>
  </div>}
   
 
  </Col>
</Row>
        </div>
        <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2'>
          <Col xs={5}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Box Name</h5>
          </Col>
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>QTY</h5>
          </Col>
        
          <Col xs={3}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
          </Col>
        </Row>
        <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
     
  {orderDetail?.boxes && (
    <Row className='p-0'>
      <Col xs={5}>
        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{orderDetail?.boxes?.box?.name}</h5>
      </Col>
      <Col xs={4}>
        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{orderDetail?.boxes?.quantity}</h5>
      </Col>
      <Col xs={3}>
        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.boxes?.box?.amount)}
        </h5>
      </Col>
    </Row>
  )}


        </div>
        <Row className='px-2 my-3'>
         
          <Col xs={5}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Items</h5>
          </Col>
          {orderDetail?.shoppingInstructions?.length > 0 &&
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Shopping Instructions</h5>
          </Col>}
         
        </Row>
        <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.boxes?.box?.items?.map((item)=> (
      <Row key={item?._id} className='p-0'>
        
       
        <Col xs={5}>

        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto'>{item?.productId?.productName}</h5>
        </Col>
        {orderDetail?.shoppingInstructions?.length > 0 &&
        <Col xs={4}>
        <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto'>{getShoppingInstructions(item?.productId?.productName, orderDetail?.shoppingInstructions)}</h5>
        </Col>}
       
      </Row>
    ))}
      
      </div>
        <div className='d-flex justify-content-end me-lg-5'>
        <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
        <div className='d-flex gap-3'> 
  <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Sub-Total:</h4>
  {orderDetail?.amount ?
  <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>
    {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail.discountAmount && orderDetail.discountAmount !== 0 ? (orderDetail?.amount - orderDetail?.fee - totalShoppingInstructionsFee) + orderDetail?.discountAmount : orderDetail?.amount - orderDetail?.fee - totalShoppingInstructionsFee)}
  </h4> : orderDetail?.totalCost ?  <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>
    {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail.discountAmount && orderDetail.discountAmount !== 0 ? (orderDetail?.totalCost - orderDetail?.fee - totalShoppingInstructionsFee) + orderDetail?.discountAmount : orderDetail?.totalCost - orderDetail?.fee - totalShoppingInstructionsFee)}
  </h4> : null}
</div>


          <div className='d-flex gap-3'> 
        
   <h4 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Shipping fee:</h4> 
{orderDetail?.fee === 0 ?   <h4 style={{color:'#469B12', fontWeight:'500'}}>Free delivery</h4>
    :
       
              <h4 style={{color:'#4F4F4F', fontWeight:'400'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.fee)}</h4>}
          </div>
          {orderDetail?.shoppingInstructions?.length > 0 &&  <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'400', width:'135px'}}>Extra sevices:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalShoppingInstructionsFee)}</h4>
          </div>}
         
          <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'600', width:'135px'}}>Total:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail.totalCost ||orderDetail?.amount)}</h4>
          </div>
        </div>
        
        </div>
        <div className='d-flex justify-content-end me-lg-5'>
        
        <div className='d-flex gap-3'>
        <Button variant='outline-secondary'  onClick={handlePrint}><img src={print} alt='print'/>&nbsp;Print</Button>
         <Button className='btn-primary' onClick={downloadPdf}><img src={download} alt='download'/> &nbsp;Download</Button>
        </div>
        </div>
        <div className='text-center my-5'>
          <Button onClick={()=> navigate('/')} className='w-50'>Continue Shopping</Button>
        </div>
       </div> 
       : orderDetail?.purchasedVoucher ?
       <div className='mx-lg-5'>
        <div style={{borderTop:'1px solid #000000',borderBottom:'1px solid #000000' }} className='mt-4 mb-5 p-3 '>
<Row>
  <Col xs={12} md={4} className='d-flex flex-column gap-2'>
  <h5 style={{fontWeight:'500', color:'#000000'}}>Personal Information</h5>
   <div>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.user?.firstName}&nbsp;{orderDetail?.purchasedVoucher?.user?.lastName}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.user?.email}</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}} className='mt-2'>{orderDetail?.purchasedVoucher?.user?.phoneNumber}</h5>
   </div>
   
    
  
  </Col>

 


  <Col xs={12} md={4} className='d-flex flex-column gap-2'>
    <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order ID</h5>
    <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.id}</h5>
   </div>
   <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Order placed on</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}> {formatDateToLongForm(orderDetail?.purchasedVoucher?.createdAt)}</h5>
  </div>
 
  </Col>
 
  <Col xs={12} md={4} className='d-flex flex-column gap-2'>
  {orderDetail?.purchasedVoucher?.voucherCode &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Voucher Code</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.voucherCode}</h5>
  </div>}

  {orderDetail?.purchasedVoucher?.transaction &&  <div> <h5 style={{fontWeight:'500', color:'#000000'}}>Transaction ID</h5>
   <h5 style={{color:'#353542', fontWeight:'400'}}>{orderDetail?.purchasedVoucher?.transaction?.transaction_reference}</h5>
  </div>}

   
 
  </Col>

</Row>
        </div>
        <Row style={{borderBottom:'1px solid #E0E0E0'}} className='px-2 pb-2'>
          <Col xs={6}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Voucher</h5>
          </Col>
          <Col xs={2}>
            <h5 style={{color:'#353542', fontWeight:'500'}}>QTY</h5>
          </Col>
          <Col xs={4}>
            <h5 style={{color:'#353542', fontWeight:'600'}}>Price (NGN)</h5>
          </Col>
        </Row>
        <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 mt-2 pb-2'>
     
     {orderDetail?.purchasedVoucher?.voucher && (
       <Row className='p-0'>
         <Col xs={6}>
           <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>{orderDetail?.purchasedVoucher?.voucher?.name}</h5>
         </Col>
         <Col xs={2}>
           <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>1</h5>
         </Col>
         <Col xs={4}>
           <h5 style={{color:'#344054', fontWeight:'500'}} className='my-auto ms-2'>
             {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.purchasedVoucher?.amount)}
           </h5>
         </Col>
       </Row>
     )}
   
   
           </div>
       
        <div className='d-flex justify-content-end me-lg-5'>
        <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
       
          <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'600', width:'135px'}}>Total:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.purchasedVoucher?.amount)}</h4>
          </div>
        </div>
        
        </div>
        <div className='d-flex justify-content-end me-lg-5'>
        
        <div className='d-flex gap-3'>
        <Button variant='outline-secondary'  onClick={handlePrint}><img src={print} alt='print'/>&nbsp;Print</Button>
         <Button className='btn-primary' onClick={downloadPdf}><img src={download} alt='download'/> &nbsp;Download</Button>
        </div>
        </div>
        <div className='text-center my-5'>
          <Button onClick={()=> navigate('/')} className='w-50'>Continue Shopping</Button>
        </div>
      
       </div>:
       (
                <div>No items found</div>
              )
            }     

       </div>
       </div>
       </>)}

       {showInvoice && <Invoice  orderDetail={orderDetail} />}
<div className='d-none'>
<Invoice ref={invoiceRef}  orderDetail={orderDetail} />
</div>
      </>
     
      

    </div>
  )
}

export default OrderConfirmation
