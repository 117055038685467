import React, { useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import SidebarNavbar from "../../components/Navbar/SideNavbar";
import { Link, useNavigate } from "react-router-dom";
import eye from "../../assets/eye.svg";
import authRoute from '../../services/authRoute';
import { useAppContext } from '../../AppContext'
import arrowLeft from '../../assets/arrow-left.svg'


const NewPassword = () => {
  const {showToast} = useAppContext();
  const [formData, setFormData] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const { updatePassword } = authRoute()
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const checkPasswordStrength = (password) => {
    const passwordRegex = /^.{8,}$/;
    return passwordRegex.test(password);
  };

  const isFormValid = () => {
    return (
      formData.password && formData.newPassword && formData.confirmPassword
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!formData.confirmPassword) {
      validationErrors.confirmPassword = "Please confirm password";
    } else if (formData.newPassword !== formData.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }

    if (!checkPasswordStrength(formData.password)) {
      validationErrors.password = "Password must have at least 8 characters";
    }
    if (!checkPasswordStrength(formData.newPassword)) {
      validationErrors.newPassword = "Password must have at least 8 characters";
    }

    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const body = {
        oldPassword: formData.password,
        password: formData.newPassword,
      };

      updatePassword(
        setLoading, body, showToast,
      )

    
    }
  };

  const handleBack = () => {
   
    navigate(-1);
  }
    
  return (
    <div>
       <div className='py-5 my-5 my-sm-0'>
       <div className='d-block d-sm-none mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex align-items-center gap-3 px-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className='' alt='arrow icon'/>
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>Password Update</h5>
              
           </div>
           </div>
           
         
  
    

        <div className="px-3 px-xxl-5 mx-lg-5">
          <div className="d-none d-sm-flex gap-2 align-items-center mb-3">
            <Link className="" to="/">
              <p style={{ color: "#475467" }}>
                Home &nbsp;{" "}
                <i
                  className="bi bi-chevron-left"
                  style={{ color: "#1D2939" }}
                ></i>
              </p>
            </Link>

            <p className="" style={{ color: "#1D2939", fontWeight: "600" }}>
              {" "}
              Password Update{" "}
            </p>
          </div>
          <Row>
            <Col xs={12} sm={3} className="d-none d-sm-block">
              <SidebarNavbar />
            </Col>
            <Col xs={12} sm={9} className="ps-3 ps-lg-4 pe-3 pe-lg-5">
              <div className="pe-lg-5 me-lg-5">
                <div className="mb-3">
                  <h5
                    style={{ color: "#0C0C0C", fontWeight: "500" }}
                    className="d-none d-sm-block"
                  >
                    Password Settings
                  </h5>
                  <h6 style={{ color: "#717171", fontWeight: "400" }}>
                    Enter the following information to update your password
                  </h6>
                </div>
                {loading ? (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
<div className="spinner-border text-success" role="status">
<span className="visually-hidden">Loading...</span>
</div>
  </div>):
                <Form className="my-3 pe-xl-5 me-xl-5" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3 pe-xl-5 me-xl-5">
                    <p className="mb-1" style={{ color: "#5D5D5D" }}>
                      Password
                    </p>

                    <div className="position-relative">
                      <button
                        type="button"
                        className=" border-0 position-absolute bg-white"
                        onClick={handleTogglePassword}
                        style={{ top: "20%", right: "3%" }}
                      >
                        {showPassword ? (
                          <i
                            className="bi bi-eye-slash"
                            style={{ color: "#B0B0B0" }}
                          ></i>
                        ) : (
                          <img
                            src={eye}
                            alt='eye icon'
                            style={{ width: "16px", height: "16px" }}
                          />
                        )}
                      </button>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter your old password"
                        className=""
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {error.password && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {error.password}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 pe-xl-5 me-xl-5">
                    <p className="mb-1" style={{ color: "#5D5D5D" }}>
                      New Password
                    </p>

                    <div className="position-relative">
                      <button
                        type="button"
                        className=" border-0 position-absolute bg-white"
                        onClick={handleTogglePassword}
                        style={{ top: "20%", right: "3%" }}
                      >
                        {showPassword ? (
                          <i
                            className="bi bi-eye-slash"
                            style={{ color: "#B0B0B0" }}
                          ></i>
                        ) : (
                          <img
                            src={eye} alt='eye icon'
                            style={{ width: "16px", height: "16px" }}
                          />
                        )}
                      </button>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="newPassword"
                        id="password"
                        placeholder="Min. of 8 characters"
                        className=""
                        value={formData.newPassword}
                        onChange={handleChange}
                      />
                      {error.newPassword && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {error.newPassword}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 pe-xl-5 me-xl-5">
                    <p className="mb-1" style={{ color: "#5D5D5D" }}>
                      Confirm Password
                    </p>

                    <div className="position-relative">
                      <button
                        type="button"
                        className=" border-0 position-absolute bg-white"
                        onClick={handleTogglePassword}
                        style={{ top: "20%", right: "3%" }}
                      >
                        {showPassword ? (
                          <i
                            className="bi bi-eye-slash"
                            style={{ color: "#B0B0B0" }}
                          ></i>
                        ) : (
                          <img
                          alt='eye icon'
                            src={eye}
                            style={{ width: "16px", height: "16px" }}
                          />
                        )}
                      </button>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        id="password"
                        placeholder="Confirm new password"
                        className=""
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      {error.confirmPassword && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {error.confirmPassword}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="pt-3 my-3 d-flex justify-content-end pe-xl-5 me-xl-5">
                    <Button
                      className="px-5 py-2"
                      disabled={!isFormValid()}
                      type="submit"
                      style={{
                        backgroundColor: isFormValid()
                          ? "#469B12"
                          : "rgba(70, 155, 18, 0.2)",
                        color: "#ffffff",
                        boxShadow: "0px 4px 4px 0px #0E35BF40",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Form>}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default NewPassword
