
import { createContext, useContext, useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { getParsedLocalStorageItem } from './components/Navbar/Navbar';

import axios from 'axios'
// import { trackViewContent } from './App';

import { localStorageGetItem,localStorageRemoveItem,localStorageSetItem } from './App';



export const AppContext = createContext();

const getInitialCart = () => {
  try {
    // Retrieve the cart from local storage
    const storedCart = localStorageGetItem('cartItems');
    return storedCart ? JSON.parse(storedCart) : [];
  } catch (error) {
    console.error("Failed to fetch cart from localStorage:", error);
    return [];
  }
};

const getInitialWishlist = () => {
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;
 
  if(isLoggedIn){
    return [];
  }
  else{
    try {
      // Retrieve the cart from local storage
      const storedWishlist = localStorageGetItem
('wishlistItems');
      return storedWishlist ? JSON.parse(storedWishlist) : [];
    } catch (error) {
      console.error("Failed to fetch wishlist from localStorage:", error);
      return [];
    }
  }
 
};

const getInitialFormData = () => {
  const savedFormData = localStorageGetItem
('formData');
  if (savedFormData) {
    // Parse the saved data
    const parsedSavedFormData = JSON.parse(savedFormData);
    // Return a new object merging the loaded data with default values for other form fields
    return {
      streetAddress: parsedSavedFormData.streetAddress || '',
      state: parsedSavedFormData.state || '',
      LGA: parsedSavedFormData.LGA || '',
      phoneNumber: '', 
      deliveryDate: '', 
      deliveryFrequency: '', 
      isDefaultAddress: false, 
      isSchoolOrBusinessAddress: false 
    };
  }
  
  return {
    streetAddress: '',
    state: '',
    LGA: '',
    phoneNumber: '',
    deliveryDate: '',
    deliveryFrequency: '', 
    isDefaultAddress: false,
    isSchoolOrBusinessAddress: false,
  };
};



export const AppContextProvider = (props) => {
// const navigate= useNavigate();
  const showToast = ({ toastText, subText, isSuccess, closeToast }) => {
    const content = (
      <div>
        <h5 style={{ fontWeight: '700' }} className='my-auto'>{toastText}</h5>
        {isSuccess && subText && <h6>{subText}</h6>}
      </div>
    );

    const toastOptions = {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      onClose: closeToast,
    };

    if (isSuccess) {
      toast.success(content, toastOptions);
    } else {
      toast.error(content, toastOptions);
    }
  };

  
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
   
    const [showCart, setShowCart] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);

  const [showWishlist, setShowWishlist] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountDescription, setDiscountDescription] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountType, setDiscountType] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 20,
    totalPages: 0,
    totalWishlistItems: 0
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchVersion, setSearchVersion] = useState(0);

  const [searchLoading, setSearchLoading] = useState(false);
  const [cartItems, setCartItems] = useState(getInitialCart());
  const [wishlistItems, setWishlistItems] = useState(getInitialWishlist());
  const [apiWishlistItems, setApiWishlistItems] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [promoCode, setPromoCode] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherDiscount, setVoucherDiscount] = useState(0);
  const [minOrderAmount, setMinOrderAmount] = useState(0);
 

 
  const [formData, setFormData] = useState(getInitialFormData());
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;
  // Effect to fetch user data if logged in

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 576);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  
  useEffect(() => {
    let timeoutId = null;
    
    const handleResize = () => {
      clearTimeout(timeoutId); // Clear any previously set timeout
      timeoutId = setTimeout(() => {
        setIsMobile(window.innerWidth <= 576);
      }, 150); // Adjust debounce time as needed
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup the event listener on component unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
   useEffect(() => {
    if (isLoggedIn) {
      const fetchUserDetails = async () => {
        try {

          const response = await axios.get('https://production-api.virtuesshop.co/user', {

          
            headers: { 'X-Auth-Token': token}, 
          });
        
          localStorageSetItem
("userData", JSON.stringify(response?.data?.data));

          setFormData(prevData => ({
            ...prevData,
            streetAddress:  response?.data?.data?.address?.streetAddress || prevData.streetAddress,
            LGA:  response?.data?.data?.address?.city?.name || prevData.LGA,
            state: response?.data?.data?.address?.state?.name || prevData.state,
          }));

        
           
        } 
        catch (error) {
          console.error('Error fetching user details:', error);
          // showToast({ toastText: "An unexpected error occurred", isSuccess: false });
          let resMessage = 'An unexpected error occurred'; // Default error message
    if (error.message === 'Network Error') {
      resMessage = 'Unable to connect. Please check your internet connection and try again.';
    } else if (error.message && (error.message.includes('TIMED_OUT') || error.message.includes('ERR_TIMED_OUT'))) {
      resMessage = 'Connection timed out. Please check your internet connection and try again.';
    } else if (error.message && (error.message.includes('CONNECTION_CLOSED') || error.message.includes('ERR_CONNECTION_CLOSED'))) {
      resMessage = 'Connection was closed unexpectedly. Please check your internet connection.';
    } else if (error.response) {
      const { status, data } = error.response;
      if ([400, 401, 405, 404, 409].includes(status)) {
        resMessage = data?.message || error.message || 'An unexpected error occurred';
      } else if (status === 500) {
        resMessage = 'We are experiencing technical issues. Please try again later.';
      } 
    }

    if (error.response && error.response.status === 401) {
      localStorageRemoveItem("userData");
      localStorageRemoveItem("userToken");
      localStorageRemoveItem("totalWishlistItems");
      // navigate("/login");
      showToast({
        toastText: 'Session timeout. Please log in again.',
        isSuccess: false,
      });
    } else if (error.response && [400, 403, 404, 405, 409, 500].includes(error.response.status)) {
     
      showToast({
        toastText: resMessage,
        isSuccess: false,
      });
        }
        }
      }
      fetchUserDetails();
    }
  }, [isLoggedIn]);

 
  useEffect(() => {
    localStorageSetItem
('formData', JSON.stringify({
      streetAddress: formData.streetAddress,
      state: formData.state,
      LGA: formData.LGA,
    }));
  }, [formData.streetAddress, formData.state, formData.LGA]);

 
 

    useEffect(() => {
      const savedSearches = JSON.parse(localStorageGetItem
('recentSearches')) || [];
      const lowerCaseMap = new Map();
    
      // Preserve the first occurrence of each search, case-insensitively
      savedSearches.forEach(search => {
        const lowerCaseSearch = search.toLowerCase();
        if (!lowerCaseMap.has(lowerCaseSearch)) {
          lowerCaseMap.set(lowerCaseSearch, search);
        }
      });
    
      const uniqueSearches = Array.from(lowerCaseMap.values());
      setRecentSearches(uniqueSearches);
    }, []);
    useEffect(() => {
      try {
        if (cartItems.length > 0) { 
          localStorageSetItem('cartItems', JSON.stringify(cartItems)); 
         
        } 
      } catch (error) {
        console.error("Failed to save cart to localStorage:", error);
      }
    }, [cartItems]);

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          const storedCart = getInitialCart();
          if (storedCart) {
            setCartItems(storedCart);
           
          }
        }
      };
    
      // Add event listener for visibility change
      document.addEventListener('visibilitychange', handleVisibilityChange);
    
      // Cleanup event listener on component unmount
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);

    // useEffect(() => {
    //   localStorageSetItem('cartItems', JSON.stringify(cartItems));
    // }, [cartItems]);
    
    useEffect(() => {
      const handleBeforeUnload = () => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [cartItems]);

    useEffect(() => {
      localStorageSetItem
('wishlistItems', JSON.stringify(wishlistItems));
    }, [wishlistItems]);
    
    const updateFormData = (newFormData) => {
      setFormData(prev => ({ ...prev, ...newFormData }));
    };
  
    const handleShowWishlist = () => {
      setShowWishlist(true)
      // const contentId = userData ? userData?.id : 'Guest'; // Use user ID or a default for guests
      // const contentType = 'View Wishlist';
      // trackViewContent(contentId, contentType);
    }
    
    const handleCloseWishlist = () => setShowWishlist(false);
   
  
    const handleShowCart = () => {
    
      setShowCart(true);
      
        // const userData = getParsedLocalStorageItem('userData');
        // const contentId = userData ? userData?.id : 'Guest'; // Use user ID or a default for guests
        // const contentType = 'View Cart';
      
        // trackViewContent(contentId, contentType);
  
    
    };
  
    const handleCloseCart = () => {
  
      
      setShowCart(false);
     
     
    };
   
    
  
    const addToWishlist = (productId) => {
      const updatedWishlist = [...wishlistItems, productId];
      localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlist));
      setWishlistItems(updatedWishlist);
    };
    
    const removeFromWishlist = (productId) => {
      const updatedWishlist = wishlistItems.filter(id => id !== productId);
      localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlist));
      setWishlistItems(updatedWishlist);
    };
  



    const updatedWishlist = (wishlist) => {
      localStorageSetItem
('wishlistItems', JSON.stringify(wishlist));
     
    };

// Update cart in AppContext and store in local storage
const updateCart = (newCartItems) => {
  // Update cart items in AppContext
  setCartItems(newCartItems);

 
  localStorageSetItem('cartItems', JSON.stringify(newCartItems));
 
};




const handleRemoveItem = (id) => {
  setCartItems(currentItems => 
    currentItems.filter(item => item.id !== id)
  );
  
};

const calculateTotalAmount = () => {
  return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
};



  const contextValue = { showToast,cartItems, setCartItems, updateCart, handleRemoveItem, calculateTotalAmount, handleCloseCart, handleShowCart, setShowCart, showCart, showWishlist, setShowWishlist, handleCloseWishlist, handleShowWishlist, wishlistItems, setWishlistItems,addToWishlist, removeFromWishlist, updatedWishlist, apiWishlistItems, setApiWishlistItems,setShowSidebar, showSidebar, setShowLogoutModal, showLogoutModal, setShowShareModal, showShareModal, setSearchLoading, searchLoading, searchQuery, setSearchQuery, searchResults, setSearchResults, products, setProducts, pagination, setPagination, searchPerformed, setSearchPerformed, searchVersion, setSearchVersion, recentSearches, setRecentSearches,formData, setFormData,updateFormData, setDiscount, discount, promoCode, setPromoCode, discountDescription, setDiscountDescription, minOrderAmount, setMinOrderAmount, setDiscountType, discountType, setDiscountPercentage, discountPercentage,voucherCode, setVoucherCode,voucherDiscount, setVoucherDiscount,isMobile, setIsMobile
   
  };

  return (
    <AppContext.Provider value={contextValue}>{props.children}
   
    </AppContext.Provider>
  );
}


export const useAppContext = () => {
  return useContext(AppContext);
};




