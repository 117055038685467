import React, {useState, useEffect, useRef, useCallback} from 'react';

import { Button, Card, Form, Row, Col, Popover, Overlay, InputGroup, ListGroup } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import arrowLeft from '../../assets/arrow-left.svg'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { trackEvent } from '../../mixpanel';
import { useNavigate } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa'
import orderRoute from '../../services/orderRoute';
import trash from '../../assets/trash.svg'
import Select from 'react-select';
import Waitlist from '../../components/Modal/Waitlist';
import productRoute from '../../services/productRoute'
import useGoogleLoader from '../../hooks/useGoogleLoader';
import { localStorageGetItem, localStorageSetItem } from '../../App';
import greenTag from '../../assets/green-tag.svg'
import redTag from '../../assets/red-tag.svg'
import userRoute from '../../services/userRoute';



const OrderSummary = () => {
  const {cartItems, setCartItems, updateCart,  calculateTotalAmount, handleShowCart, setWishlistItems, wishlistItems, showToast, apiWishlistItems, setApiWishlistItems, setFormData, formData, discount, setDiscount, promoCode, setPromoCode, discountDescription, setDiscountDescription,minOrderAmount, setMinOrderAmount, setDiscountType, discountType, setDiscountPercentage, discountPercentage,voucherCode, setVoucherCode,voucherDiscount, setVoucherDiscount, isMobile}= useAppContext();
  const {checkout,applyPromotion, applyVoucher}= orderRoute();
  const [loading, setLoading] = useState(false);
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem('userToken');
  const isLoggedIn = !!userData && !!token;
  const { getGlobalProducts}= productRoute();
  const [filledHearts, setFilledHearts] = useState({});
  const {updateWishlist, getWishlist, getDeliveryStates, getDeliveryLGAs}= orderRoute();
  const [target, setTarget] = useState(null);
  const [streetData, setStreetData]= useState({streetAddress:""});
  const googleLoader = useGoogleLoader();
  const streetAddressAutoCompleteInput = useRef(null);
  const [deliveryStates, setDeliveryStates] = useState([]);
  const [referData,setReferData] = useState({})

  const {getReferral}=userRoute()
  const navigate = useNavigate()
  const [additionalAmountNeeded, setAdditionalAmountNeeded] = useState(0);
  const [globalProducts, setGlobalProducts] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState(''); // 'in-store' or 'home'
  const [additionalFee, setAdditionalFee] = useState({amount: 0, type: ''}); // Object to hold fee amount and type
  const [showLGAModal, setShowLGAModal] = useState(false);
  const [showPromoCard, setShowPromoCard] = useState(true);
  const [showVoucherCard, setShowVoucherCard] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [showDeliveryForm, setShowDeliveryForm] = useState(false)
  const [isEditing, setIsEditing] = useState(true);
  const [selectOptions, setSelectOptions] = useState([]);
const [isSuccessful, setIsSuccessful] = useState(null)
  const [error, setError] = useState("");
  const [comment, setComment] = useState("");
  const totalAmount = calculateTotalAmount();
  const [checked, setChecked] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showDropdown, setShowDropdown] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [showAlert, setShowAlert] = useState({});
  const [selectedInstructions, setSelectedInstructions] = useState([]);
  const calculateTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };
  const totalItems = calculateTotalItems()

  

const [isPaymentDisabled, setIsPaymentDisabled] = useState(true);
const isFormValid = () => {
  if (deliveryOption === 'pickup' ){
    return  formData.phoneNumber && formData.deliveryDate
  }
  else if(deliveryOption === 'delivery' || deliveryOption === 'immediate'){
  return formData.streetAddress && formData.state && formData.LGA && formData.phoneNumber && formData.deliveryDate }
  
 
};
const [lgaOptions, setLgaOptions] = useState([]);
const [originalLgaOptions, setOriginalLgaOptions] = useState([]);
const [showWaitlist, setShowWaitlist] = useState(false);

const [immediateMessage, setImmediateMessage] = useState('');
const formatDate = (date) => {
  return date.toLocaleDateString('en-CA');
};

// Generate readable date string
const readableDate = (date) => {
  return date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
};

const calculateTuesdays = (year, month) => {
  let tuesdays = [];
  let firstDay = new Date(year, month, 1);
  // Find the first Tuesday
  let dayOfWeek = firstDay.getDay();
  let daysUntilFirstTuesday = (dayOfWeek <= 2) ? (2 - dayOfWeek) : (9 - dayOfWeek);
  let firstTuesday = new Date(year, month, 1 + daysUntilFirstTuesday);

  // Add up to the first 4 or 5 Tuesdays of the month
  for (let i = 0; i < 5; i++) {
    let nextTuesday = new Date(firstTuesday);
    nextTuesday.setDate(firstTuesday.getDate() + (i * 7));
    if (nextTuesday.getMonth() === month) {
      tuesdays.push(nextTuesday);
    }
  }

  return tuesdays;
};


const calculateServiceCosts = () => {
  return selectedCategories.reduce((total, category) => total + instructionFees[category].fee, 0);
};

const [lastActivity, setLastActivity] = useState(Date.now());

const handleUserActivity = useCallback(() => {
  setLastActivity(Date.now());
}, []);

useEffect(() => {
  // Event listeners to track user activity
  window.addEventListener('mousemove', handleUserActivity);
  window.addEventListener('keydown', handleUserActivity);
  window.addEventListener('scroll', handleUserActivity);
  window.addEventListener('click', handleUserActivity);

  // Function to check for inactivity
  const checkForInactivity = () => {
    const currentTime = Date.now();
    const timeDifference = currentTime - lastActivity;
    
    if (timeDifference >= 3600000) { // 1 hour in milliseconds
      showToast({toastText:'Session timeout', isSuccess:false})
      navigate('/');
    }
  };

  // Set interval to check for inactivity
  const interval = setInterval(checkForInactivity, 60000); // Check every minute

  // Cleanup event listeners and interval on component unmount
  return () => {
    window.removeEventListener('mousemove', handleUserActivity);
    window.removeEventListener('keydown', handleUserActivity);
    window.removeEventListener('scroll', handleUserActivity);
    window.removeEventListener('click', handleUserActivity);
    clearInterval(interval);
  };
}, [lastActivity, handleUserActivity, navigate]);

useEffect(()=> {
  if(isLoggedIn){
      getReferral(setLoading, showToast, setReferData)
  }

},[])

useEffect(() => {
  if (promoCode && discount > 0){
    setReferData({...referData, totalReferralDiscounts:0});
    setVoucherDiscount(0);
  }
  
}, [promoCode, discount]);

useEffect(()=>{
  if(voucherDiscount > 0){
    setReferData({...referData, totalReferralDiscounts:0});
    setDiscount(0);
  }
},[voucherDiscount])



useEffect(() => {

    getGlobalProducts(setLoading, showToast, setGlobalProducts);

}, []);

useEffect(() => {
 
  if (formData.state) {
    const selectedState = deliveryStates.find(state => state.name === formData.state);
    if (selectedState) {
      getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
    }
  }
}, [formData.state, deliveryStates]);
useEffect(()=>{
  getDeliveryStates(
    showToast, setDeliveryStates 
  )
},[])


useEffect(() => {
  const minimumAmountRequired = 12000;
  let total = totalAmount;
  if (promoCode && discountType === 'percentage' && discountPercentage !== 0) {
    const calculatedDiscount = totalAmount * discountPercentage / 100;
    setDiscount(calculatedDiscount);
  } else {
    setDiscount(discount);
  }
 

  if (promoCode && discount !== 0) {
    if (deliveryOption === 'delivery' && discountDescription === 'free delivery') {
      total = totalAmount
      // total = totalAmount - discount;
    } else if (discountDescription !== 'free delivery') {
      total = totalAmount - discount;
    } else {
      total = totalAmount - discount;
    }
  }

  if (minOrderAmount > 0) {

    if (totalAmount < minOrderAmount) { 
      const amountNeeded = minOrderAmount - totalAmount;
      setAdditionalAmountNeeded(amountNeeded);
   
    } else {
      setAdditionalAmountNeeded(0);
    }
  }
  else if (minOrderAmount === 0) {
    if (totalAmount < minimumAmountRequired) {
      const amountNeeded = minimumAmountRequired - totalAmount;
      setAdditionalAmountNeeded(amountNeeded);
    } else {
      setAdditionalAmountNeeded(0);
    }
  } else {
    setAdditionalAmountNeeded(0);
  }

}, [totalAmount, promoCode, discount, deliveryOption, discountPercentage, discountDescription, minOrderAmount]);

useEffect(() => {
  let shippingFee = 0;
  let feeType = '';

  if (deliveryOption === 'delivery') {
    const selectedLGA = lgaOptions.find(lga => lga.value === formData.LGA);
    if (selectedLGA) {
      shippingFee = selectedLGA.isFreeDelivery ? 0 : selectedLGA.deliveryFee;
    }
    feeType = 'Shipping Fee';

    // Adjust the shipping fee based on the discount for delivery option
    if (promoCode && discountDescription === 'free delivery' && discount !== 0) {
      if (shippingFee <= discount) {
        shippingFee = 0;
      } else {
        shippingFee = shippingFee - discount;
      }
    }
  } else if (deliveryOption === 'immediate') {
    const selectedLGA = lgaOptions.find(lga => lga.value === formData.LGA);
    shippingFee = selectedLGA ? selectedLGA.nextDayDeliveryFee : 0;
    feeType = 'Shipping Fee';
  } else if (deliveryOption === 'pickup') {
    shippingFee = 2000;
    feeType = 'Processing fee';
  }

  setAdditionalFee({ amount: shippingFee, type: feeType });
}, [formData.LGA, deliveryOption, lgaOptions, discountDescription, discount, promoCode]);


useEffect(()=>{
  let newSubTotal = 0;
  if (voucherDiscount > 0){
    if (totalAmount <= voucherDiscount) {
      newSubTotal = 0;
    } else {
      newSubTotal = totalAmount - voucherDiscount;
    }
  

    setSubTotal(newSubTotal)
  }
},[voucherDiscount,totalAmount])

useEffect(() => {
  let newGrandTotal;
  const serviceCostTotal = selectedCategories.length > 0 ? calculateServiceCosts() : 0;
  if (promoCode && discountType === 'percentage' && discountPercentage !== 0) {
    const calculatedDiscount = totalAmount * discountPercentage / 100;
    setDiscount(calculatedDiscount);
  } else {
    setDiscount(discount);
  }
  

  if (!isEditing) {
    const subtotal = (voucherDiscount > 0 ? subTotal : totalAmount) + additionalFee.amount + serviceCostTotal;
    if (promoCode && discountDescription === 'free delivery' && deliveryOption === 'delivery' && discount !== 0) {
      newGrandTotal = subtotal;
      // newGrandTotal = subtotal - discount;
    } else if(promoCode && discountDescription !== 'free delivery' && discount !== 0) {
      newGrandTotal =  subtotal - discount ;
    }
    else if(referData?.totalReferralDiscounts >= 10){  
      
        const calculatedDiscount = subtotal * 10 / 100;
        setDiscount(calculatedDiscount);
        
      
      newGrandTotal =  subtotal - calculatedDiscount ;
    }
    else{
      newGrandTotal =subtotal;
    }
  } else {
    if(referData?.totalReferralDiscounts >= 10){
      const calculatedDiscount = (totalAmount + serviceCostTotal) * 10 / 100;
      setDiscount(calculatedDiscount);
      newGrandTotal =  totalAmount + serviceCostTotal - calculatedDiscount 
    }

    else if(voucherDiscount > 0){
        newGrandTotal =  subTotal + serviceCostTotal 
      }
   
    else if (promoCode && discountDescription === 'free delivery' && discount !== 0) {
      newGrandTotal = totalAmount + serviceCostTotal;
    } else {
      newGrandTotal = promoCode && discount ? totalAmount + serviceCostTotal - discount : totalAmount + serviceCostTotal;
    }
  }

  setGrandTotal(newGrandTotal);
}, [isEditing, voucherDiscount, subTotal, referData, promoCode, totalAmount, additionalFee, discountDescription, deliveryOption, discount, discountPercentage,selectedCategories]);

useEffect(() => {
  const updatedCartItems = cartItems.map(item => {
    const updatedProduct = globalProducts.find(product => product?.id === item?.id);
    return updatedProduct ? {
      ...item,
      price: updatedProduct.price,
      stockLevel: updatedProduct.stockLevel,
      quantityAvailable: updatedProduct.quantityAvailable,
      shoppingInstructions:updatedProduct.shoppingInstructions,
      productImage: updatedProduct.productImage,
      productName: updatedProduct.productName
    } : item;
  });

  setCartItems(updatedCartItems);
  localStorageSetItem('cartItems', JSON.stringify(updatedCartItems));
}, [globalProducts]);


  useEffect(() => {
    let wishlist = [];
  
    if (isLoggedIn) {
      // If logged in, use wishlist items fetched from API
      wishlist = apiWishlistItems;
      const updatedWishlistStatus = {};
  cartItems.forEach(product => {
    const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product.id);
    updatedWishlistStatus[product.id] = isInWishlist;
  });
  setFilledHearts(updatedWishlistStatus);
    } else {
      // If not logged in, retrieve wishlist items from local storage
      wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
      const updatedWishlistStatus = {};
  cartItems.forEach(product => {
    const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product.id);
    updatedWishlistStatus[product.id] = isInWishlist;
  });
  setFilledHearts(updatedWishlistStatus);
    }
  
   
  
  }, [cartItems, apiWishlistItems]);


  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDay();
    const currentHour = today.getHours();
  
    let tuesdays = calculateTuesdays(currentYear, currentMonth);
  
    const eightHoursLater = new Date(today.getTime() + 8 * 3600 * 1000);
    tuesdays = tuesdays.filter(tuesday => tuesday >= eightHoursLater);
  
    if (tuesdays.length < 4) {
      const nextMonth = (currentMonth + 1) % 12;
      const nextYear = (currentMonth + 1 > 11) ? currentYear + 1 : currentYear;
      const nextMonthTuesdays = calculateTuesdays(nextYear, nextMonth);
      tuesdays = tuesdays.concat(nextMonthTuesdays.slice(0, 4 - tuesdays.length));
    }
  
    const options = tuesdays.slice(0, 4).map(date => ({
      value: formatDate(date),
      label: readableDate(date),
    }));
  
    if (deliveryOption === 'immediate') {
      if (currentDay === 1) {
        // Monday
        setImmediateMessage("To get your items delivered tomorrow (Tuesday), please use the weekly shopping option and also save more on delivery fees.");
        setSelectOptions([]);
      } else 
      if (currentDay === 6) {
        // Saturday
        const monday = new Date();
        monday.setDate(today.getDate() + ((1 + 7 - currentDay) % 7));
        setImmediateMessage("");
        setSelectOptions([{
          value: formatDate(monday),
          label: readableDate(monday),
        }]);
      } else {
       let nextDay = new Date();
    
    if (currentHour >= 17) {
      // After 5 PM, skip the next day
      nextDay.setDate(today.getDate() + 2);
    } else {
      // Before 5 PM, deliver the next day
      nextDay.setDate(today.getDate() + 1);
    }
    if (currentDay === 5 && currentHour >= 17) {
      const monday = new Date();
      monday.setDate(today.getDate() + ((1 + 7 - currentDay) % 7)); // Next Monday
      setImmediateMessage("");
      setSelectOptions([{
        value: formatDate(monday),
        label: readableDate(monday),
      }]);
    } else if (nextDay.getDay() === 0) {
      // Skip Sunday (no work), set Monday
      const monday = new Date();
      monday.setDate(nextDay.getDate() + 1); // Monday
      setImmediateMessage("");
      setSelectOptions([{
        value: formatDate(monday),
        label: readableDate(monday),
      }]);
    }else {
      setImmediateMessage("");
      setSelectOptions([{
        value: formatDate(nextDay),
        label: readableDate(nextDay),
      }]);
    }
  }
  } else {
      setImmediateMessage("");
      setSelectOptions(options);
    }
  }, [deliveryOption]);
  
  
 
  
  
  
  useEffect(() => {
    let autocompleteStreetAddress;
  
    if ((deliveryOption === 'delivery' || deliveryOption === 'immediate') && isEditing) {
      const handlePlaceSelect = (autocomplete) => {
        const place = autocomplete.getPlace();
        const formattedAddress = place.formatted_address || "";
        const addressComponents = place.address_components;
  
        // Function to normalize state names
        const normalizeStateName = (stateName) => {
          const stateMap = {
            'Lagos': ['Lagos', 'Ìpínlẹ̀ Èkó', 'Lagos State'],
            'Ogun': ['Ogun', 'Ìpínlẹ̀ Ògùn', 'Ogun State']
          };
  
          for (const [key, values] of Object.entries(stateMap)) {
            if (values.includes(stateName)) {
              return key;
            }
          }
          return stateName;
        };
  
        // Check if the address belongs to the specified state
        const stateLongName = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name;
        const normalizedStateName = normalizeStateName(stateLongName);
       
        const isInSpecifiedState = formData.state === normalizedStateName;
  
        if (isInSpecifiedState) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            streetAddress: formattedAddress,
          }));
    
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            streetAddress: '',
          }));
          showToast({ toastText: `Selected place is not in ${formData.state}.`, isSuccess: false });
          
      
        }
      };
  
      const setAutocompleteBounds = (google, autocomplete) => {
        // Define bounds for Lagos
        const lagosBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(6.3933, 3.1473),
          new google.maps.LatLng(6.7009, 3.4860)
        );
  
        // Define bounds for Ogun
        const ogunBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(6.3445, 2.5993),
          new google.maps.LatLng(7.2948, 3.7181)
        );
  
        if (formData.state === 'Lagos') {
          autocomplete.setBounds(lagosBounds);
        } else if (formData.state === 'Ogun') {
          autocomplete.setBounds(ogunBounds);
        } else {
          autocomplete.setBounds(null); // Clear bounds if state is not Lagos or Ogun
        }
      };
  
      googleLoader.load().then((google) => {
        const options = {
          componentRestrictions: { country: "NG" }, // Restrict search to Nigeria
        };
  
        if (streetAddressAutoCompleteInput.current) {
          autocompleteStreetAddress = new google.maps.places.Autocomplete(
            streetAddressAutoCompleteInput.current,
            options
          );
  
          autocompleteStreetAddress.addListener("place_changed", () => {
            handlePlaceSelect(autocompleteStreetAddress);
          });
  
          setAutocompleteBounds(google, autocompleteStreetAddress);
        }
      });
  
      return () => {
        if (autocompleteStreetAddress) {
          window.google.maps.event.clearInstanceListeners(autocompleteStreetAddress);
        }
      };
    }
  }, [googleLoader, deliveryOption, formData.state, setFormData]);
  
useEffect(()=> {
  trackEvent('Order Reviewed', {
    'User': userData?.email,
    attempted_at: new Date().toISOString(),
  })

},[])

  useEffect(() => {

    let minimumAmountRequired = minOrderAmount > 0 ? minOrderAmount : 12000
    const baseCondition = totalAmount < minimumAmountRequired || deliveryOption === '' || !checked;
    let additionalCondition = false;
    
   

    if(deliveryOption === 'immediate'){
      const today = new Date();
      
      const currentDay = today.getDay();
      additionalCondition = !isFormValid() || isEditing || currentDay === 1
        
    }else{
    
        additionalCondition = !isFormValid() || isEditing;
      }
    
  
    setIsPaymentDisabled(baseCondition || additionalCondition);
  }, [totalAmount, deliveryOption, isFormValid, isEditing]);
  

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    const isCheckbox = type === 'checkbox';
    setFormData({ ...formData, [name]: isCheckbox ? checked : value });
  
    if (name === "state") {
      const selectedState = deliveryStates.find(state => state.name === value);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
      }else{setLgaOptions([]); }
      setFormData((prevFormData) => ({
             ...prevFormData,
             streetAddress: ''
           }));
    }
  };
  

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const filteredLgas = originalLgaOptions.filter(lga => lga.label.toLowerCase().includes(inputValue.toLowerCase()));
      if (filteredLgas.length === 0 && inputValue.trim() !== "") {
        setLgaOptions([{ value: "comingSoon", label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.` }]);
      } else {
        setLgaOptions(filteredLgas);
      }
    }
  };
  


  const formatOptionLabel = ({ label, value }) => {
    if (value === "comingSoon") {
      // Split the label to insert styling on a specific part
      const firstPart = "We are coming to ";
      const secondPart = label.split('soon! ')[0].replace(firstPart, '');
      const thirdPart = "soon! ";
      const actionPart = "Join our waitlist and be the first to know when we do.";
     
  
      return (
        <div>
          {firstPart}
          <span>{secondPart}</span>
          {thirdPart}
          <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
        
        </div>
      );
    }
    // Return the label as is for other options
    return label;
  };
  
  const handleSelectChange = (selectedOption) => {
    if (!selectedOption) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: '' 
      }));
      setLgaOptions(originalLgaOptions)
      setAdditionalFee({ amount: 0, type: '' }); 
    } else if (selectedOption.value === "comingSoon") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: ''
      }));
      setShowWaitlist(true); 
      const selectedState = deliveryStates.find(state => state.name === formData.state);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
      }
    } else {
      const selectedLGA = lgaOptions.find(lga => lga.value === selectedOption.value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: selectedOption.value
      }));
      if (selectedLGA) {
        setAdditionalFee({ amount: selectedLGA.deliveryFee, type: 'Shipping Fee' });
      }
    }
  };
 

  const collectInstructions = () => {
    const instructions = {};
    cartItems.forEach(item => {
      item.shoppingInstructions.forEach(instruction => {
        if (!instructions[instruction.name]) {
          instructions[instruction.name] = {
            fee: instruction.amount,
            id: instruction.id,
            items: []
          };
        }
        instructions[instruction.name].items.push({
          productName: item.productName,
          productId:item.id,
          instructionId: instruction.id
        });
      });
    });
    return instructions;
  };

  const instructionFees = collectInstructions();

  const handleCategoryCheckboxChange = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
    const selectedProducts = selectedItems[category] || [];
    const eventName = selectedCategories.includes(category)
      ? 'Shopping Instructions Removed'
      : 'Shopping Instructions Added';

    trackEvent(`${category} ${eventName}`, {
      'User': userData?.email || 'Anonymous',
      'Shopping Instruction': category,
      'Selected Products': selectedProducts.join(', '),
    });
  
  };

  const handleToggleDropdown = (category, targetElement) => {
    setTarget(targetElement);
    setShowDropdown(prevShowDropdown => ({
      ...prevShowDropdown,
      [category]: !prevShowDropdown[category]
    }));
  };

  const handleSelectItem = (item, category) => {
    setSelectedItems(prev => {
      const items = prev[category] || [];
      const newItems = items.includes(item.productName)
        ? items.filter(i => i !== item.productName)
        : [...items, item.productName];

      // Automatically check the category if an item is selected
      if (newItems.length > 0 && !selectedCategories.includes(category)) {
        setSelectedCategories([...selectedCategories, category]);
        trackEvent(`${category} Shopping Instructions Added`, {
          'User': userData?.email || 'Anonymous',
          'Shopping Instruction': category,
          'Selected Products': newItems.join(', '),
        });
      } else if (newItems.length === 0) {
        setSelectedCategories(selectedCategories.filter(c => c !== category));
      }

      return {
        ...prev,
        [category]: newItems
      };
    });
    setShowAlert(prev => ({ ...prev, [category]: '' }));
  };

  const handleRemoveShoppingItem = (itemName, category) => {
    setSelectedItems(prev => {
      const newItems = prev[category].filter(i => i !== itemName);

      // Uncheck the category if no items remain selected
      if (newItems.length === 0) {
        setSelectedCategories(selectedCategories.filter(c => c !== category));
      }

      return {
        ...prev,
        [category]: newItems
      };
    });
  };

  const hasShoppingInstructions = Object.keys(instructionFees).length > 0;
  
  const toggleFill = (itemId) => {
const productId = itemId;
const products = cartItems;
   
    const item = cartItems.find(p => p.id === itemId);
   
    if (isLoggedIn) {
      updateWishlist( productId,
        showToast,
        filledHearts,
        setFilledHearts,
        apiWishlistItems,
        setApiWishlistItems,
        products);
    } else {
      const newState = !filledHearts[productId];
      setFilledHearts(prevState => ({
        ...prevState,
        [productId]: newState,
      }));
  
      let wishlist = getParsedLocalStorageItem('wishlistItems') || [];
      const index = wishlist.findIndex(item => item?.product?.id === productId);
      let toastText = '';
  
      if (newState) {
        // Add to local wishlist if not already present
        if (index === -1) {
          const productToAdd = cartItems.find(product => product.id === productId);
          wishlist = [{product:productToAdd}, ...wishlist]; // Prepend the new product
          toastText = 'Item added to Wishlist!';
          // trackAddToWishlist(productId, productToAdd.productName);
        }
      } else {
        // Remove from local wishlist if present
        if (index !== -1) {
          wishlist = wishlist.filter(item => item?.product?.id !== productId);
          toastText = 'Item removed from Wishlist!';
        }
      }
  
      // Update local storage and state
      localStorageSetItem('wishlistItems', JSON.stringify(wishlist));
      setWishlistItems(wishlist);
  
      
      showToast({
        toastText: toastText,
        isSuccess: true
      });
      trackEvent(newState ? `${item?.productName} Added to Wishlist` : `${item?.productName} Removed from Wishlist`, {
        'Product ID': itemId,
        'Product Name': item?.productName,
        'Category Name': item?.category,
        'User': userData?.email || 'Anonymous',
     
      });
    }
    
  };

  
  const handleDeliveryOptionChange = (event) => {
    const selectedOption = event.target.value;
     if(selectedOption === 'immediate'){
      if(totalAmount < 60000){
        showToast({toastText:'Next day delivery is only available for orders of ₦60,000 and above'})
        return
      }
     }

    setDeliveryOption(selectedOption);
    setIsEditing(true);

    if (selectedOption === 'pickup') {
      setAdditionalFee({ amount: 2000, type: 'Processing Fee' });
    }
    trackEvent('Delivery Option Selected', {
      'User': userData?.email || 'Anonymous',
      'Delivery Option': selectedOption,
    });
  };
  
  

  const handleStreetChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


 
  const isNigerianPhoneNumber = (phoneNumber) => {
    // This regex matches phone numbers starting with '070', '080', '090', '081', and '091'
    return /^0[7-9][0-1]\d{8}$/.test(phoneNumber) || /^091\d{8}$/.test(phoneNumber);
  };
 

  const handleEditDelivery = () => {
    setIsEditing(true);
    trackEvent('Edit Delivery Address', {
      clicked_at: new Date().toISOString(),
      'User': userData?.email ,
     
    })
  };
  const handleSaveDelivery = (e) => {
    e.preventDefault();
    
    const validationErrors = {};

    
  if (!formData.phoneNumber) {
    validationErrors.phoneNumber = "Please enter your Phone Number";
  } else if (formData.phoneNumber.length !== 11) {
    validationErrors.phoneNumber =
      "Please enter a valid 11-digit Nigerian phone number";
  }

    

    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (promoCode !== '' && discountDescription === 'free delivery' && (deliveryOption === 'pickup' || deliveryOption === 'immediate') ){
        showToast({toastText:'This coupon applies to only weekly delivery orders. Please remove coupon or change delivery option to weekly delivery.', isSuccess:false})
        return
      }
   
    setIsEditing(false)
    
    trackEvent('Add Delivery Details ', {
      'User': userData?.email ,
      'Address': `${formData.streetAddress}, ${formData.LGA}, ${formData.state}`,
      'Phone number': formData.phoneNumber,
      'Delivery date': formData.deliveryDate
     
      
    });
     
    }
  };
  
  const handleIncreaseQuantityInCart = (productId) => {
    const updatedCartItems = cartItems.map(item => {
        if (item?.id === productId) {
            // Find the corresponding product in the global products array
            const product = globalProducts.find(p => p?.id === productId);

            if (product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0) {
              showToast({ toastText: 'This product is out of stock!', isSuccess: false });
              return item; 
          }else if(item?.quantity + 1 > product?.quantityAvailable) {
                showToast({ toastText: `This product has only ${product?.quantityAvailable} items in stock.`, isSuccess: false });
                return item; 
            }

           
            return { ...item, quantity: item?.quantity + 1 };
        }
        return item;
    });

    setCartItems(updatedCartItems);
    updateCart(updatedCartItems)

    const updatedProduct = updatedCartItems.find(p => p?.id === productId);
    if (updatedProduct) {
      // trackAddToCart(updatedProduct.id, updatedProduct.productName, updatedProduct.price);
        trackEvent(`${updatedProduct.productName} Increased in Cart`, {
            'Product ID': updatedProduct.id,
            'Product Name': updatedProduct.productName,
            'Category Name': updatedProduct.category,
            'Quantity Added': updatedProduct.quantity,
            'User': userData?.email || 'Anonymous',
        });
    }
};

const handleDecreaseQuantityInCart = (productId) => {
  
    const updatedCartItems = cartItems.map(item => 
      item?.id === productId && item?.quantity > 1 ? { ...item, quantity: item?.quantity - 1 } : item
    )
    setCartItems(updatedCartItems);
    updateCart(updatedCartItems)
  
    const updatedProduct = updatedCartItems.find(p => p.id === productId);
    trackEvent(`${updatedProduct?.productName} Decreased in Cart`, {
      'Product ID': updatedProduct?.id,
      'Product Name': updatedProduct?.productName,
      'Category Name': updatedProduct?.category,
      'Quantity Added': updatedProduct.quantity, 
      'User': userData?.email || 'Anonymous',
    });
};

const handleRemoveItem = (id) => {
  
  const removedProduct = cartItems.find(p => p.id === id);
  
  const updatedCartItems = cartItems.filter(item => item.id !== id);
  setCartItems(updatedCartItems);
  updateCart(updatedCartItems)
 
  trackEvent(`${removedProduct.productName} Removed from Cart`, {
    'Product ID': removedProduct.id,
    'Product Name': removedProduct.productName,
    'Category Name': removedProduct.category,
    'Quantity': removedProduct.quantity,
    'User': userData?.email || 'Anonymous',
  });
};

const handleShow=()=> {
  
  handleShowCart();
}


const isContinueButtonEnabled = () => {
  return additionalAmountNeeded === 0;
};
const handleContinue = () => {
  if (isContinueButtonEnabled()) {
    setShowDeliveryForm(true);
  }
}




const handleBack = ()=> {
  setShowDeliveryForm(false);
}



const handleSubmitOrder = async () => {
  // const deliverySchedule = "scheduled"; 
  const paymentMethod = "paystack"; // Fixed value
  // const deliveryDate = new Date(formData.deliveryDate).toISOString();
  const phoneNumber = formData?.phoneNumber;
  // const deliveryType = deliveryOption;

  const invalidItems = cartItems?.filter(item => {
      const product = globalProducts?.find(p => p?.id === item?.id);
      return !product || product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0 || item?.quantity > product?.quantityAvailable;
  });

  if (invalidItems?.length > 0) {
     
      let outOfStockMessage = '';
      let lowQuantityMessage = '';
      
      invalidItems?.forEach(item => {
          const product = globalProducts?.find(p => p?.id === item?.id);
       
          if (product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0) {
              outOfStockMessage += `${item.productName}, `;
          } else {
              lowQuantityMessage += `We only have ${product?.quantityAvailable} items in stock for ${item?.productName}. `;
          }
      });

      // Construct the final toast message
      let toastText = '';
      if (outOfStockMessage) {
          toastText += `${outOfStockMessage?.trim()} ${outOfStockMessage?.length > 1 ? 'are' : 'is'} out of stock. `;
      }
      if (lowQuantityMessage) {
          toastText += `${lowQuantityMessage?.trim()} `;
      }

      // Show the toast message
      showToast({ toastText: toastText.trim(), isSuccess: false });

      return; 
  } 


  if(voucherDiscount > 0){
    if(totalAmount < voucherDiscount){
     
        showToast({ toastText: `You still have ₦${(voucherDiscount - totalAmount).toLocaleString()} on your voucher discount. Please add more items to your cart as the remainder cannot be redeemed.`, isSuccess: false });
        return;
        
    }
  }
   if (promoCode && discountDescription === 'free delivery' && (deliveryOption === 'pickup' || deliveryOption === 'immediate')){
    showToast({toastText:'This coupon applies to only weekly delivery orders. Please remove coupon or change delivery option to weekly delivery.', isSuccess:false})
    return
  }

  if (discountType === 'percentage' && discountPercentage !== 0) {
    const calculatedDiscount = totalAmount * discountPercentage / 100;
    setDiscount(calculatedDiscount);
  } else {
    setDiscount(discount);
  }

  let total = totalAmount;
  if (discountDescription !== '' && discount !== 0) { 
    
    total = discountDescription === 'free delivery' ? totalAmount : totalAmount - discount;
   } else {
      total = totalAmount
    }
 
   
    if (minOrderAmount === 0 && totalAmount < 12000){
    showToast({ toastText: "The minimum basket value is ₦12,000 NGN. Please add more items to your cart.", isSuccess: false });
    return;
    } else if (minOrderAmount > 0 && totalAmount < minOrderAmount){
      showToast({ toastText: `The minimum basket value is ₦${minOrderAmount}. Please add more items to your cart.`, isSuccess: false });
      return;
      }
    else{

  // Prepare the order body
  const carts = cartItems?.map(item => ({
      productId: item.id,
      quantity: item.quantity,
      productName: item.productName
  }));
  
  const address = {
      streetAddress: formData.streetAddress,
      city: formData.LGA,
      state: formData.state,
      schoolOrWork: formData.isSchoolOrBusinessAddress,
      makeDefault: formData.isDefaultAddress,
  };

  const selectedState = deliveryStates.find(state => state.name === formData.state);
  const stateId = selectedState ? selectedState.id : null;

  // Extract LGA ID
  const selectedLGA = lgaOptions.find(option => option.label === formData.LGA);
  const lgaId = selectedLGA ? selectedLGA.id : null;

  const selectedInstructions = selectedCategories.length > 0 ? selectedCategories.flatMap(category => {
    const uniqueInstructionIds = new Set();

    instructionFees[category].items.forEach(item => {
      if (selectedItems[category]?.includes(item.productName)) {
        uniqueInstructionIds.add(item.instructionId);
      }
    });

    return [...uniqueInstructionIds].map(instructionId => ({
      instruction: instructionId,
      products: instructionFees[category].items
        .filter(item => item.instructionId === instructionId && selectedItems[category]?.includes(item.productName))
        .map(item => item.productId)
    }));
  }) : [];
  const body = {
      paymentMethod,
      phoneNumber,
      carts
  };
  
  if (deliveryOption !== 'pickup') {
    body.deliveryType = 'delivery';
  }
  else{  body.deliveryType = 'pickup'};

  const orderComment = comment;
  if (deliveryOption !== 'immediate') {
    body.deliveryDate = new Date(formData.deliveryDate).toISOString();
  }
  if (deliveryOption === 'immediate') {
    body.deliverySchedule = 'immediate';
}else{
  body.deliverySchedule = 'scheduled';
}

  if (orderComment) {
    body.orderComment = orderComment;
}

if (promoCode !== '') {
  body.couponCode = promoCode;
}

if (voucherCode !== '') {
  body.voucherCode = voucherCode;
}


if (selectedCategories.length > 0) {
  body.shoppingInstructions = selectedInstructions;
}
  
  if (deliveryOption !== 'pickup') {
    body.address = {
      ...address,
      state: stateId, // Pass state ID instead of state name
      city: lgaId, // Pass LGA ID instead of LGA name
    };
  }



  checkout(body, setLoading, showToast);
}}
const handlePromotions = (e) => {
  e.preventDefault()
  const minimumAmountRequired = 12000;
  if (totalAmount < minimumAmountRequired) {
    setAdditionalAmountNeeded(minimumAmountRequired - totalAmount);
  } else {

   
  
      const body = {
        couponCode: promoCode,
        totalCostAmount: totalAmount,
      
      }

      applyPromotion(body, setDiscount,setDiscountDescription,setDiscountType, setDiscountPercentage, setMinOrderAmount, setLoading, setIsSuccessful, showToast)
    } 
   
    
    
  
};

const handleVoucher = (e) => {
  e.preventDefault()
  const minimumAmountRequired = 12000;
  if (totalAmount < minimumAmountRequired) {
    setAdditionalAmountNeeded(minimumAmountRequired - totalAmount);
  } else {
   
   
  
      const body = {
        voucherCode: String(voucherCode),
        totalCostAmount: totalAmount,
      
      }

      applyVoucher(body, setVoucherDiscount,setLoading, setIsSuccessful, showToast)
    } 
   
    
    
  
};

const handleRemove = () => {
  setPromoCode('');
  setDiscount(0)
  setVoucherCode('')
  setVoucherDiscount(0)
  setIsSuccessful(null);
};


  return (
    <>
    
    {isMobile ? (<div className='px-lg-5 mx-lg-5 py-5'>
      {showDeliveryForm && (<> 
      <div className='pt-5 pb-3'>
              <div className='px-3' style={{borderBottom:'1px solid #D0D9E3'}}> 
              <div className='d-flex gap-3 align-items-center pb-3' > 
              <img src={arrowLeft} onClick={handleBack}/>
       <h6 className='my-auto'style={{color:'#0D111B', fontWeight:'700'}}>Payment & Delivery Information</h6>
      </div>
     
      </div>
              </div> 
              <div className='mb-4 p-3'>
            <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}>Delivery Options</h6>
            <p>You can pickup your orders from our store, have it delivered to you on our weekly Tuesday group shopping dates or opt for the next day delivery option.</p>
            <div className='d-flex gap-3'>
            <div className='d-flex'>
       
          <Form.Check
            type="radio"
            name="deliveryOption"
            value="pickup"
            label="In-Store Pickup"
            checked={deliveryOption === 'pickup'}
            onChange={handleDeliveryOptionChange}
          />
        
      </div>
      
      <div className='d-flex'>
       
          <Form.Check
            type="radio"
            name="deliveryOption"
            value="delivery"
            label='Weekly Delivery'
            checked={deliveryOption === 'delivery'}
            onChange={handleDeliveryOptionChange}
          />
        
      </div>
      <div className='d-flex'>
       
       <Form.Check
         type="radio"
         name="deliveryOption"
         value="immediate"
         label='Next Day Delivery'
         checked={deliveryOption === 'immediate'}
         onChange={handleDeliveryOptionChange}
       />
     
   </div>
            </div>
            </div>
            {deliveryOption && 
          <Card className='p-3 mb-4 ' style={{border:'none'}}>
             {deliveryOption === 'pickup' && <div>
              <h6 style={{color:'#0D111B', fontWeight:'700'}}>Pickup Address</h6>
              <p style={{color:'#353542', fontWeight:'400'}}>No. 2, Ileri-Oluwa Crescent, Ifako-Ijaiye, via
            College Road, Oyemekun Bus-Stop</p>
             </div>
            
            }
          <div className='d-flex gap-3'>
          {deliveryOption !== 'pickup' ? 
      <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}>Delivery Details</h6> : <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}> Details</h6>}
      {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEditDelivery}>Edit</p>
      )}
    </div>

            <Form className='my-3' onSubmit={handleSaveDelivery}>
            {deliveryOption !== 'pickup' && (<>
           

             <Row>
             <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D', fontSize:'12px'}}>State/Province</p>
          
            <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.state || ''}
        name="state"
        required disabled={!isEditing}
      > <option value="" disabled>Select a state</option>
    
        {/* <option value='Lagos'>Lagos</option>
        <option value='Ogun'>Ogun</option> */}
 {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}
      </Form.Select>

            </Form.Group>
              </Col>
              <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontSize:'12px'}}>Delivery Area {isEditing && !formData?.LGA && <span>(Please select a state first)</span>}</p>
          
            <Select
         formatOptionLabel={formatOptionLabel}
          options={lgaOptions}
          onInputChange={handleInputChange}
          onChange={handleSelectChange}
         
        required
           value={lgaOptions.find(option => option.value === formData.LGA) || ''}

          name="LGA"
          isDisabled={!isEditing}
          isClearable={true}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 2000, // Ensure the dropdown has a high z-index
            }),
            menuList: (provided) => ({
              ...provided,
              zIndex: 2000, // Ensure the dropdown options have a high z-index
            }),
          }}
          
        />

            </Form.Group>
              </Col>
             </Row>
             <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontSize:'12px'}}>Street Address {isEditing && !formData?.LGA && <span>(Please select a state first)</span>}</p>
            <div className="input-group">
            <Form.Control
  ref={streetAddressAutoCompleteInput}
  name="streetAddress"
  value={formData?.streetAddress}
  placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
  onChange={handleStreetChange}
  style={{ border: '1px solid #D7D7D7' }}
  required
  disabled={!isEditing}
/>

  {isEditing && formData.streetAddress && (
   <Button variant='outline-secondary'  onClick={() => setFormData({ ...formData, streetAddress: '' })}  style={{ zIndex: 1000 }}>
    X</Button>
  )}
</div>
<p className='text-secondary mt-1' style={{fontSize:'11px'}}>Please ensure you enter the correct delivery address. Additional costs associated with a wrong delivery address will be your responsibility. Thank you!</p>


            </Form.Group>

            <Form.Group className='d-flex gap-1 align-items-center mb-3'>
            <Form.Check
    type='checkbox'
    name='isDefaultAddress'
    checked={formData.isDefaultAddress}
    onChange={handleChange} disabled={!isEditing}
  /> <p className='my-auto'style={{color:'#4F4F4F',fontSize:'12px'}}>Set as default address</p>
              </Form.Group>
            </>)}
            
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontSize:'12px'}}>Phone</p>
            <Form.Control
  type='text'
  placeholder='Enter phone number'
  style={{border:'1px solid #D7D7D7'}}
  value={formData.phoneNumber}
  onChange={(event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    if (numericValue.length <= 11) {
      setFormData({ ...formData, phoneNumber: numericValue });
    }

    
    if (numericValue.length === 11 && !isNigerianPhoneNumber(numericValue)) {
      setError({...error, phoneNumber: 'Please enter a valid 11-digit Nigerian phone number.'});
    } else {
    
      setError({...error, phoneNumber: ''});
    }
  }}
  readOnly={!isEditing}
/>
{error.phoneNumber && <div className="text-danger" style={{fontSize:'11px'}}>{error.phoneNumber}</div>}

            </Form.Group>
            {deliveryOption !== 'pickup' && (
            <Form.Group className='d-flex gap-1 align-items-center mb-3'>
              <Form.Check type='checkbox'
    name='isSchoolOrBusinessAddress'
    checked={formData.isSchoolOrBusinessAddress}
    onChange={handleChange} disabled={!isEditing}/> <p className='my-auto'style={{color:'#4F4F4F',fontSize:'12px'}}>Please check if this is either a school or business address</p>
              </Form.Group>)}

              <Form.Group className=' mb-3'>
              {deliveryOption !== 'pickup' ? 
              <p className='mb-1' style={{color:'#5D5D5D',fontSize:'12px'}}>Delivery Date</p>: <p className='mb-1' style={{color:'#5D5D5D',fontSize:'12px'}}>Pickup Date</p>}
              {deliveryOption === 'immediate' && immediateMessage ? (
    <p className='mb-1 text-danger' style={{color:'#5D5D5D',fontSize:'11px'}}>{immediateMessage}</p>
  ) : (
    <div className="custom-select-wrapper">
      <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.deliveryDate}
        name="deliveryDate"
        required disabled={!isEditing}
      >
        <option value="" disabled>Select a date</option>
        {selectOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Select>
      <FaRegCalendarAlt className="select-icon" />
    </div>)}
              </Form.Group>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontSize:'12px'}}>Delivery Comment (optional)</p>
         
            <Form.Control
  type='text' placeholder='Add notes/comments to your order(s)' rows={3} className=' p-2 w-100' style={{border:'1px solid #D7D7D7'}}value={comment} onChange={(e)=> setComment(e.target.value)}
  disabled={!isEditing}
/>



            </Form.Group>

              {isEditing && (
        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
      )}
            </Form>
            </Card>
          }

{hasShoppingInstructions && (
     <Card style={{ border: 'none' }} className='p-3 mb-4'>
     <h6 className='mb-4' style={{ color: '#0D111B', fontWeight: '700' }}>Shopping Instructions</h6>
     <div className='d-flex flex-column gap-3'>
       {Object.keys(instructionFees).map((category, index) => (
         <div key={index}>
           <div className='d-flex align-items-center gap-4'>
             <Form.Group className='d-flex align-items-center gap-2'>
               <Form.Check
                 type='checkbox'
                 className='d-flex align-items-center gap-2'
                 checked={selectedCategories.includes(category)}
                 onChange={() => {
                   if (selectedItems[category]?.length > 0) {
                     handleCategoryCheckboxChange(category);
                     setShowAlert(prev => ({ ...prev, [category]: '' }));
                   } else {
                     setShowAlert(prev => ({ ...prev, [category]: 'Please select at least one item first.' }));
                   }
                 }}
               />
               <h6 className='my-auto'>{category}</h6>
             </Form.Group>
  
             <div
               className='shopping-container w-100 justify-content-between align-items-center'
               style={{
                 cursor: 'pointer',
                 border: '1px solid #ced4da',
                 borderRadius: '8px',
                 padding: '0.35rem 0.75rem',
                 minHeight: '38px',
                 gap: '5px'
               }}
               onClick={(e) => handleToggleDropdown(category, e.currentTarget)}
             >
               {selectedItems[category]?.length > 0 ? (
                 <div className='item-container w-100'>
                   {selectedItems[category].map((item, index) => (
                     <Button
                       variant='outline-secondary'
                       style={{
                         color: '#11142D',
                         border: '1px solid #E7E8EA',
                         fontWeight: '500',
                         fontSize: '11px',
                         boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
                       }}
                       key={index}
                       onClick={(e) => {
                         e.stopPropagation();
                         handleRemoveShoppingItem(item, category);
                       }}
                     >
                       {item} &nbsp; X
                     </Button>
                   ))}
                 </div>
               ) : (
                 <span style={{ color: '212529BF', fontWeight: '400', fontSize: '12px' }} className='my-auto align-iems-center'>Select items for {category}</span>
               )}
               <i className='bi bi-chevron-down'></i>
             </div>
  
             <Overlay
               show={showDropdown[category]}
               target={target}
               placement="bottom"
               rootClose={true}
               onHide={() => setShowDropdown(prev => ({ ...prev, [category]: false }))}
             >
               <Popover id={`popover-positioned-bottom-${category}`}>
                 <Popover.Header as="h5">{`Select the item for ${category}`}</Popover.Header>
                 <Popover.Body>
                   {instructionFees[category].items.map((item, index) => (
                     <ListGroup.Item key={index} as='div' className='ms-1 py-2'>
                       <Form.Check
                         type='checkbox'
                         label={item.productName}
                         checked={selectedItems[category]?.includes(item.productName)}
                         onChange={() => handleSelectItem(item, category)}
                         className='d-flex align-items-center gap-2'
                       />
                     </ListGroup.Item>
                   ))}
                 </Popover.Body>
               </Popover>
             </Overlay>
           </div>
  
           {showAlert[category] && (
             <div className="text-danger" style={{ fontSize: '12px' }}>{showAlert[category]}</div>
           )}
         </div>
       ))}
     </div>
   </Card>
)}


{loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (<>
     
     {
      showPromoCard &&
     
        <Card style={{border:'none'}} className='mb-3'>
        <div className='p-3' style={{borderBottom:'1px solid #F8F8F9'}}>
        <p style={{color:'#0D111B'}} className='mb-1'>Promotional Coupon</p>
        <Form className=' '  onSubmit={handlePromotions}>
        <InputGroup>
  <Form.Control
    type='text'
    placeholder='Enter promotional code'
    value={promoCode}
    required
    onChange={(e) => setPromoCode(e.target.value)}
  />
  {isSuccessful ? (
    <Button className='' variant='danger' onClick={handleRemove}>
      Remove
    </Button>
  ) : (
    <Button  variant={promoCode !== '' ? 'primary' : 'secondary'} type='submit'>
      Apply
    </Button>
  )}
</InputGroup>

{isSuccessful === true  && <p style={{backgroundColor:'#DCF8D1', color:'#4AB247', fontSize:'10px', maxWidth:'400px'}} className='p-2 my-2'><img src={greenTag}/>&nbsp;Coupon code valid! {discountDescription === 'free delivery' ? `- ${new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(discount)} off shipping fee. For weekly delivery orders only` : ''}</p> }
        {isSuccessful === false  && <p style={{backgroundColor:'#FCE5D0', color:'#CE371C', fontSize:'10px', width:'150px'}} className='p-2 my-2'><img src={redTag}/> &nbsp;Coupon code invalid!</p>}
       
        </Form>
        </div>
        <p className='my-2 pb-0 ms-3'style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', textDecoration:"underline", cursor:"pointer"}}  onClick={()=> {setShowPromoCard(false);setShowVoucherCard(true);setIsSuccessful(null);setPromoCode('');setDiscount(0)}}>Click here to use voucher code to checkout</p>
      </Card>}
      {
      showVoucherCard && 
     
        <Card style={{border:'none'}} className='mb-3'>
        <div className='p-3' style={{borderBottom:'1px solid #F8F8F9'}}>
        <p style={{color:'#0D111B'}} className='mb-1'>Voucher Code</p>
        <Form className=' '  onSubmit={handleVoucher}>
        <InputGroup>
  <Form.Control
    type='text'
    placeholder='Enter voucher code'
    value={voucherCode}
    required
    onChange={(e) => setVoucherCode(e.target.value)}
  />
  {isSuccessful ? (
    <Button className='' variant='danger' onClick={handleRemove}>
      Remove
    </Button>
  ) : (
    <Button  variant={voucherCode !== '' ? 'primary' : 'secondary'} type='submit'>
      Apply
    </Button>
  )}
</InputGroup>

{isSuccessful === true  && <p style={{backgroundColor:'#DCF8D1', color:'#4AB247', fontSize:'10px', maxWidth:'400px'}} className='p-2 my-2'><img src={greenTag}/>&nbsp;Code valid! - {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucherDiscount)} off your order.</p> }
        {isSuccessful === false  && <p style={{backgroundColor:'#FCE5D0', color:'#CE371C', fontSize:'10px', width:'150px'}} className='p-2 my-2'><img src={redTag}/> &nbsp;Code invalid!</p>}
       
        </Form>
        </div>
     
        <p className='my-2 pb-0 ms-3 'style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', textDecoration:"underline", cursor:"pointer"}} onClick={()=> {setShowVoucherCard(false);setShowPromoCard(true);setIsSuccessful(null);setVoucherCode('');setVoucherDiscount(0)}}>Click here to use promo code to checkout</p>
      </Card>}



            <Card style={{border:'none'}} className='p-3 mx-3 mb-5'>
            <h6 className='mb-4'style={{color:'#0D111B', fontWeight:'700'}}>Payment</h6>
             <div className='d-flex flex-column gap-0' style={{borderBottom:'1px solid #F8F8F9'}}>
            
             <div className='d-flex justify-content-between'>
               <p style={{color:'#667085'}}>Subtotal <span style={{color:'#4F4F4F'}}></span></p>
               {voucherDiscount > 0 ?
               <div className='d-flex flex-column gap-0 mb-1 align-items-end'><p className='mb-0 pb-0' style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subTotal)}</p>
                <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px'}}>(Voucher Discount deducted)</p></div> 
                :
               <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</p>}
             </div>
             

             {!isEditing && (
    <div className='d-flex justify-content-between'>
      <p style={{color:'#667085'}}>{additionalFee.type}</p>

      <div className='d-flex flex-column gap-0 align-items-end'>
    {additionalFee.amount === 0 && discount === 0 ?  <p className='mb-0 pb-0 'style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit'}}>Free delivery</p>
    :
    
      <p className='mb-0 pb-0' style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(additionalFee.amount)}</p>
    }



             
    {promoCode && deliveryOption === 'delivery' && discountDescription === 'free delivery' && discount !== 0 &&  <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px'}}>(Discount deducted)</p>}
    </div>
    </div>
  )}

{ referData?.totalReferralDiscounts >= 10 &&  <div className='d-flex justify-content-between'>
<p style={{color:'#667085'}}>Referral Discount <span style={{color:'#4F4F4F'}}></span></p>
             
             <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>-{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(discount)}</p>
             
               
             
             </div>}


 

             {promoCode && discountDescription !== 'free delivery' && discount !== 0 &&  <div className='d-flex justify-content-between'>
               <p style={{color:'#667085'}}>Discount <span style={{color:'#4F4F4F'}}></span></p>
             
               <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>-{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(discount)}</p>
               
             
             </div>}

             {selectedCategories.length > 0 && (
        <div className='d-flex flex-column gap-0'>
          <p className='mb-2' style={{ color: '#0D111B', fontWeight: '600' }}>Extra Services</p>
          {selectedCategories.map((category, index) => (
            <div className='d-flex justify-content-between' key={index}>
              <p style={{ color: '#667085' }}>{category} <span style={{ color: '#4F4F4F' }}></span></p>
              <p style={{ color: '#4F4F4F', fontWeight: '500', fontFamily: 'Outfit' }}>
                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(instructionFees[category].fee)}
              </p>
            </div>
          ))}
        </div>
      )}

             </div>
             <div className='py-2'>
               <div className='d-flex justify-content-between'>
                 <h6 style={{color:'#353542', fontWeight:'700'}}> Grand Total</h6>
                 <p style={{color:'#353542', fontWeight:'700', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(grandTotal)}</p>
               </div>
             </div>
             <div className='pt-2'>
             <div className='d-flex gap-1 align-items-center mt-1'>
              <Form.Check type='checkbox'   onChange={() => setChecked(!checked)}
                value={checked} required
   /> <p className='my-auto'style={{color:'#4F4F4F',fontWeight:'500'}}>Please check to acknowledge our <a href='/terms' style={{color:'#469B12'}}> Terms of Use</a> and <a href='/privacy-policy' style={{color:'#469B12'}}> Privacy Policy</a> </p>
              </div>
              <div className='mt-3 px-3 pb-5 mb-5'>
              <Button   disabled={isPaymentDisabled} onClick={handleSubmitOrder} className='py-2 w-100' style={{backgroundColor: isPaymentDisabled ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}}>Pay {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(grandTotal)}</Button>
              </div>
             
              
             </div>
           </Card></>)}
           </>)}
      {!showDeliveryForm && (<>
      
        <Card style={{backgroundColor:'#FCFCFC', borderRadius:'0px', border:'none'}} className='my-5'>
              <div className='py-3 '>
              <div className='px-3' style={{borderBottom:'1px solid #D0D9E3'}}> 
              <div className='d-flex gap-3 align-items-center pb-3' > 
              <img src={arrowLeft} onClick={()=> navigate(-1)}/>
       <h6 className='my-auto'style={{color:'#0D111B', fontWeight:'700'}}>Order Review</h6>
      </div>
      <p style={{color:'#000000'}}>Please carefully review your orders before you proceed to make payment.</p>
      </div>
              </div>
              {additionalAmountNeeded > 0 && (
              <div style={{border:'1px solid #469B12', borderLeft:'2px solid #469B12', backgroundColor:'rgba(70, 155, 18, 0.1)'}} className='p-2 my-3 mx-3 '>
   <p className="my-auto" style={{color:'#469B12'}}>
      Add items worth at least <span style={{fontWeight:'700'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(additionalAmountNeeded)}</span> to reach the  <span style={{fontWeight:'700'}}>{minOrderAmount > 0 ? new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(minOrderAmount): '₦12,000'}</span> minimum basket amount.
    </p>
    </div>
  )}
              <div className='mt-3 px-3'>
              {cartItems.map((item) => (
              <Card key={item.id} style={{border:'none',}} className='mb-4'>
                <div className='d-flex gap-3 align-items-center py-2' style={{borderBottom:'1px solid #fcfcfc'}}>
                  <img src={item?.productImage} style={{width:'70px', height:'70px', borderRadius:'20px'}}className='ms-3'/>
                  <div className='me-3 mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'700', color:'#344054'}}>{item?.productName}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'700'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.price)}</p>

             {item?.OldPrice && (<p className='' style={{color:'#dfdcdc',textDecoration:'line-through', fontFamily:'Outfit', fontWeight:'500'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.OldPrice)}</p>)}
             

         </div>
                  </div>
                </div>
                 <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
                  <div className='d-flex gap-3 align-items-center'>
                  <div onClick={() => handleRemoveItem(item.id)} className='d-flex gap-1 align-items-center'>  <img src={trash} alt='trash'/><p  style={{color:'#394357'}}className='my-auto'> Delete</p></div>
                  <div className='d-flex gap-1 align-items-center'>  
                  <i
                  onClick={() => toggleFill(item.id)}
                  className={`bi ${filledHearts[item.id] ? 'bi-heart-fill' : 'bi-heart'}`}
                  style={{
                    fontSize: '1rem',
                    color: filledHearts[item.id] ? '#469B12' : '#394357', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                ></i> 
                 <p className='my-auto' style={{color: filledHearts[item.id] ? '#469B12' : '#394357'}}> {/* Green if in wishlist, black otherwise */}
    {filledHearts[item.id] ? 'Added to Wishlist' : 'Add to Wishlist'}
  </p>
                   </div>
                   </div>
                  
       <div className="btn-group btn-group-sm gap-1" >
         <Button variant='outline-secondary' disabled={item?.quantity === 1} onClick={() => handleDecreaseQuantityInCart(item.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'24px', height:'24px'}}>-</Button>
         <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'24px', height:'24px', fontWeight:'700'}}>{item.quantity}</Button>
         <Button variant='outline-secondary' onClick={() => handleIncreaseQuantityInCart(item.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'24px', height:'24px'}}>+</Button>
       </div>
    
     </div>
                
                 
                
              </Card>
            ))}
            </div>

            </Card>

            <div className='mx-5 mb-5 pb-5'>
            <Button
              onClick={handleContinue}
              className='w-100 p-md-3'
              disabled={!isContinueButtonEnabled()}
              style={{
                backgroundColor: isContinueButtonEnabled() ? '#469B12' : 'rgba(70, 155, 18, 0.2)',
                color: '#F6F6F6',
                boxShadow: '0px 4px 4px 0px #0E35BF40'
              }}
            >
              Continue
            </Button>
            </div></>)}
  
          </div>): (
       <div style={{backgroundColor:'#F4F4F5'}} className='px-5 py-5'>
       <div className='px-xxl-5 mx-xxl-5'>
        
        <Row>
          <Col xs={12} md={5}>
            <Card style={{backgroundColor:'#FCFCFC', borderRadius:'0px', border:'none'}}>
              <div className='py-3 '>
              <div className='px-3' style={{borderBottom:'1px solid #D0D9E3'}}> 
              <div className='d-flex gap-3 align-items-center pb-3' > 
              <img src={arrowLeft} onClick={handleShow}/>
       <h6 className='my-auto'style={{color:'#0D111B', fontWeight:'700'}}>Order Review</h6>
      </div>
      <p style={{color:'#353542'}}>{totalItems}  {totalItems === 1 ? 'item' : 'items'} in card</p>
      </div>
              </div>
              {additionalAmountNeeded > 0 && (
              <div style={{border:'1px solid #469B12', borderLeft:'2px solid #469B12', backgroundColor:'rgba(70, 155, 18, 0.1)'}} className='p-2 my-3 mx-3 '>
    <p className="my-auto" style={{color:'#469B12'}}>
      Add items worth at least <span style={{fontWeight:'700'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(additionalAmountNeeded)}</span> to reach the  <span style={{fontWeight:'700'}}>{minOrderAmount !== 0 ? new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(minOrderAmount): '₦12,000'}</span> minimum basket amount.
    </p>
    </div>
  )}
              <div className='my-3 px-3'>
              {cartItems.map((item) => (
              <Card key={item.id} style={{border:'none',}} className='mb-4'>
                <div className='d-flex gap-3 align-items-center py-2' style={{borderBottom:'1px solid #fcfcfc'}}>
                  <img src={item?.productImage} style={{width:'70px', height:'70px', borderRadius:'20px'}}className='ms-3'/>
                  <div className='me-3 mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'700', color:'#344054'}}>{item?.productName}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'700'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.price)}</p>

             {item?.OldPrice && (<p className='' style={{color:'#dfdcdc',textDecoration:'line-through', fontFamily:'Outfit', fontWeight:'500'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.OldPrice)}</p>)}
             

         </div>
                  </div>
                </div>
                 <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
                  <div className='d-flex gap-3 align-items-center'>
                  <div onClick={() => handleRemoveItem(item.id)} className='d-flex gap-1 align-items-center'> <img src={trash} alt='trash'/><p  style={{color:'#394357'}}className='my-auto'> Delete</p></div>
                  <div className='d-flex gap-1 align-items-center'>  
                  <i
                  onClick={() => toggleFill(item.id)}
                  className={`bi ${filledHearts[item.id] ? 'bi-heart-fill' : 'bi-heart'}`}
                  style={{
                    fontSize: '0.85rem',
                    color: filledHearts[item.id] ? '#469B12' : '#394357', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                ></i> 
                 <p className='my-auto' style={{color: filledHearts[item.id] ? '#469B12' : '#394357'}}> {/* Green if in wishlist, black otherwise */}
    {filledHearts[item.id] ? 'Added to Wishlist' : 'Add to Wishlist'}
  </p>
                   </div>
                   </div>
                  
       <div className="btn-group btn-group-sm gap-1" >
         <Button variant='outline-secondary' disabled={item?.quantity === 1} onClick={() => handleDecreaseQuantityInCart(item.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'24px', height:'24px'}}>-</Button>
         <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'24px', height:'24px', fontWeight:'700'}}>{item.quantity}</Button>
         <Button variant='outline-secondary' onClick={() => handleIncreaseQuantityInCart(item.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'24px', height:'24px'}}>+</Button>
       </div>
    
     </div>
                
                 
                
              </Card>
            ))}
             
            </div>

            </Card>
           
          </Col>
          <Col xs={12} md={7}>
            <div className='mb-4'>
            <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}>Delivery Options</h6>
            <p>You can pickup your orders from our store, have it delivered to you on our weekly Tuesday group shopping dates or opt for the next day delivery option.</p>
            <div className='d-flex gap-3'>
            <div className='d-flex'>
       
          <Form.Check
            type="radio"
            name="deliveryOption"
            value="pickup"
            label="In-Store Pickup"
            checked={deliveryOption === 'pickup'}
            onChange={handleDeliveryOptionChange}
          />
        
      </div>
      
      <div className='d-flex'>
       
          <Form.Check
            type="radio"
            name="deliveryOption"
            value="delivery"
            label='Weekly Delivery'
            checked={deliveryOption === 'delivery'}
            onChange={handleDeliveryOptionChange}
          />
        
      </div>
      <div className='d-flex'>
       
       <Form.Check
         type="radio"
         name="deliveryOption"
         value="immediate"
         label='Next Day Delivery'
         checked={deliveryOption === 'immediate'}
         onChange={handleDeliveryOptionChange}
       />
     
   </div>
            </div>
            </div>
            {deliveryOption && 
          <Card className='p-3 mb-4 ' style={{border:'none'}}>
             {deliveryOption === 'pickup' && <div>
              <h6 style={{color:'#0D111B', fontWeight:'700'}}>Pickup Address</h6>
              <p style={{color:'#353542', fontWeight:'400'}}>No. 2, Ileri-Oluwa Crescent, Ifako-Ijaiye, via
            College Road, Oyemekun Bus-Stop</p>
             </div>
            
            }
          <div className='d-flex gap-3'>
          {deliveryOption !== 'pickup' ? 
      <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}>Delivery Details</h6> : <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}> Details</h6>}
      {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEditDelivery}>Edit</p>
      )}
    </div>

            <Form className='my-3' onSubmit={handleSaveDelivery}>
            {deliveryOption !== 'pickup' && (<>
           

             <Row>
             <Col xs={6}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>State/Province</p>
          
            <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.state || ''}
        name="state"
        required disabled={!isEditing}
      > <option value="" disabled>Select a state</option>
    
        {/* <option value='Lagos'>Lagos</option>
        <option value='Ogun'>Ogun</option> */}
 {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}
      </Form.Select>

            </Form.Group>
              </Col>
              <Col xs={6}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>Delivery Area {isEditing && !formData?.LGA && <span>(Please select a state first)</span>}</p>
          
            <Select
         formatOptionLabel={formatOptionLabel}
          options={lgaOptions}
          onInputChange={handleInputChange}
          onChange={handleSelectChange}
         
        required
           value={lgaOptions.find(option => option.value === formData.LGA) || ''}

          name="LGA"
          isDisabled={!isEditing}
          isClearable={true}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 2000, // Ensure the dropdown has a high z-index
            }),
            menuList: (provided) => ({
              ...provided,
              zIndex: 2000, // Ensure the dropdown options have a high z-index
            }),
          }}
          
        />

            </Form.Group>
              </Col>
             </Row>
             <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>Street Address {isEditing && !formData?.LGA && <span>(Please select a state first)</span>}</p>
            <div className="input-group">
            <Form.Control
  ref={streetAddressAutoCompleteInput}
  name="streetAddress"
  value={formData?.streetAddress}
  placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
  onChange={handleStreetChange}
  style={{ border: '1px solid #D7D7D7' }}
  required
  disabled={!isEditing}
/>

  {isEditing && formData.streetAddress && (
   <Button variant='outline-secondary'  onClick={() => setFormData({ ...formData, streetAddress: '' })}  style={{ zIndex: 1000 }}>
    X</Button>
  )}
</div>
<p className='text-secondary mt-1' style={{fontSize:'12px'}}>Please ensure you enter the correct delivery address. Additional costs associated with a wrong delivery address will be your responsibility. Thank you!</p>


            </Form.Group>

            <Form.Group className='d-flex gap-1 align-items-center mb-3'>
            <Form.Check
    type='checkbox'
    name='isDefaultAddress'
    checked={formData.isDefaultAddress}
    onChange={handleChange} disabled={!isEditing}
  /> <p className='my-auto'style={{color:'#4F4F4F',}}>Set as default address</p>
              </Form.Group>
            </>)}
            
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>Phone</p>
            <Form.Control
  type='text'
  placeholder='Enter phone number'
  style={{border:'1px solid #D7D7D7'}}
  value={formData.phoneNumber}
  onChange={(event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    if (numericValue.length <= 11) {
      setFormData({ ...formData, phoneNumber: numericValue });
    }

    
    if (numericValue.length === 11 && !isNigerianPhoneNumber(numericValue)) {
      setError({...error, phoneNumber: 'Please enter a valid 11-digit Nigerian phone number.'});
    } else {
    
      setError({...error, phoneNumber: ''});
    }
  }}
  readOnly={!isEditing}
/>
{error.phoneNumber && <div className="text-danger" style={{fontSize:'12px'}}>{error.phoneNumber}</div>}

            </Form.Group>
            {deliveryOption !== 'pickup' && (
            <Form.Group className='d-flex gap-1 align-items-center mb-3'>
              <Form.Check type='checkbox'
    name='isSchoolOrBusinessAddress'
    checked={formData.isSchoolOrBusinessAddress}
    onChange={handleChange} disabled={!isEditing}/> <p className='my-auto'style={{color:'#4F4F4F',}}>Please check if this is either a school or business address</p>
              </Form.Group>)}

              <Form.Group className=' mb-3'>
              {deliveryOption !== 'pickup' ? 
              <p className='mb-1' style={{color:'#5D5D5D'}}>Delivery Date</p>: <p className='mb-1' style={{color:'#5D5D5D'}}>Pickup Date</p>}
              {deliveryOption === 'immediate' && immediateMessage ? (
    <p className='mb-1 text-danger' style={{color:'#5D5D5D'}}>{immediateMessage}</p>
  ) : (
    <div className="custom-select-wrapper">
      <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.deliveryDate}
        name="deliveryDate"
        required disabled={!isEditing}
      >
        <option value="" disabled>Select a date</option>
        {selectOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Select>
      <FaRegCalendarAlt className="select-icon" />
    </div>)}
              </Form.Group>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>Delivery Comment (optional)</p>
         
            <Form.Control
  type='text' placeholder='Add notes/comments to your order(s)' rows={3} className=' p-2 w-100' style={{border:'1px solid #D7D7D7'}}value={comment} onChange={(e)=> setComment(e.target.value)}
  disabled={!isEditing}
/>



            </Form.Group>

              {isEditing && (
        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
      )}
            </Form>
            </Card>
          }

{hasShoppingInstructions && (
   <Card style={{ border: 'none' }} className='p-3 mb-4'>
   <h6 className='mb-4' style={{ color: '#0D111B', fontWeight: '700' }}>Shopping Instructions</h6>
   <div className='d-flex flex-column gap-3'>
     {Object.keys(instructionFees).map((category, index) => (
       <div key={index}>
         <div className='d-flex align-items-center gap-4'>
           <Form.Group className='d-flex align-items-center gap-2'>
             <Form.Check
               type='checkbox'
               className='d-flex align-items-center gap-2'
               checked={selectedCategories.includes(category)}
               onChange={() => {
                 if (selectedItems[category]?.length > 0) {
                   handleCategoryCheckboxChange(category);
                   setShowAlert(prev => ({ ...prev, [category]: '' }));
                 } else {
                   setShowAlert(prev => ({ ...prev, [category]: 'Please select at least one item first.' }));
                 }
               }}
             />
             <h6 className='my-auto'>{category}</h6>
           </Form.Group>

           <div
             className='shopping-container w-100 justify-content-between align-items-center'
             style={{
               cursor: 'pointer',
               border: '1px solid #ced4da',
               borderRadius: '8px',
               padding: '0.35rem 0.75rem',
               minHeight: '38px',
               gap: '5px'
             }}
             onClick={(e) => handleToggleDropdown(category, e.currentTarget)}
           >
             {selectedItems[category]?.length > 0 ? (
               <div className='item-container w-100'>
                 {selectedItems[category].map((item, index) => (
                   <Button
                     variant='outline-secondary'
                     style={{
                       color: '#11142D',
                       border: '1px solid #E7E8EA',
                       fontWeight: '500',
                       fontSize: '11px',
                       boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
                     }}
                     key={index}
                     onClick={(e) => {
                       e.stopPropagation();
                       handleRemoveShoppingItem(item, category);
                     }}
                   >
                     {item} &nbsp; X
                   </Button>
                 ))}
               </div>
             ) : (
               <span style={{ color: '212529BF', fontWeight: '400', fontSize: '12px' }} className='my-auto align-iems-center'>Select items for {category}</span>
             )}
             <i className='bi bi-chevron-down'></i>
           </div>

           <Overlay
             show={showDropdown[category]}
             target={target}
             placement="bottom"
             rootClose={true}
             onHide={() => setShowDropdown(prev => ({ ...prev, [category]: false }))}
           >
             <Popover id={`popover-positioned-bottom-${category}`}>
               <Popover.Header as="h5">{`Select the item for ${category}`}</Popover.Header>
               <Popover.Body>
                 {instructionFees[category].items.map((item, index) => (
                   <ListGroup.Item key={index} as='div' className='ms-1 py-2'>
                     <Form.Check
                       type='checkbox'
                       label={item.productName}
                       checked={selectedItems[category]?.includes(item.productName)}
                       onChange={() => handleSelectItem(item, category)}
                       className='d-flex align-items-center gap-2'
                     />
                   </ListGroup.Item>
                 ))}
               </Popover.Body>
             </Popover>
           </Overlay>
         </div>

         {showAlert[category] && (
           <div className="text-danger" style={{ fontSize: '12px' }}>{showAlert[category]}</div>
         )}
       </div>
     ))}
   </div>
 </Card>

)}

{loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (<>
     {
      showPromoCard &&
     
        <Card style={{border:'none'}} className='mb-3'>
        <div className='p-3' style={{borderBottom:'1px solid #F8F8F9'}}>
        <p style={{color:'#0D111B'}} className='mb-1'>Promotional Coupon</p>
        <Form className=' '  onSubmit={handlePromotions}>
        <InputGroup>
  <Form.Control
    type='text'
    placeholder='Enter promotional code'
    value={promoCode}
    required
    onChange={(e) => setPromoCode(e.target.value)}
  />
  {isSuccessful ? (
    <Button className='' variant='danger' onClick={handleRemove}>
      Remove
    </Button>
  ) : (
    <Button  variant={promoCode !== '' ? 'primary' : 'secondary'} type='submit'>
      Apply
    </Button>
  )}
</InputGroup>

{isSuccessful === true  && <p style={{backgroundColor:'#DCF8D1', color:'#4AB247', fontSize:'10px', maxWidth:'400px'}} className='p-2 my-2'><img src={greenTag}/>&nbsp;Coupon code valid! {discountDescription === 'free delivery' ? `- ${new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(discount)} off shipping fee. For weekly delivery orders only` : ''}</p> }
        {isSuccessful === false  && <p style={{backgroundColor:'#FCE5D0', color:'#CE371C', fontSize:'10px', width:'150px'}} className='p-2 my-2'><img src={redTag}/> &nbsp;Coupon code invalid!</p>}
       
        </Form>
        </div>
        <p className='my-2 pb-0 ms-3'style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', textDecoration:"underline", cursor:"pointer"}}  onClick={()=> {setShowPromoCard(false);setShowVoucherCard(true);setIsSuccessful(null);setPromoCode('');setDiscount(0)}}>Click here to use voucher code to checkout</p>
      </Card>}
      {
      showVoucherCard && 
     
        <Card style={{border:'none'}} className='mb-3'>
        <div className='p-3' style={{borderBottom:'1px solid #F8F8F9'}}>
        <p style={{color:'#0D111B'}} className='mb-1'>Voucher Code</p>
        <Form className=' '  onSubmit={handleVoucher}>
        <InputGroup>
  <Form.Control
    type='text'
    placeholder='Enter voucher code'
    value={voucherCode}
    required
    onChange={(e) => setVoucherCode(e.target.value)}
  />
  {isSuccessful ? (
    <Button className='' variant='danger' onClick={handleRemove}>
      Remove
    </Button>
  ) : (
    <Button  variant={voucherCode !== '' ? 'primary' : 'secondary'} type='submit'>
      Apply
    </Button>
  )}
</InputGroup>

{isSuccessful === true  && <p style={{backgroundColor:'#DCF8D1', color:'#4AB247', fontSize:'10px', maxWidth:'400px'}} className='p-2 my-2'><img src={greenTag}/>&nbsp;Code valid! - {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(voucherDiscount)} off your order.</p> }
        {isSuccessful === false  && <p style={{backgroundColor:'#FCE5D0', color:'#CE371C', fontSize:'10px', width:'150px'}} className='p-2 my-2'><img src={redTag}/> &nbsp;Code invalid!</p>}
       
        </Form>
        </div>
        {referData?.totalReferralDiscounts === 0 &&
        <p className='my-2 pb-0 ms-3 'style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', textDecoration:"underline", cursor:"pointer"}} onClick={()=> {setShowVoucherCard(false);setShowPromoCard(true);setIsSuccessful(null); setVoucherCode('');setVoucherDiscount(0)}}>Click here to use promo code to checkout</p>}
      </Card>}

          
            <Card style={{border:'none'}} className='p-3'>
            <h6 className='mb-4'style={{color:'#0D111B', fontWeight:'700'}}>Payment</h6>
             <div className='d-flex flex-column gap-0' style={{borderBottom:'1px solid #F8F8F9'}}>
            
             <div className='d-flex justify-content-between'>
               <p style={{color:'#667085'}}>Subtotal <span style={{color:'#4F4F4F'}}></span></p>

               {voucherDiscount > 0 ?
                  <div className='d-flex flex-column gap-0 mb-1 align-items-end'><p className='mb-0 pb-0' style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subTotal)}</p>
                <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px'}}>(Voucher Discount deducted)</p></div> 
                :
               <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</p>}
             </div>
            
            
             {!isEditing && (
    <div className='d-flex justify-content-between'>
      <p style={{color:'#667085'}}>{additionalFee.type}</p>

      <div className='d-flex flex-column gap-0 align-items-end'>
    {additionalFee.amount === 0 && discount === 0 ?  <p className='mb-0 pb-0 'style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit'}}>Free delivery</p>
    :
    
      <p className='mb-0 pb-0' style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(additionalFee.amount)}</p>
    }

    {!isEditing && promoCode && deliveryOption === 'delivery' && discountDescription === 'free delivery' && discount !== 0 &&  <p className='mb-0 pb-0' style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit', fontSize:'11px'}}>(Discount deducted)</p>}
    </div>
    </div>
  )}


{ referData?.totalReferralDiscounts >= 10 &&  <div className='d-flex justify-content-between'>
<p style={{color:'#667085'}}>Referral Discount <span style={{color:'#4F4F4F'}}></span></p>
             
             <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>-{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(discount)}</p>
             
               
             
             </div>}

            
 
 

             {promoCode && discountDescription !== 'free delivery' && discount !== 0 &&  <div className='d-flex justify-content-between'>
               <p style={{color:'#667085'}}>Discount <span style={{color:'#4F4F4F'}}></span></p>
             
               <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>-{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(discount)}</p>
               
             
             </div>}

             {selectedCategories.length > 0 && (
        <div className='d-flex flex-column gap-0'>
          <p className='mb-2' style={{ color: '#0D111B', fontWeight: '600' }}>Extra Services</p>
          {selectedCategories.map((category, index) => (
            <div className='d-flex justify-content-between' key={index}>
              <p style={{ color: '#667085' }}>{category} <span style={{ color: '#4F4F4F' }}></span></p>
              <p style={{ color: '#4F4F4F', fontWeight: '500', fontFamily: 'Outfit' }}>
                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(instructionFees[category].fee)}
              </p>
            </div>
          ))}
        </div>
      )}



             </div>
             <div className='py-2'>
               <div className='d-flex justify-content-between'>
                 <h6 style={{color:'#353542', fontWeight:'700'}}> Grand Total</h6>

                 <p style={{color:'#353542', fontWeight:'700', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(grandTotal)}</p>
               </div>
             </div>
             <div className='pt-2' >
            
             <div className='d-flex gap-1 align-items-center mt-1'>
              <Form.Check type='checkbox'   onChange={() => setChecked(!checked)}
                value={checked} required
   /> <p className='my-auto'style={{color:'#4F4F4F',fontWeight:'500'}}>Please check to acknowledge our <a href='/terms' style={{color:'#469B12'}}> Terms of Use</a> and <a href='/privacy-policy' style={{color:'#469B12'}}> Privacy Policy</a> </p>
              </div>
              
              <div className='my-3 px-3'>
              <Button   disabled={isPaymentDisabled} onClick={handleSubmitOrder} className='py-2 w-100' style={{backgroundColor: isPaymentDisabled ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}}>Pay {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(grandTotal)}</Button>
              </div>
              
             </div>
           </Card>
           </>)}
          </Col>
        </Row>
           

           
       

  
          </div>
    </div>
    )}

   <Waitlist showWaitlist={showWaitlist} setShowWaitlist={setShowWaitlist}/>
   
    </>
   
  )
}

export default OrderSummary


