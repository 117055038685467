import  {useState, useEffect} from 'react'
import { Button, Card, Modal, Row, Col } from 'react-bootstrap'
import shieldTick from '../../assets/shield-tick.svg'
import truckTick from '../../assets/truck-tick.svg'
import love from '../../assets/favourite.svg'
import share from '../../assets/share.svg'
import arrowDown from '../../assets/arrow-down.svg'
import arrowLeft from '../../assets/arrow-left.svg'
import arrowRight from '../../assets/arrow-right.svg'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar'
import { trackEvent } from '../../mixpanel'
import orderRoute from '../../services/orderRoute'
import { useAppContext } from '../../AppContext'
import ShareModal from '../../components/Modal/ShareModal'
// import { trackAddToWishlist, trackViewContent } from '../../App'
import { Link, useLocation } from 'react-router-dom'
import { localStorageGetItem, localStorageSetItem } from '../../App';
import { useParams } from 'react-router-dom'
import productRoute from '../../services/productRoute'

const ProductPage = () => {
  const location = useLocation();
  const {productId} = useParams();
  const {updateWishlist, getWishlist,updateSingleWishlist}= orderRoute();
  const{showToast, wishlistItems,setWishlistItems,updatedWishlist, setApiWishlistItems, apiWishlistItems,cartItems, setCartItems,updateCart, isMobile}= useAppContext();

  const [loading,setLoading]=useState(false);
  const [isHeartFilled, setIsHeartFilled] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const userData = getParsedLocalStorageItem('userData');
  const userToken = localStorageGetItem('userToken');
  const [showShareModal, setShowShareModal] = useState(false);
  const token = localStorageGetItem
('userToken');
const {getGlobalProducts,getSingleProduct}= productRoute();
  const isLoggedIn = !!userData && !!token;

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [globalProducts, setGlobalProducts] = useState([]);


  useEffect(()=>{
   
    getGlobalProducts(
      setLoading, showToast, setGlobalProducts, 
     
    )
  
},[])
useEffect(()=>{
   
  getSingleProduct(
    productId,
    setLoading,
    showToast,
   setSelectedProduct
  )

},[])

useEffect(() => {
  if (selectedProduct) {
    const foundInCart = cartItems.find(item => item?.id === selectedProduct?.id);
    const productQuantity = foundInCart ? foundInCart?.quantity : 0;
    setSelectedProduct(prev => ({ ...prev, quantity: productQuantity }));
  }
}, [cartItems,selectedProduct?.id]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const toggleAccordion2 = () => {
      setIsOpen2(!isOpen2);
  };

    
  useEffect(() => {
    if (isLoggedIn) {
      // Fetch the wishlist if logged in
      getWishlist(setLoading, showToast, setApiWishlistItems);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    const checkWishlistStatus = () => {

      if(isLoggedIn){
     
        const isProductInWishlist = apiWishlistItems?.some(item => item?.product?.id === selectedProduct?.id);
        setIsHeartFilled(isProductInWishlist);
      

      }else{
        const wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
        // Determine if the selected product is in the wishlist
        const isProductInWishlist = wishlist?.some(item => item?.product?.id === selectedProduct?.id);
        setIsHeartFilled(isProductInWishlist);
      
      };
      }
     
     
  
    // Call the function if selectedProduct is defined
    if (selectedProduct) {
      checkWishlistStatus();
    }
  }, [selectedProduct, apiWishlistItems]);

  const handleIncreaseQuantity = (id, increment = 1, replace) => {
    let newCartItems = [...cartItems];
    const cartIndex = newCartItems.findIndex(item => item.id === id);
  
    // Find the product in the global products array
    const product = globalProducts.find(p => p.id === id);
    if (!product) {
        console.log('Error', `Product with ID ${id} not found`);
        return;
    }
    let initialQuantity = 0;
    if (cartIndex !== -1) {
      initialQuantity = newCartItems[cartIndex].quantity;
    }
  
    // Check if the product is already in the cart
    if (cartIndex !== -1) {
        // Check if increasing quantity exceeds available stock
        if (newCartItems[cartIndex].quantity + increment > product.quantityAvailable) {
            showToast({toastText:'This product has limited stock!', isSuccess:false});
            return;
        }
        // Update the quantity in the cart
        newCartItems[cartIndex].quantity = replace ? increment : newCartItems[cartIndex].quantity + increment;
    } else {
        // Check if adding this quantity would exceed available stock
        if (product.quantity + increment > product.quantityAvailable) {
            showToast({toastText:'This product has limited stock!', isSuccess:false});
            return;
        }
        // Add the product to the cart
        newCartItems.unshift({ ...product, quantity: increment });
    }
  
    // Update cart items state
    setCartItems(newCartItems);
    updateCart(newCartItems);
 
    // Track the event of adding a product to the cart
    const addedProduct = globalProducts.find(p => p.id === id);
    if (addedProduct) {
      if (initialQuantity === 0) {
        trackEvent(`${addedProduct.productName} Added to Cart`, {
          'Product ID': addedProduct.id,
          'Product Name': addedProduct.productName,
          'Category Name': addedProduct.category,
          'Quantity Added': increment,
          'User': userData?.email || 'Anonymous',
          'Location': 'Product page'
        });
      } else {
        trackEvent(`${addedProduct.productName} Increased in Cart`, {
          'Product ID': addedProduct.id,
          'Product Name': addedProduct.productName,
          'Category Name': addedProduct.category,
          'New Quantity': newCartItems[cartIndex].quantity,
          'User': userData?.email || 'Anonymous',
          'Location': 'Product page'
        });
      }
    }
    if (selectedProduct?.id === id) {
      setSelectedProduct(prev => ({ ...prev, quantity: newCartItems[cartIndex]?.quantity || increment }));
    }
  };
  
    const handleDecreaseQuantity = (id) => {
      const productBeforeUpdate = cartItems.find(item => item?.id === id);
      let newCartItems = [...cartItems];
      const cartIndex = newCartItems.findIndex(item => item.id === id);
    
      // Check if the product is in the cart
      if (cartIndex !== -1) {
        // Decrease quantity or remove item if quantity is 1
        if (newCartItems[cartIndex].quantity > 1) {
          newCartItems[cartIndex] = { ...newCartItems[cartIndex], quantity: newCartItems[cartIndex].quantity - 1 };
       
        trackEvent(`${newCartItems[cartIndex].productName} Quantity Decreased in Cart`, {
          'Product ID': newCartItems[cartIndex].id,
          'Product Name': newCartItems[cartIndex].productName,
          'Category Name': newCartItems[cartIndex].category,
          'New Quantity': newCartItems[cartIndex].quantity,
          'User': userData?.email || 'Anonymous',
          'Location': 'Product page'
        });
        } else {
          // Remove item from cart if quantity is 1
          const removedProduct = newCartItems[cartIndex];
          newCartItems.splice(cartIndex, 1);
          
          trackEvent(`${productBeforeUpdate.productName} Removed from Cart`, {
            'Product ID': productBeforeUpdate.id,
            'Product Name': productBeforeUpdate.productName,
            'Category Name': productBeforeUpdate.category,
            'Location': 'Product page',
            // Here, we know the quantity reached 0, leading to its removal
            'User': userData?.email || 'Anonymous',
          });
        }
      } else {
          console.log('Error', `Product with ID ${id} not found in the cart`);
          return; // Exit function as there is no item to decrease
      }
    
      setCartItems(newCartItems);
    
      
      updateCart(newCartItems); 
      if (selectedProduct?.id === id) {
        setSelectedProduct(prev => ({ ...prev, quantity: newCartItems[cartIndex]?.quantity || 0 }));
      }
  };
  
  
  const toggleFill = () => {
    const productId= selectedProduct?.id
    if(isLoggedIn){
      updateSingleWishlist (
        productId, 
        showToast, 
        isHeartFilled,
        setIsHeartFilled, apiWishlistItems,
        setApiWishlistItems, 
        globalProducts
      )
    } else {
      const wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
      let toastText = '';
    if (isHeartFilled) {
      
      const newWishlist = wishlist.filter(item => item.product?.id !== selectedProduct.id);
      localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlist));
      setIsHeartFilled(false);
      updatedWishlist(newWishlist)
      setWishlistItems(newWishlist); 
      toastText = 'Item removed from Wishlist!';
    } else {
     
      const newWishlist = [{product:selectedProduct},...wishlist ];
      localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlist));
      setIsHeartFilled(true);
      updatedWishlist(newWishlist)
      setWishlistItems(newWishlist);
      toastText = 'Item added to Wishlist!';
      const product = globalProducts.find(p => p.id === productId);
      // trackAddToWishlist(productId, product.productName);
    }
    showToast({
      toastText: toastText,
      isSuccess: true
    });
    const product = globalProducts.find(p => p.id === productId);
    trackEvent(!isHeartFilled ? `${product?.productName} Added to Wishlist` : `${product?.productName} Removed from Wishlist`, {
      'Product ID': productId,
      'Product Name': product?.productName,
      'Category Name': product?.category,
      'User': userData?.email || 'Anonymous',
   
    });
    }
    
   
  };
  
  const shareViaWebShareApi = async (url) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Share this link',
          url: url,
        });
       
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
    
      setShowShareModal(true);
    }
  };


  const handleShareClick = () => {
    if(isMobile){
      if (navigator.share) {
      
        const url=window.location.href
        shareViaWebShareApi(url);
      } else {
      
        setShowShareModal(true);
      }
    }else {
    
      setShowShareModal(true);
    }
   
  };
  


   if (loading) {
    return <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>;
  }

    return (
      <>
      <section className='px-3 px-xxl-5 mx-lg-5'>
      <div className='my-5 mx-xl-5 pt-5 pt-md-0 pb-5'> 
            <div className="d-flex gap-2 align-items-center mb-0 mb-md-4" >  
  <Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-right" ></i></p></Link>
  <p className='' style={{color:'#1D2939', fontWeight:'600'}} >              {selectedProduct?.productName} </p>
    
      </div>
        <Row>
                <Col xs={12} lg={5} className=' position-relative mb-0 p-0  '>
                    
                    <Card.Img src={selectedProduct?.productImage} style={{maxHeight:'500px', objectFit:'cover'}} className='mb-0 my-0'  alt='product image'/>


              <div className='position-absolute d-flex flex-column gap-2' style={{top:'5%', left:'5%'}}>
              {/* <div className='p-2 bg-white d-flex d-md-none  align-items-center' style={{borderRadius:'20px', fontWeight:'700', boxShadow:'0px 1px 2px 0px #0000001A'}} onClick={handleCloseModal}> <img src={arrowLeft}/></div> */}
            
              </div>
                    
                    
                </Col>
                <Col xs={12} lg={6} className='mt-3 mt-sm-0 pt-0 ps-lg-4 content' >
                  <div className='d-flex justify-content-between gap-2 align-items-center mb-3 '>
                    <div>
                        <h4 style={{color:'#1D2939'}}>{selectedProduct?.productName}</h4>
                        <div className='d-flex gap-2'>
                        <h3 style={{fontWeight:'600'}} className='my-auto price'> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(selectedProduct?.price)}</h3>
                        {selectedProduct?.OldPrice && (<p className='my-auto' style={{color:'#475467',textDecoration:'line-through'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(selectedProduct?.OldPrice)}</p>)}
                        </div>
                       

                    </div>
                    <div>
                    {selectedProduct?.inSeason && 
                    <div className={selectedProduct?.inSeason ? 'bg-success text-center' : 'bg-danger text-center'}style={{ borderRadius:'100px',width:'75px'}} >
        <span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400',}}>
{selectedProduct?.inSeason ? 'IN SEASON' : 'OFF SEASON'}
</span>

                                     
      </div>}
                  
                    </div>
                   
                  </div>
                  <div className='d-block d-md-none line my-2'></div>
                  {selectedProduct?.description && <div className='pb-1 '>
                    <p style={{letterSpacing:'12%', color:'#98A2B3'}}>DESCRIPTION</p>
                    <p style={{letterSpacing:'1%', color:'#344054'}}>{selectedProduct?.description}</p>
                    <p style={{letterSpacing:'1%', color:'#469B12', fontWeight:'500'}}>Disclaimer: Prices and quantities of items are subject to change due to market fluctuations.</p>
                  </div>}


                  <div className='d-flex justify-content-between gap-3 '>
                  {selectedProduct?.stockLevel === 'out_of_stock' ?  <Button   variant='danger'className='w-100'>Out of Stock</Button>: 
                  selectedProduct?.quantity === 0 ? (
            <Button   onClick={() => handleIncreaseQuantity(selectedProduct.id, 1, true)} className='w-100'>Add to Cart</Button>
          ) : (
            <div className="d-flex align-items-center">
              <Button className='d-flex text-center align-items-center justify-content-center'onClick={() => handleDecreaseQuantity(selectedProduct?.id)} style={{width:'40px'}}>-</Button>
              <span  style={{fontWeight:'700'}} className="mx-3">{selectedProduct?.quantity}</span>
              <Button onClick={() => handleIncreaseQuantity(selectedProduct?.id)} style={{width:'40px'}} className='d-flex text-center align-items-center justify-content-center'>+</Button>
              <span className="ms-2" style={{fontSize:'12px'}}>({selectedProduct?.quantity} {selectedProduct?.quantity > 1 ? 'items' : 'item'} added to cart)</span>
            </div>  
          )}

          
          <div className='d-flex gap-2'>
          <Button variant='outline-secondary' onClick={toggleFill} className='d-flex text-center justify-content-center align-items-center' style={{borderRadius:'8px', borderColor:'#B2BCCA', backgroundColor:'#fff', width:'40px'}}>
  <i className={`bi ${isHeartFilled ? 'bi-heart-fill' : 'bi-heart'}`} style={{ fontSize: '1rem', color: isHeartFilled ? '#469B12' : '#B2BCCA' }}></i>
</Button>

 <Button variant='outline-secondary' onClick={handleShareClick}   className='d-flex text-center justify-content-center align-items-center' style={{borderRadius:'8px', borderColor:'#B2BCCA', backgroundColor:'#fff', width:'40px'}}><img src={share}  alt='share icon' /></Button>
 </div>
                  </div>

                  <div className='mt-3 pb-5 '>
                  <div style={{ borderBottom: '1px solid #EAECF0' }}>
            <div className='d-flex justify-content-between py-3'  onClick={toggleAccordion} >
                <div className='d-flex gap-2 align-items-center'>
                    <img style={{ width: '24px', height: '24px' }} src={shieldTick} alt='shield'/>
                    <p className='my-auto' style={{ fontWeight: '500', color: '#1D2939' }}>Return & Exchange Policy</p>
                </div>
                <Button variant='outline-secondary' style={{border:'none', backgroundColor:'white'}}>
                <img src={isOpen ? arrowDown : arrowRight} alt='toggle'/>
                </Button>
            </div>
            {isOpen && (
                <div>
                    <p style={{color:'#353542'}}>We accept returns for damaged or spoiled items within 12 - 24 hours of delivery. Please contact us immediately if you have any issues with your order. We do not issue refunds unless items are out of stock or damaged.</p>
                </div>
            )}
        </div>
        <div style={{ borderBottom: '1px solid #EAECF0' }}>
            <div className='d-flex justify-content-between py-3'  onClick={toggleAccordion2} >
                <div className='d-flex gap-2 align-items-center'>
                  
                    <img style={{ width: '24px', height: '24px' }} src={truckTick} alt='truck'/>
                    <p className='my-auto' style={{ fontWeight: '500', color: '#1D2939' }}>Shipping & Delivery</p>
                    
                </div>
                <Button variant='outline-secondary' style={{border:'none', backgroundColor:'white'}}>
                <img src={isOpen2 ? arrowDown : arrowRight} alt='toggle'/>
                </Button>
            </div>
            {isOpen2 && (
                <div>
                    <p style={{color:'#353542'}}>Delivery fees vary depending on your location and order amount. You will see the applicable fee displayed at checkout.</p>
                </div>
            )}
        </div>
                  </div>

                </Col>
            </Row>
            </div> 
         
      
      
      </section>
       <ShareModal
       show={showShareModal}
       handleClose={() => setShowShareModal(false)}
       url={window.location.href}
     />
     </>
    );
  };
  

export default ProductPage