

import { useState } from 'react'
import {Image,Form, Button, Card } from 'react-bootstrap'
import homeBg from '../../assets/auth-bg.png'
import logo from '../../assets/virtue-logo-2.png'
import { Link, useNavigate } from 'react-router-dom'
import google from '../../assets/google-icon.svg'
import authRoute from '../../services/authRoute'
import { useAppContext } from '../../AppContext'

const ForgotPassword = () => {
    const {showToast}= useAppContext()
    const [email, setEmail] = useState("");
    const { sendPasswordResetLink } = authRoute();

     
  
    const handleSubmit = (e)=> {
        e.preventDefault();
    
        const body = { 
         
          email: email,
        }
        sendPasswordResetLink(body, showToast)
  
    }

      
  
   

    const handleGoogleLogin = () => {
      
    
     
      window.location = 'https://production-api.virtuesshop.co/auth/google';
    };
 
    
   
  return (
    <> 
     <div style={{backgroundColor:'white', }} className='pt-3 pb-5 d-block d-md-none'>
             
             <div className='login-form pt-3 pb-5 mb-5 px-4'>
              

           <Link className='d-flex justify-content-end mb-2' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
                 <div className='text-center'><Image fluid src={logo} style={{width:'100px'}}/>
                 <h2 className='mt-1 mb-3'>Forgot Password</h2>
                 <p style={{color:'#5D5D5D', fontSize:'12px'}}>Enter the email address associated with your account and we will send you a link to reset your password.</p></div>
              
                   <Form onSubmit={handleSubmit}>
                       <Form.Group className='mb-4'>
                       <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                       <Form.Control
                       type="email"
                       name="email"
                       id="email"
                       className=""
                       placeholder="e.g. user@email.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       required
                     />
                       </Form.Group>
                      
                     
                       <div><Button type='submit' className='btn-primary w-100'>Continue</Button></div>
                       <div className=' text-center mt-2 mb-0 '><p className='mb-1' style={{color:'#393939'}}>Don’t have an account? &nbsp;<Link to='/signup' className=''  style={{fontWeight:'500',color:"#469B12 "}}>Sign up</Link></p></div>
                   </Form>
                   <div className='d-flex flex-row justify-content-center gap-3 align-items-center mb-1'>
            <div className='line'></div>
            <p className='line-p'>OR</p>
            <div className='line'></div>
        </div>
                   
        <div className='d-flex flex-column mt-2 pb-5 mb-5'>
                     
                   <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'600'}} className='w-100 mb-5' onClick={handleGoogleLogin}><img src={google} alt='google'/> &nbsp; Sign in with Google</Button>
                  
                   </div>
                   
                  
            </div>
        
                   
                  
                   </div>
       
           
     <Card style={{border:"none", borderRadius:'0px'}} className='d-none d-md-block'>
          
            <Card.ImgOverlay  style={{
      backgroundImage: `url(${homeBg})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat', 
    backgroundColor: 'rgba(0, 0, 0, 0.4)', height:'100%', width:'100'}} className=' '>


      <div style={{width:'100%', maxWidth:'32rem',border:'none', boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px',backgroundColor:'white' }} className=' mx-md-auto py-2 mt-md-5 mb-5'>
              
      <div className='login-form pt-3 pb-4 pt-lg-2  px-4'>
           <Link className='d-flex justify-content-end mb-2' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
                 <div className='text-center'><Image fluid src={logo} style={{width:'100px'}}/>
                 <h2 className='mt-1 mb-3'>Forgot Password</h2>
                 <p style={{color:'#5D5D5D', fontSize:'12px'}}>Enter the email address associated with your account and we will send you a link to reset your password.</p></div>
              
                   <Form onSubmit={handleSubmit}>
                       <Form.Group className='mb-4'>
                       <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                       <Form.Control
                       type="email"
                       name="email"
                       id="email"
                       className=""
                       placeholder="e.g. user@email.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       required
                     />
                       </Form.Group>
                      
                     
                       <div><Button type='submit' className='btn-primary w-100'>Continue</Button></div>
                       <div className=' text-center mt-2 mb-0 '><p className='mb-1' style={{color:'#393939'}}>Don’t have an account? &nbsp;<Link to='/signup' className=''  style={{fontWeight:'500',color:"#469B12 "}}>Sign up</Link></p></div>
                   </Form>
                   <div className='d-flex flex-row justify-content-center gap-3 align-items-center mb-1'>
            <div className='line'></div>
            <p className='line-p'>OR</p>
            <div className='line'></div>
        </div>
                   
        <div className='d-flex flex-column mt-2'>
                     
                   <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'600'}} className='w-100' onClick={handleGoogleLogin}><img src={google} alt='google'/> &nbsp; Sign in with Google</Button>
                  
                   </div>
                   
                  
            </div>
          </div>
          </Card.ImgOverlay>
          </Card>
    
                  
     </>
    
 
       
   
  )
}

export default ForgotPassword