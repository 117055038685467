import {useState} from 'react'

import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import  { localStorageGetItem } from '../../App';
import { Row, Col, Button,Form } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import arrowLeft from '../../assets/arrow-left.svg'
import SidebarNavbar from '../../components/Navbar/SideNavbar';
import orderRoute from '../../services/orderRoute';

const CancelSubscription = () => {
   const {showToast,isMobile} = useAppContext();
    const [reason, setReason]= useState(null);
    const location = useLocation();
    const [subscription, setSubscription] = useState(location?.state?.subscription);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
const {deleteSubscription}= orderRoute()
    const userData = getParsedLocalStorageItem('userData');
    const userToken = localStorageGetItem
('userToken');;
    const isLoggedIn = !!userData && !!userToken;
    
    const {subscriptionId}= useParams()
    
   
   



  const handleBack = () => {
   
    navigate(-1);
  }


const handleCancel = (e)=> {
    e.preventDefault();

    const body ={
        reason:reason,
    }
    deleteSubscription(
        subscriptionId,
        subscription,
        body,
        setLoading,
        showToast
      )
}

  return (
    <div>
      {isMobile ? ( <div className='py-5 my-5 my-sm-0 '>

      <div className='mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex d-sm-none align-items-center gap-3 px-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>Reason</h5>
              
           </div>
           </div>

           {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className='px-3'>


               <h6 style={{color:'#333333', fontWeight:'500'}} className='mb-3'>Sad to see you go. Please tell us why you are cancelling your box of fresh goodies?</h6>
               <Form onSubmit={handleCancel}  className='d-flex flex-column jusify-content-center text-center align-items-center'>
               <textarea name="reason" style={{ backgroundColor: '#FFFFFF', borderRadius: '5px', border: '1px solid #E1E6EF'}} placeholder='Write a reason ...' type='text' className='p-2 w-100' rows={8} required onChange={(e)=> setReason(e.target.value)}></textarea>
                
               
                    <Button type='submit' className='w-50 mt-5'>Submit</Button>
               
               </Form>
     </div>
      )}

 
</div>): ( 
        <div className='py-5 px-3 px-xxl-5 mx-lg-5 mb-5 mb-sm-0'>

<div className="d-flex gap-2 align-items-center mb-3" >  
<Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-left" style={{color:'#1D2939'}} ></i></p></Link>

<p className='' style={{color:'#1D2939', fontWeight:'600'}} > Subscriptions </p>
</div>
<Row>
<Col xs={12} sm={3} className='d-none d-sm-block'>
    <SidebarNavbar/>
</Col>
<Col xs={12} sm={9} className='ps-lg-4 pe-lg-5'>
          <div>
         

           
          
        {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
  <div className='d-none d-sm-block mb-3'>
               <h5 style={{color:'#0C0C0C', fontWeight:'500'}}>Reason</h5>
               <h6 style={{color:'#717171', fontWeight:'400'}} className='mb-5'>Sad to see you go. Please tell us why you are cancelling your box of fresh goodies?</h6>
               <Form onSubmit={handleCancel} className='d-flex flex-column jusify-content-center text-center align-items-center col-9'>
               <textarea name="reason" style={{ backgroundColor: '#FFFFFF', borderRadius: '5px', border: '1px solid #E1E6EF'}} placeholder='Write a reason ...' type='text' className='p-2 w-100' rows={8} required onChange={(e)=> setReason(e.target.value)}></textarea>
                
               
                    <Button type='submit' className='mt-5 w-50'>Submit</Button>
             
               </Form>
           </div>
     </>
      )}
  </div>
</Col>
</Row>
 
</div>)}
      

    </div>
  )
}

export default CancelSubscription